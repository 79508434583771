import React from 'react';
import {
    InformationCircleIcon,
    ExclamationCircleIcon,
    CheckIcon
} from '@heroicons/react/24/outline';
import {cn, mergeClasses} from '#lib/helpers/class.js';

export const TYPES = {
    INFO: 'info',
    WARN: 'warn',
    PROBLEM: 'problem',
    SUCCESS: 'success'
};

const iconMap = {
    [TYPES.INFO]: InformationCircleIcon,
    [TYPES.WARN]: InformationCircleIcon,
    [TYPES.PROBLEM]: ExclamationCircleIcon,
    [TYPES.SUCCESS]: CheckIcon
};

const colorMap = {
    [TYPES.INFO]: {
        border: 'border-primary-500',
        background: 'bg-primary-50',
        icon: 'text-primary-600',
        body: 'text-primary-700'
    },
    [TYPES.WARN]: {
        border: 'border-yellow-500',
        background: 'bg-yellow-50',
        icon: 'text-yellow-600',
        body: 'text-yellow-700'
    },
    [TYPES.PROBLEM]: {
        border: 'border-red-500',
        background: 'bg-red-50',
        icon: 'text-red-600',
        body: 'text-red-700'
    },
    [TYPES.SUCCESS]: {
        border: 'border-green-500',
        background: 'bg-green-50',
        icon: 'text-green-600',
        body: 'text-green-700'
    }
};

export default function Aside(props) {
    const {
        as = 'aside',
        type = TYPES.INFO,
        icon: providedIcon,
        className,
        children,
        ...rest
    } = props;
    const Icon = providedIcon || iconMap[type];
    return React.createElement(
        as,
        {
            className: mergeClasses(
                cn('border-l-4 p-4', colorMap[type].border, colorMap[type].background),
                className
            ),
            ...rest
        },
        <div className="flex">
            <div className="flex-shrink-0">
                <Icon className={cn('icon', colorMap[type].icon)} />
            </div>
            <div className={cn('ml-3 text-sm', colorMap[type].body)}>{children}</div>
        </div>
    );
}
