import React from 'react';

const sizes = {
    mini: {width: 22, height: 22},
    regular: {width: 24, height: 24}
};

function Icon(props) {
    const {id, ...rest} = props;

    const isMini = id.startsWith('mini/');
    const size = isMini ? sizes.mini : sizes.regular;

    return (
        <svg {...size} {...rest}>
            <use href={`/sprite.svg#${id}`} />
        </svg>
    );
}

export default React.memo(Icon);
