import {Link} from 'react-router-dom';
import {List, Action} from '#components/actions.jsx';
import {includesAny} from "#lib/utils/array.js";
import {Ruoli} from "#root/src/constants.js";
import useAuth from "#lib/hooks/use-auth.jsx";

const actions = [
    {code: 'A', title: 'Carica', to: '/carica'},
    {code: 'B', title: 'Trasferisci', to: '/trasferisci'},
    {code: 'C', title: 'Lavorazione', to: '/lavorazione'},
    {code: 'D', title: 'Ordini', to: '/ordini'}
];

function Code(props) {
    const {children} = props;
    return (
        <span className="bg-neutral-950 text-white font-semibold inline-flex w-10 items-center justify-center aspect-square text-2xl text-center">
            {children}
        </span>
    );
}

export default function Root() {
    const {meta} = useAuth();
    const isMagazzinoOrAdmin = includesAny(meta.data?.ruoli, [Ruoli.backOfficeAdmin, Ruoli.magazzino]);

    return (
        <>{isMagazzinoOrAdmin &&
            <div className="max-w-screen-md mx-auto h-full flex flex-col justify-between pt-4 pb-4">
                <List>
                    {actions.map((action) => (
                        <List.Item key={action.code}>
                            <Action as={Link} to={action.to}>
                                <Action.Label>{action.title}</Action.Label>
                            </Action>
                        </List.Item>
                    ))}
                </List>
            </div>
        }
        </>
    );
}
