import {fetchAndParse, jsonHeaders} from "#lib/helpers/fetch.js";
import {useAuthQuery} from "#lib/hooks/use-query.js";
import useMutation from "#lib/hooks/use-mutation.js";
import {getAuthHeader} from "#lib/hooks/use-auth.jsx";

export function useOrdini(date, chiudi, direction) {
    const endpoint = chiudi ? 'ordine' : 'ordini/giacenza';
    const {data, ...queryProps} = useAuthQuery({
        queryKey: ['ordini', date],
        staleTime: 'Infinity',
        refetchOnWindowFocus: false,
        retry: false,
        retryOnMount: false,
        queryFn: (_, headers) =>
            fetchAndParse(`${API_URL}/p/${endpoint}?dataOrdine=${date}`, {   //&direction=${direction || ''}
                headers
            })
    });
    if(!date) {
        const dataOrdine = data?.results[0]?.dataOrdine;
        return {data: data?.results, dataOrdine: dataOrdine, ...queryProps};
    }

    return {data: data?.results, ...queryProps};
}

export function useConfezionaOrdine() {
    return useMutation({
        mutationFn: async (body) => {
            const headers = await getAuthHeader();
            const response = await fetch(`${API_URL}/p/ordini/confeziona`, {
                method: 'POST',
                headers: {
                    ...jsonHeaders,
                    ...headers
                },
                body: JSON.stringify(body),

            });
            return await response.json();
        }
    });
}

export function useChiudiOrdini() {
    return useMutation({
        mutationFn: async (idOrdini) => {
            const headers = await getAuthHeader();
            const response = await fetch(`${API_URL}/p/ordini/chiudi`, {
                method: 'POST',
                headers: {
                    ...jsonHeaders,
                    ...headers
                },
                body: JSON.stringify(idOrdini),
            });
            return await response.json();
        }
    });
}