import Confirm from '#components/confirm-button.jsx';
import Icon from '#components/icon.jsx';
import {Link} from 'react-router-dom';
import React from "react";
import {useNavigate} from 'react-router-dom';

export default function TabletOk(props) {
    const navigate = useNavigate();
    const {message} = props;
    return (
        <div className="mx-auto max-w-screen-md flex flex-col flex-wrapper">
            <aside className="p-6 flex font-semibold text-2xl items-center justify-between gap-2 text-white bg-green-600 cursor-pointer"
                   onClick={() => navigate('../../')}>
                {_t.tablet.completato()} <Icon id="thick/check" />
            </aside>
            <div className="flex-content">
                <div className="p-5 mb-4">
                    <h1 className="text-md font-semibold">{message}</h1>
                </div>
            </div>
            <Confirm as={Link} to="../../../">
                <h1>{_t.tablet.menu()}</h1>
                <Icon id="thick/arrow" />
            </Confirm>
        </div>
    );
}
