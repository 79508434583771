import React from 'react';
import {useAuthInfiniteQuery, useAuthQuery} from 'lib/hooks/use-query';
import {useAuthMutation} from 'lib/hooks/use-mutation';
import {useLastValue} from 'lib/hooks/use-last-value';
import {hasProperties} from 'lib/utils/object';
import {isFunction} from 'lib/utils/function';
import {isNotFalse} from 'lib/utils/boolean';
import {
    HTTP_METHODS,
    makeUrl,
    fetchFn,
    getOperationsEnabledStatus,
    makeSearchKey,
    makeDetailKey,
    makeInitKey
} from './helpers';
import {useQueryClient} from '@tanstack/react-query';

export function useCrud(options) {
    const props = _useCrud(useAuthQuery, options);
    return Object.assign(props, {_paginated: false});
}

export function usePaginatedCrud(options) {
    const props = _useCrud(useAuthInfiniteQuery, options);
    return Object.assign(props, {_paginated: true});
}

const readsPriority = ['detail', 'search', 'init'];

function _useCrud(queryHook, options) {
    const {
        search: searchOptions,
        detail: detailOptions,
        init: initOptions,
        create: createOptions,
        clone: cloneOptions,
        update: updateOptions,
        delete: deleteOptions,
        ...globalOptions
    } = options;

    if (!globalOptions.key && globalOptions.endpoint)
        globalOptions.key = [globalOptions.endpoint];

    if (!globalOptions.name) globalOptions.name = globalOptions.key.at(0);

    const [enabledStatus, readOperation] = getOperationsEnabledStatus(
        readsPriority,
        options
    );

    const detail = useRead(
        useAuthQuery,
        {
            enabled: enabledStatus.detail,
            key: makeDetailKey,
            ...detailOptions
        },
        globalOptions
    );

    const search = useRead(
        queryHook,
        {
            enabled: enabledStatus.search,
            key: makeSearchKey,
            ...searchOptions
        },
        globalOptions
    );

    const init = useRead(
        useAuthQuery,
        {
            enabled: enabledStatus.init,
            key: makeInitKey,
            ...initOptions
        },
        globalOptions
    );

    const reads = {detail, search, init};
    const readProps = reads[readOperation];

    const create = useWrite(
        {
            enabled: isNotFalse(createOptions),
            httpMethod: HTTP_METHODS.POST,
            ...createOptions
        },
        globalOptions
    );

    const clone = useWrite(
        {
            enabled: isNotFalse(cloneOptions),
            httpMethod: HTTP_METHODS.POST,
            ...cloneOptions
        },
        globalOptions
    );

    const update = useWrite(
        {
            enabled: isNotFalse(updateOptions),
            httpMethod: HTTP_METHODS.PUT,
            ...updateOptions
        },
        globalOptions
    );

    const _delete = useWrite(
        {
            invalidate: [],
            enabled: isNotFalse(deleteOptions),
            httpMethod: HTTP_METHODS.DELETE,
            ...deleteOptions
        },
        globalOptions
    );

    const writes = {create, update, _delete};

    const error = [...Object.values(reads), ...Object.values(writes)].find(
        (operation) => operation.isError
    )?.error;
    const isError = Boolean(error);

    return {
        ...readProps,
        readOperation,
        search,
        detail,
        init,
        create,
        clone,
        update,
        delete: _delete,
        error,
        isError,
        name: globalOptions.name
    };
}

function useRead(queryHook, options = {}, globalOptions) {
    const {
        key,
        fn,
        endpoint,
        accessor = globalOptions?.accessor,
        mutator,
        httpMethod,
        disableAuth,
        enabled,
        searchParams,
        ...useQueryOptions
    } = options;

    options.searchParams = searchParams;

    const providedKey = isFunction(key) ? key(globalOptions.key, options) : key;
    const queryKey = providedKey ?? globalOptions?.key;

    const to = makeUrl(endpoint, options, globalOptions, !disableAuth);
    const queryFn = fetchFn.bind(null, to, httpMethod, accessor, mutator);

    const {refetch, isFetching, ...queryProps} = queryHook({
        queryKey,
        queryFn: isFunction(fn) ? fn : queryFn,
        disableAuth,
        enabled,
        ...useQueryOptions
    });

    const queryClient = useQueryClient();
    function setQueryData(newData) {
        queryClient.setQueryData(queryKey, newData);
    }

    // Allows checking on isFetching without re-triggering the effect
    // when it switches back to false
    const fetchingRef = useLastValue(isFetching);
    React.useEffect(() => {
        if (enabled && !fetchingRef.current) refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled]);

    return {
        refetch,
        setQueryData,
        ...queryProps
    };
}

function useWrite(options, globalOptions) {
    const {
        key,
        fn,
        endpoint,
        accessor = globalOptions?.accessor,
        mutator,
        httpMethod,
        disableAuth,
        ...useMutationOptions
    } = hasProperties(options) ? options : {};

    const providedKey = isFunction(key) ? key(globalOptions.key, options) : key;

    const to = makeUrl(endpoint, options, globalOptions);
    const mutationFn = fetchFn.bind(null, to, httpMethod, accessor, mutator);

    return useAuthMutation({
        // Fall back to global `useCrud` key if the local one is not defined
        mutationKey: providedKey ?? globalOptions?.key,
        mutationFn: isFunction(fn) ? fn : mutationFn,
        disableAuth,
        ...useMutationOptions
    });
}

/**
 * endpoint,
 * [operation]: {
 *  key?: string,
 *  fn?: (args: any) => Promise<any>
 *  endpoint?: URL
 *  searchParams: object,
 *  accessor?: (args: any) => any
 *  mutator?: (args: any) => any
 *  disableAuth?: boolean
 *  ...useQueryOptions
 * },
 * detail: {
 *  id:
 * }
 */
