/**
 * Calls all functions passed in the array with the provided args
 * @param  {...any} fns
 * @returns
 */
export function callAll(...fns) {
    return function (...args) {
        return fns.forEach((fn) => fn?.(...args));
    };
}

export function callIfDefined(func, args) {
    if (func && typeof func === 'function') {
        return func(args);
    }
}

export function isFunction(variable) {
    return typeof variable === 'function';
}

export function utilizeAccessor(accessor, option) {
    if (isFunction(accessor)) return accessor(option);
    return option;
}