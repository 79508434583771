import React from 'react';

export default function useWaitQueue(initialSet) {
    const [queue, _setQueue] = React.useState(() => initialSet || new Set());

    const add = React.useCallback((key) => {
        _setQueue((prev) => new Set(prev).add(key));
    }, []);

    const remove = React.useCallback((key) => {
        _setQueue((prev) => {
            const newSet = new Set(prev);
            newSet.delete(key);
            return newSet;
        });
    }, []);

    return {
        queue,
        add,
        remove
    };
}
