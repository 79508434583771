import {useContext} from "#routes/carica/inventario/index.jsx";
import {useNavigate} from "react-router-dom";
import {Action, List} from "#components/actions.jsx";
import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import React from "react";
import {useInventarioLista} from "#hooks/use-inventario.js";

export default function ProdottoWebgate() {
    const {setInventario} = useContext();
    const prodotti = useInventarioLista();
    const navigate = useNavigate();

    function selectProduct(prodotto) {
        setInventario((p) => ({
            ...p,
            prodotto: {
                id: prodotto.idProdotto,
                codice: prodotto.codice,
                idUm: prodotto.prodotto.idUm,
                nome: prodotto.prodotto.prodotto
            }
        }));

        navigate('../seleziona-lotti');
    }

    return (
        <TabletFrame titolo={_t.crud.carica.inventarioDaWebgate() + " / " + _t.prodotto.prodotto()} descrizione={_t.interfaccia.messaggiCarica.prodotto()}>
            <List>
                {prodotti.data?.length > 0 && prodotti.isSuccess &&
                    prodotti.data.map((prodotto) => (
                        <List.Item key={prodotto.idProdotto + prodotto.lotto}>
                            <Action
                                as="button"
                                onClick={() => selectProduct(prodotto)}
                            >
                                <Action.Label>{prodotto.prodotto.prodotto}{prodotto.idProdotto.includes('SL')? ' - SL' : ''}</Action.Label>
                            </Action>
                        </List.Item>
                    ))}
                {prodotti.data?.length <= 0 &&
                    <h1 className="m-2">{_t.errore.nessunRisultato()}</h1>
                }
            </List>
        </TabletFrame>
    );
}