import {fetchAndParse, jsonHeaders} from "#lib/helpers/fetch.js";
import {useAuthQuery} from "#lib/hooks/use-query.js";
import useMutation from "#lib/hooks/use-mutation.js";
import {getAuthHeader} from "#lib/hooks/use-auth.jsx";

export function useSpedizioni(nascondi, date, direction, allowRefetch) {
    const {data, ...queryProps} = useAuthQuery({
        queryKey: ['spedizioni', allowRefetch],
        staleTime: 'Infinity',
        refetchOnWindowFocus: false,
        retry: false,
        retryOnMount: false,
        queryFn: (_, headers) =>
            fetchAndParse(`${API_URL}/p/spedizioni?nascondi=${nascondi}&date=${date}&direction=${direction || ''}`, {
                headers
            })
    });
    const dataDDT = data?.results[0]?.dataDDT;
    return {data: data?.results, dataDDT: dataDDT, ...queryProps};
}

export function useAggiornaSpedizione() {
    return useMutation({
        mutationFn: async (body) => {
            const headers = await getAuthHeader();
            const response = await fetch(`${API_URL}/p/spedizioni/aggiorna`, {
                method: 'POST',
                headers: {
                    ...jsonHeaders,
                    ...headers
                },
                body: JSON.stringify(body),

            });
            return await response.json();
        }
    });
}