import React from 'react';
import useUniversalLayoutEffect from './use-universal-layout-effect';

export function useLastValue(value) {
    const cache = React.useRef(value);

    useUniversalLayoutEffect(() => {
        cache.current = value;
    }, [value]);

    return cache;
}
