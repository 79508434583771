import React from 'react';
import {isString} from '#lib/utils/string.js';

function renderElement(element, props, children) {
    if (!element) return children;
    if (isString(element)) return React.createElement(element, props, children);
    const Element = element;
    return <Element {...props}>{children}</Element>;
}

export function ConditionalWrapper(props) {
    const {element, condition, children, ...deferredProps} = props;
    if (Array.isArray(element)) {
        const index = condition ? 0 : 1;
        return renderElement(element[index], deferredProps, children);
    }
    if (condition) return renderElement(element, deferredProps, children);
    return children;
}

export function ComponentSwitch(props) {
    const {elements, condition, children, ...deferredProps} = props;

    const deferred = React.useMemo(() => {
        let result = {};
        for (const key in deferredProps) {
            const value = deferredProps[key];
            if (condition && key.startsWith('true:')) {
                const prop = key.replace('true:', '');

                result[prop] = value;
                continue;
            }
            if (!condition && key.startsWith('false:')) {
                const prop = key.replace('true:', '');
                result[prop] = value;
                continue;
            }
            result[key] = value;
        }
        return result;
    }, [condition, deferredProps]);

    const toRender = condition ? 0 : 1;
    return renderElement(elements[toRender], deferred, children);
}
