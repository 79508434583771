import {useNavigate} from 'react-router-dom';
import {useContext} from '../index.jsx';
import {Action} from '#components/actions.jsx';
import React from 'react';
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";

const presetQuantities = [1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 20];

export default function Quantita() {
    const {state, setState} = useContext();

    const navigate = useNavigate();
    let initialQta = state.isEdit? state.aggiustaSpedizione.forme : 1
    const [quantity, setQuantity] = React.useState(initialQta);

    function onSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);

        if(state.isEdit) {
            setState((p) => ({
                ...p,
                aggiustaSpedizione: {
                    ...p.aggiustaSpedizione,
                    quantity: formData.get('quantity')
                }
            }));
            navigate('../conferma');
        } else {
            setState((p) => ({
                ...p,
                manual: {
                    ...p.manual,
                    quantity: formData.get('quantity')
                }
            }));
            navigate('../lotto');
        }
    }

    function increase() {
        setQuantity((p) => p + 1);
    }

    function decrease() {
        setQuantity((p) => {
            if (p <= 1) return 1;
            return p - 1;
        });
    }

    return (
        <TabletFrame titolo={_t.crud.carica.forme() + " / " + _t.quantita.quantita()} formId='quantity-form' descrizione={_t.interfaccia.messaggiCarica.quantitaForme()}>
            <TabletRiepilogoRow label={_t.prodotto.prodotto()} text={state.isEdit ? state.aggiustaSpedizione.prodotto : state.manual?.name}/>
            <TabletRiepilogoRow label={_t.quantita.numeroForme()} text={quantity
                + (!state.isEdit ? ' (' + state.manual?.idUm + ' ' + Math.round(quantity * state.manual?.peso * 100) / 100 + ')' : '')
            }/>
            <div className='grid grid-cols-4 gap-4 mb-6 p-5'>
                <Action as='button' onClick={decrease}>
                    <Action.Label>-1</Action.Label>
                </Action>
                <form className='col-span-2' id='quantity-form' onSubmit={onSubmit}>
                    <input
                        min={0}
                        step={1}
                        type='number'
                        className='w-full h-full text-3xl font-bold border-b-4 border-neutral-700 text-center'
                        name='quantity'
                        value={quantity}
                        onChange={(event) => setQuantity(event.target.value)}
                    />
                </form>
                <Action as='button' onClick={increase}>
                    <Action.Label>+1</Action.Label>
                </Action>
            </div>

            <div className='grid grid-cols-4 gap-4 mb-8 p-5'>
                {presetQuantities.map((quantity) => (
                    <Action
                        key={quantity}
                        as='button'
                        onClick={() => setQuantity(quantity)}
                    >
                        <Action.Label>{quantity}</Action.Label>
                    </Action>
                ))}
            </div>
        </TabletFrame>
    );
}
