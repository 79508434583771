import useManageCrud from "#@wizard/use-manage.js";
import DesktopFrame from "#root/src/lib/@desktop-template/desktop-frame.jsx";
import SearchPanel from "#components/search-panel.jsx";
import Input from "#lib/components/input.jsx";
import Select from 'lib/components/select.jsx';
import SearchResults from "#components/search-results.jsx";
import React from "react";
import {makePaginatedTableColumns} from "#helpers/table.jsx";
import {capitalize} from "lodash-es";
import Badge from "#lib/components/badge.jsx";
import {insertAtIndex} from "#lib/utils/array.js";
import ManageControls from "#components/manage-controls.jsx";
import BreadCrumbs from "#src/components/breadcrumbs.jsx";
import {SearchButtons} from "#components/control-buttons.jsx";

export default function UtentiSearch() {
    const {
        data,
        stateFlags,
        searchParams,
        changeSorting,
        paginationProps,
        getSearchFieldProps,
        setMultipleSearchFields
    } = useManageCrud(
        'utente',
        {},
        {search: {
                initialSortField: 'username'
            }
        });

    const abilitatoOptions = [
        {
            id: '',
            text: 'Seleziona opzione'
        },
        {
            id: 'true',
            text: 'Attivo'
        },
        {
            id: 'false',
            text: 'Inattivo'
        },
    ];

    const columnProps = makePaginatedTableColumns(
        data,
        {
            searchParams,
            changeSorting
        },
        {
            omit: ['id', 'abilitato'],
            headerText: (accessor) => {
                return capitalize(accessor);
            }
        }
    );

    columnProps.columns = insertAtIndex(columnProps.columns, 4, {
        accessor: 'abilitato',
        Header: <span className="sr-only">Attivo</span>,
        Cell: ({value}) =>
            value ? (
                <Badge color="green">attivo</Badge>
            ) : (
                <Badge color="neutral">inattivo</Badge>
            )
    });
    columnProps.templateColumns =
        'min-content auto auto auto min-content';

    return (
        <DesktopFrame>
            <ManageControls.BarSlot>
                <BreadCrumbs customName={_t.crud.utenti.utenti()}/>
            </ManageControls.BarSlot>
            <ManageControls.ButtonSlot>
                <SearchButtons/>
            </ManageControls.ButtonSlot>
            <SearchPanel
                onSubmit={setMultipleSearchFields}
                onChange={setMultipleSearchFields}
                searchParams={searchParams}
            >
                <Input.Full
                    placeholder={_t.crud.utenti.username()}
                    name="username"
                    type="search"
                    label={_t.crud.utenti.username()}
                    className="flex-grow"
                />

                <Input.Full
                    placeholder={_t.crud.utenti.nome()}
                    name="nome"
                    type="search"
                    label={_t.crud.utenti.nome()}
                    className="flex-grow"
                />

                <Input.Full
                    placeholder={_t.crud.utenti.cognome()}
                    name="cognome"
                    type="search"
                    label={_t.crud.utenti.cognome()}
                    className="flex-grow"
                />

                <Select.Full
                    className="flex-grow"
                    label={_t.utenti.abilitato()}
                    {...getSearchFieldProps('abilitato')}
                    options={abilitatoOptions}
                    accessValue={(option) => option.id}
                    displayValue={(id) => abilitatoOptions.find(c => c.id === id)?.text || ''}
                    nullable
                    onChange={(e) => {
                        searchParams.abilitato = e;
                        setMultipleSearchFields();
                    }}
                />
            </SearchPanel>

            <SearchResults
                data={data}
                {...stateFlags}
                {...paginationProps}
                {...columnProps}
                paginated
            />
        </DesktopFrame>);
}
