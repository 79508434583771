import {useContext} from "./index.jsx";
import {useNavigate} from "react-router-dom";
import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import React from "react";
import {cn} from "#lib/helpers/class.js";
import {useChiudiOrdini, useConfezionaOrdine} from "#hooks/use-ordini.js";
import {useChiudiBins} from "#hooks/use-movimenti.js";
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";

export default function Conferma() {
    const {ordini} = useContext();
    const navigate = useNavigate();
    const confezionaOrdineMutation = useConfezionaOrdine();
    const chiudiBinsMutation = useChiudiBins();
    const chiudiOrdiniMutation = useChiudiOrdini();

    const bins = ordini?.bins?.filter((bin) =>  {
        return ordini.selectedElements.some(
            el => el.idlotto === bin.idlotto && el.idprodotto === bin.prodotto.idprodotto
        );
    });

    function comparaBins(a, b) {
        return a.qta - b.qta;
    }

    async function OnConfezionaOrdine() {
        bins.sort(comparaBins);

        confezionaOrdineMutation.mutate({
            bins: bins,
            ordine: ordini.ordine.id,
            scatole: ordini.cartoni,
            pezziCaricati: ordini.ordine.numeroPezziCaricati
        }, {
            onSuccess: (res) => {res?.ok ? navigate('../fine') : navigate('../errore')},
            onError: () => navigate('../errore')
        });
    }

    async function OnChiudiOrdine() {
        chiudiOrdiniMutation.mutate({
            idOrdini: ordini.selectedElements
        }, {
            onSuccess: (res) => {res?.ok ? navigate('../fine') : navigate('../errore')},
            onError: () => navigate('../errore')
        });
    }

    async function OnChiudiBins(){
        chiudiBinsMutation.mutate({
            bins: bins,
        }, {
            onSuccess: (res) => {res?.ok ? navigate('../fine') : navigate('../errore')},
            onError: () => navigate('../errore')
        });
    }

    return (
        <TabletFrame titolo={_t.crud.ordine.ordini() + " / " + _t.tablet.riepilogo()}
                     descrizione={ordini.chiudiBins ? _t.interfaccia.messaggiOrdini.confermaChiudiBins() :
                         ordini.chiudiOrdine? _t.interfaccia.messaggiOrdini.confermaChiudiOrdini() :
                             _t.interfaccia.messaggiOrdini.confermaConfezionamento()}
            onConfirm={ordini.chiudiBins? OnChiudiBins :
                ordini.chiudiOrdine? OnChiudiOrdine :
            OnConfezionaOrdine}
        >
            {ordini?.selectedElements?.length > 0 && (
                <>
                    {!ordini.chiudiBins && ! ordini.chiudiOrdine &&
                        <>
                            <TabletRiepilogoRow label={_t.crud.ordine.nOrdine()} text={ordini.ordine.numeroOrdine} />
                            <TabletRiepilogoRow label={_t.crud.ordine.destinatario()} text={ordini.ordine.clienteRagioneSociale} />
                            <TabletRiepilogoRow label={_t.crud.ordine.cartoniTotali()} text={ordini.cartoni} />
                        </>
                    }
                    {!ordini.chiudiOrdine &&
                        <ol className="container-grid">
                            {bins.sort(comparaBins).map((bin) => (
                                <li
                                    key={bin.idlotto + bin.idprodotto}
                                    className={cn(
                                        "item-grid",
                                        ["relative isolate text-md bg-neutral-50 mb-6 p-4"]
                                    )}
                                >
                                    <header className="w-fit">
                                        <p className="font-semibold mb-2 text-neutral-800">
                                            {bin.prodotto.prodotto}
                                        </p>
                                    </header>
                                    <table className="contents">
                                        <tbody className="contents">
                                        <tr className="flex flex-col"></tr>
                                        <tr className="flex flex-col"></tr>
                                        <tr className="flex flex-col"></tr>
                                        <tr className="flex flex-col"></tr>
                                        <tr className="flex flex-col">
                                            <th className="font-normal text-neutral-800 text-sm text-left">
                                                {_t.lotto.lotto()}
                                            </th>
                                            <td className="text-sm text-left">{bin.idlotto}</td>
                                        </tr>
                                        <tr className="flex flex-col">
                                            <th className="font-normal text-neutral-800 text-sm text-right">
                                                {_t.deposito.ubicazione()}
                                            </th>
                                            <td className="text-sm text-right">{bin.ubicazione?.ubicazione}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </li>
                            ))}
                        </ol>
                    }
                    {ordini.chiudiOrdine &&
                        <ol className="container-grid">
                            {ordini.ordini.data.map(ordine => (
                                <React.Fragment key={ordine.id + ordine.prodotto + ordine.numeroColli}>
                                {ordini.selectedElements.includes(ordine.id) &&
                                    <li
                                        className={cn(
                                            "item-grid",
                                            ["relative isolate text-md bg-neutral-50 mb-6 p-4"]
                                        )}
                                    >
                                        <header className="w-fit">
                                            <p className="font-semibold mb-2 text-neutral-800">
                                                {ordine.prodotto}
                                            </p>
                                        </header>
                                        <table className="contents">
                                            <tbody className="contents">
                                            <tr className="flex flex-col"></tr>
                                            <tr className="flex flex-col"></tr>

                                            <tr className="flex flex-col">
                                                <th className="font-normal text-neutral-800 text-sm text-right">
                                                    {_t.crud.ordine.nOrdine()}
                                                </th>
                                                <td className="text-sm text-right">{ordine.numeroOrdine}</td>
                                            </tr>
                                            <tr className="flex flex-col">
                                                <th className="font-normal text-neutral-800 text-sm text-right">
                                                    {_t.crud.ordine.pCollo()}
                                                </th>
                                                <td className="text-sm text-right">{ordine.pezziPerCollo}</td>
                                            </tr>
                                            <tr className="flex flex-col">
                                                <th className="font-normal text-neutral-800 text-sm text-right">
                                                    {_t.crud.ordine.pezzi()}
                                                </th>
                                                <td className="text-sm text-right">{ordine.numeroPezziCaricati || 0}/{ordine.numeroPezzi}</td>
                                            </tr>
                                            <tr className="flex flex-col">
                                                <th className="font-normal text-neutral-800 text-sm text-right">
                                                    {_t.crud.ordine.colli()}
                                                </th>
                                                <td className="text-sm text-right">{ordine.numeroPezziCaricati / ordine.numeroColli}/{ordine.numeroColli}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </li>
                                }
                                </React.Fragment>
                            ))}
                        </ol>
                    }
                </>
            )}
        </TabletFrame>
    );
}
