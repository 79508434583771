import {useParams} from 'react-router-dom';

export function useRouteParams(props = {}) {
    const params = useParams();
    const isCreate = !props.id && !params.id;

    return {
        id: props.id || params.id,
        ownerId: params.ownerId,
        isCreate: isCreate,
        ...props,
        params
    };
}
