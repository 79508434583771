import React from 'react';
import {replaceParams} from '#lib/helpers/text.js';
import {isFunction} from '#lib/utils/function.js';

function useValidator(actions = {}, rules) {
    const {onSubmit, onChange, onInvalid} = actions;

    const _onSubmit = React.useCallback(
        (event, ...args) => {
            event.preventDefault();
            const form = event.target;

            if (typeof rules === 'object') {
                const formData = getFormModel(event.target);

                for (const fieldName in rules) {
                    let fields;
                    //to validate grid edit fields in format list[id].sublist[id].field
                    if (fieldName.includes('.')) {
                        const split = fieldName.split('.');
                        let querySelector;
                        split.map((s, i) => {
                            if (i === 0) querySelector = "[name^='" + s + "[']";
                            else if (i < split.length - 1)
                                querySelector += "[name*=']." + s + "[']";
                            else querySelector += "[name$=']." + s + "']";
                        });

                        fields = form.querySelectorAll(querySelector);
                    } else {
                        const field = form.querySelector(`[name='${fieldName}']`);
                        if (field) fields = [field];
                    }

                    if (!fields || fields.length === 0) continue;

                    const fieldRules = rules[fieldName];
                    validate(fields, fieldRules, formData);
                }
            }

            if (!form.reportValidity()) {
                //TODO: trigger validation error popup (?)
                return false;
            }
            if (isFunction(onSubmit)) {
                return onSubmit(event, ...args);
            }
        },
        [onSubmit, rules]
    );

    const _onChange = (event, ...args) => {
        if (event) {
            const field = event.target;

            //reset custom validation
            field.setCustomValidity('');

            //check for custom rules
            if (typeof rules === 'object') {
                const fieldName = field.name.replace(/\[(.*?)\]/, '');
                const fieldRules = rules[fieldName];
                if (Array.isArray(fieldRules)) {
                    const formData = getFormModel(event.target.form);
                    validate([field], fieldRules, formData);
                }
            }

            //trigger field validation works only on uncontrolled
            field.reportValidity();
        }

        if (isFunction(onChange)) {
            return onChange(event, ...args);
        }
    };

    //fields onChange validation bubbled on form
    const _onInvalid = (event, ...args) => {
        const field = event.target;

        if (!field || field.tagName === 'FORM') return;

        const validationModel = field.validity;

        if (validationModel.valid || validationModel.customError) return;

        //reset custom validation
        field.setCustomValidity('');

        let error;
        for (let key in validationModel)
            if (validationModel[key]) {
                error = key;
                break;
            }

        if (!error) return;
        let message = '';

        //TODO: insert localized texts
        switch (error) {
            case 'badInput':
                break;

            case 'patternMismatch':
                //TODO
                break;
            case 'rangeOverflow':
                message = replaceParams(_t.global.validation.max(), field.max);
                break;
            case 'rangeUnderflow':
                message = replaceParams(_t.global.validation.min(), field.min);
                break;
            case 'stepMismatch':
                //TODO
                break;
            case 'tooLong':
                message = replaceParams(
                    _t.global.validation.maxLength(),
                    field.maxLength
                );
                break;
            case 'tooShort':
                message = replaceParams(
                    _t.global.validation.minLength(),
                    field.minLength
                );
                break;
            case 'typeMismatch':
                //TODO
                break;
            case 'valueMissing':
                message = _t.global.validation.required();
                break;
        }

        if (message) field.setCustomValidity(message);

        if (isFunction(onInvalid)) {
            return onInvalid(event, ...args);
        }
    };

    return {
        onSubmit: _onSubmit,
        onChange: _onChange,
        onInvalid: _onInvalid,
        noValidate: true
    };
}

function getFormModel(formElement) {
    if (!formElement) return {};

    return [...new FormData(formElement)].reduce((p, c) => {
        p[c[0]] = c[1];
        return p;
    }, {});
}

function validate(fields, rules, formData) {
    for (let field of fields)
        for (let rule of rules) {
            const error = rule(field.value, field.name, formData);
            if (!isEmpty(error)) {
                field.setCustomValidity(error);
                break;
            }
        }
}

/* ****************************************** UTILITIES *************************************************************** */

const isEmpty = (value) => value === undefined || value === null || value === '';

/* ****************************************** VALIDATORS *************************************************************** */

/**
 * required validator. set this validator in the field's rule array
 **/
export function required(value) {
    if (isEmpty(value)) return _t.global.validation.required();
}

/**
 * minLength validator.
 * @param {number} min - min length number
 **/
export function minLength(min) {
    return (value) => {
        if (!isEmpty(value) && value.length < min)
            return replaceParams(_t.global.validation.minLength(), min);
    };
}

/**
 * maxLength validator.
 * @param {number} max - max length number
 **/
export function maxLength(max) {
    return (value) => {
        if (!isEmpty(value) && value.length > max) {
            return replaceParams(_t.global.validation.maxLenght(), max);
        }
    };
}

/**
 * min number validator.
 * @param {number} min - min value
 **/
export function min(min) {
    return (value) => {
        if (!isEmpty(value) && parseInt(value) < min) {
            return replaceParams(_t.global.validation.min(), min);
        }
    };
}

/**
 * max number validator.
 * @param {number} max - max value
 **/
export function max(max) {
    return (value) => {
        if (!isEmpty(value) && parseInt(value) > max) {
            return replaceParams(_t.global.validation.max(), max);
        }
    };
}

/**
 * field value must be one of passed values
 * @param {any[]} enumeration - condition
 **/
export function oneOf(enumeration) {
    return (value) => {
        if (!~enumeration.indexOf(value)) {
            return replaceParams(_t.global.validation.oneOf(), enumeration.join(', '));
        }
    };
}

/**
 * regexp validator.
 * @param {RegExp|string} regexp - validation regexp
 * @param {string} [errorText=''] - error text
 **/
export function regexp(regexp, errorText = '') {
    return (value) => {
        if (value && !value.toString().match(regexp)) {
            errorText = errorText ?? replaceParams(_t.global.validation.regexp(), regexp);
            return errorText;
        }
    };
}
/* ****************************************** FIELD COMPARE VALIDATION *************************************************************** */
/**
 * field value must be equal to @field
 * @param {string} field - other field
 * @param {string} [fieldLabel=field} - other field label to display
 **/
export function mustBeEqual(field, fieldLabel = field) {
    return (value, name, model) => {
        if (model) {
            if (value !== model[field]) {
                return replaceParams(_t.global.validation.equal(), fieldLabel);
            }
        }
    };
}

/* ****************************************** CONDITIONAL VALIDATION *************************************************************** */
/**
 * Conditional apply required validator
 * @param {function} functreq - condition
 **/
export function requiredIf(functreq) {
    return (value, name, model) => {
        if (model) if (functreq && functreq(model)) return required(value, model);
    };
}

/**
 * Conditional apply validator
 * @param {function} functreq - condition
 * @param {function} rule - validation rule fn
 **/
export function applyIf(functreq, rule) {
    return (value, chiave, data, model) => {
        if (model) {
            if (functreq && functreq(model)) {
                return rule(value, chiave, data, model);
            }
        }
    };
}

export default useValidator;
