function expandTransitionProps(target, source) {
    let result = {};
    for (const prop in source) {
        result[prop] = source[prop];
        if (prop in target) result[prop] += ' ' + target[prop];
    }
    return result;
}

const durations = {
    75: 'duration-75',
    100: 'duration-100',
    150: 'duration-150',
    200: 'duration-200',
    300: 'duration-300'
};

const defaultDuration = {
    enter: durations[100],
    leave: durations[75]
};

const enter = (duration) => `transition ease-out ${duration}`;
const leave = (duration) => `transition ease-in ${duration}`;

export const getFadeInProps = (duration = defaultDuration) => ({
    enter: enter(duration.enter),
    enterFrom: 'transform opacity-0',
    enterTo: 'transform opacity-100',
    leave: leave(duration.leave),
    leaveFrom: 'transform opacity-100',
    leaveTo: 'transform opacity-0'
});

export const getPopInProps = (duration) =>
    expandTransitionProps(
        {
            enterFrom: 'scale-95',
            enterTo: 'scale-100',
            leaveFrom: 'scale-100',
            leaveTo: 'scale-95'
        },
        getFadeInProps(duration)
    );

export const getSlideRightProps = (duration = defaultDuration) => ({
    enter: enter(duration.enter),
    enterFrom: '-translate-x-full',
    enterTo: 'translate-x-0',
    leave: leave(duration.leave),
    leaveFrom: 'translate-x-0',
    leaveTo: '-translate-x-full'
});

export const getSlideLeftProps = (duration = defaultDuration) => ({
    enter: enter(duration.enter),
    enterFrom: 'translate-x-full',
    enterTo: 'translate-x-0',
    leave: leave(duration.leave),
    leaveFrom: 'translate-x-0',
    leaveTo: 'translate-x-full'
});
