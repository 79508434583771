import {useContext} from "#routes/carica/inventario/index.jsx";
import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";
import React from "react";
import {useCaricaInventarioWebgate} from "#hooks/use-movimenti.js";
import {useNavigate} from "react-router-dom";

export default function ConfermaWebgate() {
    const {inventario} = useContext();
    const caricaInventarioWebgateMutation = useCaricaInventarioWebgate();
    const navigate = useNavigate();

    function onCaricaInventarioWebgate() {
        caricaInventarioWebgateMutation.mutate(
            inventario, {
                onSuccess: (res) => {res?.ok ? navigate('../fine') : navigate('../errore')},
                onError: () => navigate('../errore')
        })
    }

    return (
        <TabletFrame
            titolo={_t.crud.carica.inventarioDaWebgate() + " / " + _t.tablet.riepilogo()}
            descrizione={_t.interfaccia.messaggiCarica.conferma()}
            onConfirm={onCaricaInventarioWebgate}
        >
            <TabletRiepilogoRow label={_t.prodotto.prodotto()} text={inventario.prodotto.nome} />
            <TabletRiepilogoRow label={_t.deposito.deposito()} text={inventario.deposito.deposito} />
            {inventario.listaLotti.map(lotto => (
                <TabletRiepilogoRow
                    key={lotto.id}
                    label={_t.lotto.lotto() + " " + lotto.lotto}
                    text={
                        _t.crud.movimenti.qta() + " " + lotto.qta
                    }
                />
            ))}
        </TabletFrame>
    );
}