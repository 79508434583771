import React from 'react';
import {Link} from 'react-router-dom';
import {UserCircleIcon} from '@heroicons/react/24/solid';
import {Menu as HUIMenu, Transition} from '@headlessui/react';
import {cn} from '#lib/helpers/class';
import {getPopInProps} from '#lib/helpers/transition';
import {isFunction} from '#lib/utils/function';

const transition = getPopInProps();

function Menu(props) {
    const {isOpen, onOpen, onClose, actions} = props;

    let userImage;
    // const profile = useProfile();
    // userImage = profile.data?.info?.immagineprofilo;

    React.useEffect(() => {
        if (isOpen && isFunction(onOpen)) onOpen();
    }, [isOpen, onOpen]);

    React.useEffect(() => {
        if (!isOpen && isFunction(onClose)) onClose();
    }, [isOpen, onClose]);

    return (
        <div className="relative flex-shrink-0">
            <HUIMenu.Button className="flex rounded-full bg-neutral-100 text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                <span className="sr-only">{_t.bottone.apriMenuUtente()}</span>
                {userImage ? (
                    <img src={userImage} className="h-10 w-10 rounded-full" />
                ) : (
                    <UserCircleIcon className="icon box-content fill-neutral-600 p-2" />
                )}
            </HUIMenu.Button>
            <Transition as={React.Fragment} {...transition}>
                <HUIMenu.Items className="absolute ml-10 mt-1 w-48 -translate-x-full rounded-md border border-neutral-100 bg-white py-1 shadow-lg">
                    {actions.map((item) => (
                        <HUIMenu.Item key={item.label}>
                            {({active}) => <Action active={active} {...item} />}
                        </HUIMenu.Item>
                    ))}
                </HUIMenu.Items>
            </Transition>
        </div>
    );
}

export default function Container(props) {
    return <HUIMenu>{({open}) => <Menu isOpen={open} {...props} />}</HUIMenu>;
}

function _Action(props, ref) {
    const {active, label, ...rest} = props;
    const as = props.to ? Link : props.onClick ? 'button' : 'span';
    const state = props.to ? {from: location.pathname} : undefined;
    return React.createElement(
        as,
        {
            ref,
            className: cn(
                active && 'bg-primary-50',
                'text-right w-full block px-4 py-2 text-sm text-neutral-700 hover:text-primary-700'
            ),
            state,
            ...rest
        },
        label
    );
}

const Action = React.forwardRef(_Action);
