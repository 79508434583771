/**
 * @param {string} initialSortField: value to set the default sorting field
 * @param {string} initialSortDirection: value to set the default sorting direction
 **/
export function makeSortingFields(initialSortField = '', initialSortDirection = '') {
    return {
        sortField: initialSortField,
        sortDirection: initialSortDirection
    };
}

/**
 * @param {number} pageSize: value to set the page size for paginated results
 * @param {number} pageParam: value to set the current page number
 **/
export function makePagingFields(pageSize = 50, pageParam = 1) {
    return {pageSize, pageParam};
}
