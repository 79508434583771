import React from 'react';
import Portal from 'lib/components/portal.jsx';
import MaxWidth from 'lib/components/max-width.jsx';
import {addClasses, mergeClasses} from 'lib/helpers/class.js';

const ManageControlsContext = React.createContext();
ManageControlsContext.displayName = 'ManageControlsContext';

export function useManageControls() {
    return React.useContext(ManageControlsContext) ?? {};
}

function Provider(props) {
    const {hideControls = false, children} = props;

    const barSlotId = React.useId();
    const buttonSlotId = React.useId();

    return (
        <ManageControlsContext.Provider value={{hideControls, barSlotId, buttonSlotId}}>
            {children}
        </ManageControlsContext.Provider>
    );
}

export default function ManageControls(props) {
    const {children, className, ...rest} = props;
    const {hideControls, barSlotId, buttonSlotId} = useManageControls();
    if (hideControls) return null;
    return (
        <div
            className={mergeClasses('absolute top-[72px] w-full px-4 isolate z-10', className)}
            {...rest}
        >
            <MaxWidth
                className={addClasses(
                    'flex min-h-[2rem] flex-1 flex-wrap gap-3 md:items-center'
                )}
            >
                {children}
                <div className="flex flex-grow" id={barSlotId} />
                <div
                    className="ml-auto flex items-center gap-2 md:mt-0"
                    id={buttonSlotId}
                />
            </MaxWidth>
        </div>
    );
}

function BarSlot(props) {
    const {hideControls, barSlotId} = useManageControls();
    if (hideControls || !barSlotId) return null;
    return <Portal root={barSlotId}>{props.children}</Portal>;
}

function ButtonSlot(props) {
    const {hideControls, buttonSlotId} = useManageControls();
    if (hideControls || !buttonSlotId) return null;
    return <Portal root={buttonSlotId}>{props.children}</Portal>;
}

ManageControls.BarSlot = BarSlot;
ManageControls.ButtonSlot = ButtonSlot;
ManageControls.Provider = Provider;
