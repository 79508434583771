import React from 'react';
import {createRoot} from 'react-dom/client';
import {onBuild, hotReloadCSS} from '#lib/helpers/esbuild.js';
import {QueryClient, QueryClientProvider} from '#lib/hooks/use-query.js';
import {BrowserRouter} from 'react-router-dom';
import {AuthProvider, getAuthHeader} from '#lib/hooks/use-auth.jsx';
import {jsonHeaders} from '#lib/helpers/fetch.js';
import AppRoutes from '#routes/index.jsx';
import {Spinner} from '#lib/components/loading.jsx';
import Portal from '#lib/components/portal.jsx';
import Toaster from '#lib/components/toaster.jsx';
import './style.css';
import intl from "#lib/helpers/intl.js";

const queryClient = new QueryClient();

const element = document.getElementById('root');
const root = createRoot(element);

intl({
    onSetup: () => renderApp({loadingLocale: true}),
    onSuccess: () => renderApp({loadingLocale: false})
});

renderApp({loadingLocale: false});

// test
async function getUserInfo() {
    const headers = await getAuthHeader();
    const response = await fetch(`${API_URL}/p/user/auth-info`, {
        headers: {
            ...jsonHeaders,
            ...headers
        }
    });

    const data = await response.json();
    return {...data};
}

if (WATCH_MODE) onBuild(hotReloadCSS);

function App(props) {
    const {loadingLocale = false} = props;
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <AuthProvider options={{getMeta: getUserInfo}}>
                    <AppRoutes />
                    {loadingLocale && (
                        <div className="absolute inset-0 flex h-full items-center justify-center bg-white">
                            <Spinner />
                        </div>
                    )}
                    <Portal>
                        <Toaster />
                    </Portal>
                </AuthProvider>
            </BrowserRouter>
        </QueryClientProvider>
    );
}

export function renderApp(props) {
    root.render(
        <React.StrictMode>
            <App {...props} />
        </React.StrictMode>
    );
}

renderApp();
