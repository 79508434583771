/**
 * Utility to convert a form to an object.
 * Convert to array if field name is in format "arrayName[rowId].fieldName"
 * Convert to object if field name is in format "obj1.obj2.fieldName"
 * @param {HTMLFormElement} form: form to be converted
 **/
export function formToObject(form) {
    const formData = new FormData(form);

    for (const element of form.elements) {
        if (element.type === 'checkbox') formData.set(element.name, element.checked);

        if (element.type === 'file' && element.multiple && element.name) {
            formData.delete(element.name);
            let index = 0;
            for (const file of element.files) {
                formData.set(`${element.name}[${index++}]`, file);
            }
        }
    }

    return Array.from(formData).reduce((p, c) => {
        const fieldName = c[0];
        const value = c[1];

        if (fieldName.includes('.')) {
            const split = fieldName.split('.');

            let current = p;
            for (let i = 0; i < split.length - 1; i++) {
                const element = split[i];

                if (element.includes('[')) {
                    const listName = element.split('[')[0];

                    if (!current[listName]) current[listName] = [];

                    const index = element.substring(
                        element.indexOf('[') + 1,
                        element.lastIndexOf(']')
                    );
                    const fieldName = split[i + 1];
                    let objectInArray = current[listName][index];
                    if (!objectInArray) current[listName].push({[fieldName]: value});
                    else objectInArray[fieldName] = value;

                    return p;
                } else {
                    const objectName = split[i];
                    if (!current[objectName]) current[objectName] = {};

                    if (i === split.length - 2) {
                        current[objectName][split[i + 1]] = value;
                        return p;
                    } else current = current[objectName];
                }
            }
        }

        p[fieldName] = value;
        return p;
    }, {});
}

export function captureFormSubmit(event) {
    event.preventDefault();
    const form = event.target;
    return formToObject(form);
}

export function captureFormChange(event) {
    const form = event.target.form;
    return formToObject(form);
}
