import React from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid';
import {isFunction} from '#lib/utils/function.js';
import {isString} from '#lib/utils/string.js';
import {isDefined} from '#lib/utils/undefined.js';
import {isArrayEmpty} from '#lib/utils/array.js';
import {getPopInProps} from '#lib/helpers/transition.js';
import {cn, mergeClasses} from '#lib/helpers/class.js';
import {matchReferenceWidth} from '#lib/helpers/floating-ui.js';
import Popover from '#lib/components/popover.jsx';
import {ConditionalWrapper} from '#lib/components/conditional-render.jsx';
import {BouncingDots} from '#lib/components/loading.jsx';

const SelectContext = React.createContext();
SelectContext.displayName = 'SelectContext';

const useSelect = () => React.useContext(SelectContext);

const PopoverWrapper = (props) => {
    const {isOpen, animated} = useSelect();
    return (
        <Popover
            animated={animated}
            isOpen={isOpen}
            middlewares={[matchReferenceWidth()]}
        >
            <Popover.Reference {...props} />
        </Popover>
    );
};

/**
 * @see https://headlessui.com/react/Select#component-api
 */
function Elements(props) {
    const {
        className: providedClassName,
        noBorder = false,
        as = 'div',
        children,
        ...rest
    } = props;

    const {hasError, inPortal, disabled} = useSelect();

    const hasBorder = !noBorder;

    const className = mergeClasses(
        cn(
            'group relative flex w-full rounded-md focus-within:ring-1 focus-within:ring-primary-500',
            disabled ? 'bg-neutral-50 opacity-80' : 'bg-white',
            hasError &&
                hasBorder &&
                'border-red-400 text-red-800 placeholder-red-300 focus-within:border-transparent focus-within:ring-red-500',
            hasBorder &&
                'border border-neutral-300 focus-within:border-primary-500 shadow-sm'
        ),
        providedClassName
    );

    return (
        <ConditionalWrapper
            element={[PopoverWrapper, as]}
            condition={inPortal}
            className={className}
            {...rest}
        >
            {children}
        </ConditionalWrapper>
    );
}

function Button(props) {
    const {autoFocus, autoOpen} = useSelect();

    const ref = React.useRef(null);

    React.useEffect(() => {
        const button = ref.current;
        if (!autoFocus || !button) return;
        button.focus();
    }, [autoFocus]);

    React.useEffect(() => {
        const button = ref.current;
        if (!autoOpen || !button) return;
        button.click();
    }, [autoOpen]);

    return (
        <Listbox.Button
            ref={ref}
            className="flex flex-grow items-center gap-2 whitespace-nowrap border-transparent p-2 focus-visible:outline-none sm:text-sm"
            {...props}
        />
    );
}

const transition = getPopInProps();

function Options(props) {
    const {className, ...rest} = props;
    const {inPortal, isOpen, animated} = useSelect();

    const baseClassName =
        'rounded-md bg-white shadow-lg py-2 border border-primary-100 overflow-auto w-max cursor-default';

    return (
        <ConditionalWrapper
            element={Popover.Panel}
            condition={inPortal}
            className={baseClassName}
        >
            <ConditionalWrapper
                element={Transition}
                condition={!inPortal && animated}
                as={React.Fragment}
                show={isOpen}
                {...transition}
            >
                <Listbox.Options
                    className={mergeClasses(
                        cn(
                            !inPortal &&
                                `${baseClassName} absolute left-0 top-full z-10 min-w-full translate-y-2`,
                            'focus-visible:outline-none'
                        ),
                        className
                    )}
                    {...rest}
                />
            </ConditionalWrapper>
        </ConditionalWrapper>
    );
}

function Option(props) {
    const {className, value, children, ...rest} = props;

    return (
        <Listbox.Option value={value} as={React.Fragment}>
            {({active, selected}) => (
                <li
                    className={mergeClasses(
                        cn(
                            active && 'bg-primary-100 text-primary-700',
                            'py-1.5 px-4 text-sm'
                        ),
                        className
                    )}
                    {...rest}
                >
                    {isFunction(children) ? children({active, selected}) : children}
                </li>
            )}
        </Listbox.Option>
    );
}

function Label(props) {
    const {className, ...rest} = props;

    const {hasError} = useSelect();

    return (
        <Listbox.Label
            className={mergeClasses(
                cn(
                    hasError ? 'text-red-800' : 'text-neutral-700',
                    'mb-1 block text-sm font-medium'
                ),
                className
            )}
            {...rest}
        />
    );
}

function Helper(props) {
    const {as = 'p', className, id: providedId, children, ...rest} = props;
    const {helperId, hasError} = useSelect();
    const id = providedId ?? helperId;

    const helper = React.createElement(
        as,
        {
            className: mergeClasses('mt-2 text-sm text-neutral-500', className),
            id,
            ...rest
        },
        children
    );

    return hasError ? null : helper;
}

function ErrorMessage(props) {
    const {as = 'p', className, id: providedId, children, ...rest} = props;
    const {errorMessageId} = useSelect();
    const id = providedId ?? errorMessageId;

    return React.createElement(
        as,
        {
            className: mergeClasses('mt-1 text-sm text-red-800', className),
            id,
            ...rest
        },
        children
    );
}

function Container(props) {
    const {
        className,
        children,
        as = 'div',
        error,
        hasError: providedHasError,
        value: providedValue,
        defaultValue,
        onChange: providedOnChange,
        disabled,
        autoFocus,
        autoOpen,
        inPortal = true,
        animated = true,
        ...rest
    } = props;

    const isControlled = isDefined(providedValue);
    const [_selectedOption, setSelectedOption] = React.useState(defaultValue ?? null);

    const value = isControlled ? providedValue : _selectedOption;

    function onChange(selection) {
        if (!isControlled) setSelectedOption(selection);
        if (isFunction(providedOnChange)) providedOnChange(selection);
    }

    const helperId = React.useId();
    const errorMessageId = React.useId();

    const hasError = providedHasError ?? Boolean(error);

    return (
        <Listbox
            as={as}
            disabled={disabled}
            className={mergeClasses('w-full', className)}
            value={value}
            onChange={onChange}
            {...rest}
        >
            {({open: isOpen, disabled, value}) => (
                <SelectContext.Provider
                    value={{
                        isOpen,
                        inPortal,
                        animated,
                        disabled,
                        value,
                        error,
                        hasError,
                        errorMessageId,
                        helperId,
                        autoFocus,
                        autoOpen
                    }}
                >
                    {children}
                </SelectContext.Provider>
            )}
        </Listbox>
    );
}

const defaultMakeReactKey = (option) =>
    isString(option) ? option : option.id ?? option.value;

function Full(props) {
    const {
        label,
        options = [],
        disabled,
        error,
        helper,
        isLoading = false,
        accessValue = (option) => option,
        displayValue = (option) => option,
        makeReactKey = defaultMakeReactKey,
        noBorder = false,
        children,
        ...rest
    } = props;

    const _children = isFunction(children) ? children({options}) : children;

    return (
        <Container disabled={disabled} error={error} {...rest}>
            {label && <Label>{label}</Label>}
            <Elements noBorder={noBorder}>
                <Button>
                    {({value}) => {
                        //(isDefined(value, false) && value !== '') && console.log(value)
                        return (
                            <React.Fragment>
                            <span
                                className="w-full flex-grow overflow-hidden text-ellipsis whitespace-nowrap text-left">
                                {isDefined(value, false) && value !== ''
                                    ? displayValue(value, options)
                                    : _t.bottone.selezionaOpzione()}
                            </span>
                                <ChevronUpDownIcon className="ml-auto h-5 w-6 flex-shrink-0 text-neutral-500"/>
                            </React.Fragment>
                        )
                    }}
                </Button>
                <BouncingDots
                    aria-hidden
                    className={cn(
                        isLoading ? 'opacity-100' : 'opacity-0',
                        'pointer-events-none absolute inset-y-0 right-8 my-auto w-4 -translate-y-0.5 fill-primary-500 transition-opacity'
                    )}
                />
                <Options>
                    {_children ??
                        (!isArrayEmpty(options) ? (
                            options.map((option) => {
                                const value = accessValue(option, options);
                                return (
                                    <Option
                                        aria-describedby={useSelect.helperId}
                                        aria-errormessage={useSelect.errorMessageId}
                                        className={(prev) => `${prev} flex items-center`}
                                        key={makeReactKey(option, options)}
                                        value={value}
                                    >
                                        {({selected, active}) => (
                                            <React.Fragment>
                                                <CheckIcon
                                                    className={cn(
                                                        selected
                                                            ? 'visible'
                                                            : 'invisible',
                                                        active
                                                            ? 'text-primary-700'
                                                            : 'text-primary-600',
                                                        '-ml-2 mr-2 h-5 w-5 flex-shrink-0'
                                                    )}
                                                />
                                                {displayValue(value, options)}
                                            </React.Fragment>
                                        )}
                                    </Option>
                                );
                            })
                        ) : isLoading ? (
                            <LoadingResults />
                        ) : (
                            <EmptyResults />
                        ))}
                </Options>
            </Elements>
            {helper && <Helper>{helper}</Helper>}
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </Container>
    );
}

function EmptyResults() {
    return (
        <span className="py-2 px-4 text-sm text-neutral-700">
            {_t.global.noResults()}
        </span>
    );
}

function LoadingResults() {
    return (
        <span className="py-2 px-4 text-sm text-neutral-700">{_t.global.loading()}</span>
    );
}

export default {
    Container,
    Elements,
    Button,
    Options,
    Option,
    Label,
    ErrorMessage,
    Full
};
