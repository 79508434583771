import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import {useContext} from "#routes/ordini/index.jsx";
import {useNavigate} from "react-router-dom";
import {useForm} from "#lib/hooks/use-form.js";
import {useGiacenzeDeposito} from "#hooks/use-giacenze.js";
import React from "react";
import TabletSelect from "#root/src/lib/@tablet-template/tablet-select.jsx";

export default function SelezionaBins(props) {
    const {ordini, setOrdini} = useContext();
    const {chiudi} = props;
    const navigate = useNavigate();
    const {data, registerCheckbox} = useForm();
    const bins= useGiacenzeDeposito("Confezionamento", "true", ordini?.ordine?.idProdotto);

    const [pezziSelezionati, setPezziSelezionati] = React.useState(0);

    //se ho solo un bins skip al passaggio successivo
    /*useEffect(() => {
        if(bins.data?.length === 1 && !confezionamento.selectedElements) {
            const model = chiudi? {
                chiudiBins: true
            } : {
                pezziSelezionati: bins.data[0]?.qta,
                chiudiBins: false
            }

            setConfezionamento((p) => ({
                ...p,
                ...model,
                selectedElements: [{...bins.data[0], idprodotto: bins.data[0].prodotto.idprodotto}],
                bins: bins.data
            }));

            if (chiudi) navigate('../conferma');
            else navigate('../quantita');
        }
    });*/

    function onSubmit(event) {
        event.preventDefault();
        const selectedBins = getBinsSelezionati();
        if (selectedBins.length === 0)
            return;

        if (chiudi) {
            setOrdini((p) => ({
                ...p,
                selectedElements: selectedBins,
                bins: bins.data,
                chiudiBins: true
            }));
            navigate('../conferma');
        } else {
            setOrdini((p) => ({
                ...p,
                selectedElements: selectedBins,
                bins: bins.data,
                pezziSelezionati: pezziSelezionati,
                chiudiBins: false
            }));
            navigate('../quantita');
        }
    }

    function comparaBins(a, b) {
        return a.qta - b.qta;
    }

    function getElementiSelezionati(data) {
        let bins = Object.keys(data);
        let binsSelezionati = [];
        for (const b in bins){
            if(data[bins[b]] === true){
                binsSelezionati.push(bins[b]);
            }
        }
        return binsSelezionati;
    }

    function aggiornaPezziSelezionati(idLotto, idProdotto, numeroPezzi) {
        const selectedElements = getBinsSelezionati();
        const filteredElements = selectedElements.filter(e => e.idlotto === idLotto && e.idprodotto === idProdotto);
        if (filteredElements.length) {
            setPezziSelezionati((p) => p - numeroPezzi);
        } else {
            setPezziSelezionati((p) => p + numeroPezzi);
        }
    }

    function getBinsSelezionati () {
        const selectedKeys = getElementiSelezionati(data);
        return selectedKeys.map(key => {
            const arr = key.split("|");
            return {idlotto: arr[0], idprodotto: arr[1]}
        });
    }

    return (
        <TabletFrame titolo={_t.crud.ordine.ordini() + " / " + (chiudi? _t.crud.ordine.chiudiBins() : _t.crud.ordine.selezioneBins())} formId="binsPerOrdini-form"
            descrizione={_t.interfaccia.messaggiOrdini.bins()}>
            {!chiudi &&
                <h1 className="font-bold text-neutral-800 ml-4">{_t.crud.ordine.pezzi()}:
                    <span className="ml-2">{pezziSelezionati + (ordini.ordine.numeroPezziCaricati || 0)}</span>
                    /{ordini.ordine.numeroPezzi}
                </h1>
            }
            {bins.isSuccess &&
                <form onSubmit={onSubmit} id="binsPerOrdini-form">
                    <ol className="container-grid">
                        {bins.data.length > 0 && bins.data.sort(comparaBins).map((bin) => (
                            <TabletSelect
                                key={bin.idlotto + "|" + bin.prodotto.idprodotto}
                                chiave={bin.idlotto + "|" + bin.prodotto.idprodotto}
                                onClick={()=>aggiornaPezziSelezionati(bin.idlotto, bin.prodotto.idprodotto, bin.qta)}
                                headerTitle={<span>{bin.prodotto.prodotto} {bin.ubicazione?.ubicazione && ' | ' + bin.ubicazione?.ubicazione}</span>}
                                headerText={<><span>{bin.idlotto}</span> <span className="text-neutral-800">{bin.tag && " | " + bin.tag}</span></>}
                                isActive={data[bin.idlotto + "|" + bin.prodotto.idprodotto]}
                                emptySlots={4}
                                {...registerCheckbox(bin.idlotto + "|" + bin.prodotto.idprodotto)}
                            >
                                <tr className="flex flex-col">
                                    <th className="font-normal text-neutral-800 text-sm text-right">
                                        {_t.crud.ordine.qta()}
                                    </th>
                                    <td className="text-sm text-right">{Math.round(bin.qta * bin.prodotto.peso * 100) / 100} {bin.prodotto.idUm}</td>
                                </tr>
                                <tr className="flex flex-col">
                                    <th className="font-normal text-neutral-800 text-sm text-right">
                                        {_t.crud.ordine.pezzi()}
                                    </th>
                                    <td className="text-sm text-right">{bin.qta}</td>
                                </tr>
                            </TabletSelect>
                        ))}
                    </ol>

                    {bins.data.length <= 0 &&
                        <h1 className="m-2">{_t.errore.nessunRisultato()}</h1>
                    }
                </form>
            }
        </TabletFrame>
    );
}