import React from 'react';
import {useNavigate} from 'react-router-dom';
import * as Crud from '../lib/@crud';
import {SORT_DIRECTIONS} from 'lib/constants';
import {makeSortingFields, makePagingFields} from '../helpers/search-fields';
import {toast} from 'lib/components/toaster';
import {useSearch} from 'lib/hooks/use-search';
import {hasProperties} from 'lib/utils/object';
import {isBoolean} from 'lodash-es';

// Since we use the endpoint parameter, not the request body,
// to delete an entry or get its details, we empty `request.body`
const emptyMutator = () => undefined;

const queryDefaults = {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
    getNextPageParam
};

const mutationDefaults = {
    retry: false
};

function getNextPageParam(_, pages) {
    const {totalResults} = pages.at(0);
    const totalFetched = pages.reduce((acc, data) => acc + data.results.length, 0);
    const hasNextPage = totalResults > totalFetched;
    if (hasNextPage) return pages.length + 1;
}

/**
 * A wrapper around useCrud that initializes some recurring features for SARDA
 * @param {string} endpoint
 * @param {Object} routeProps
 * @param {Object} options – useCrud options
 * @returns
 */
export default function useManageCrud(endpoint, routeProps = {}, options = {}) {
    const {id, owner, slideOver} = routeProps;

    const {isDetail, isCreate, isUpdate} = Crud.useRouteParams();

    const navigate = useNavigate();
    const {urlEntityName} = options;

    //Per usare il salvataggio della query su cambio route usare useKeepPreviousSearch
    const {params: searchParams, ...searchActions} = useSearch(
        undefined,
        {
            query: '',
            id: '',
            ...makePagingFields(),
            ...makeSortingFields(
                options.search?.initialSortField ?? endpoint,
                options.search?.initialSortDirection ?? SORT_DIRECTIONS.ASC
            ),
            ...(options.search?.initFields ?? [])
        }
    );

    const getSearchFieldProps = React.useCallback(
        (name) => {
            return {
                name,
                defaultValue: searchParams[name]
            };
        },
        [searchParams]
    );

    const getSearchCheckboxProps = React.useCallback(
        (name) => {
            const value = searchParams[name];
            return {
                name,
                defaultChecked: isBoolean(value) ? value : value === 'true'
            };
        },
        [searchParams]
    );

    const searchOptions = {
        ...queryDefaults,
        searchParams,
        accessor: (data) =>
            data.totalResults
                ? {results: data.results, totalResults: data.totalResults}
                : {results: data.results},
        enabled: !isDetail,
        onError: (error) => {
            toast.error(error.message);
        },
        ...options.search
    };

    const detailOptions = {
        ...queryDefaults,
        id,
        enabled: isUpdate,
        onError: (error) => {
            navigate(
                owner ? `/gestione/${owner.name}/search` : `/gestione/${urlEntityName || endpoint}/search`
            );
            const errorCause = error.cause;
            toast.error(errorCause?.statusCode === 404 ? _t.errore.nonTrovata() : errorCause?.errorText);
        },
        endpoint: (_, options) =>
            options.id ? `${endpoint}/${options.id}` : `${owner?.name}/${owner?.data.id}`,
        mutator: emptyMutator,
        accessor: (data) => data.model,
        ...options.detail
    };

    const initOptions = {
        ...queryDefaults,
        cacheTime: 0,
        // Work-around since the cache doesn't update when we change the initial data
        // TODO: open pull request for useQuery
        key: [endpoint, 'init', options.init?.initialData ? 'initialized' : 'empty'],
        enabled: false, // Defaults to false
        accessor: (data) => data.model,
        ...options.init // Can be overwritten by passing `options.init.enabled`
    };

    const createOptions = {
        ...mutationDefaults,
        onSuccess: (data) => {
            toast.success(_t.messaggio.successoCrea());
            if (owner) owner.refetch();
            if (slideOver) return slideOver.close();
            if (data.ok && data?.entity?.id)
                navigate(
                    owner
                        ? `/gestione/${owner.name}/edit/${owner.data.id}`
                        : `/gestione/${urlEntityName || endpoint}/edit/${data.entity.id}`
                );
        },
        onError: (error) => {
            toast.error(error.message);
        },
        ...options.create
    };

    const cloneOptions = {
        ...mutationDefaults,
        endpoint: `${endpoint}/clone`,
        mutator: () => ({id}),
        onSuccess: (data) => {
            toast.success(_t.messaggio.successoClona());
            if (owner) owner.refetch();
            if (slideOver) return slideOver.close();
            if (data.ok && data?.id)
                navigate(
                    owner
                        ? `/gestione/${owner.name}/edit/${owner.data.id}`
                        : `/gestione/${urlEntityName || endpoint}/edit/${data.id}`
                );
        },
        onError: (error) => {
            toast.error(error.message);
        },
        ...options.clone
    };

    const updateOptions = {
        ...mutationDefaults,
        onSuccess: () => {
            toast.success(_t.messaggio.successoModifica());
            if (owner) owner.refetch();
        },
        onError: (error) => {
            toast.error(error.message);
        },
        ...options.update
    };

    const deleteOptions = {
        ...mutationDefaults,
        endpoint: `${endpoint}/${id}`,
        mutator: emptyMutator,
        enabled: Boolean(id),
        onSuccess: (data) => {
            toast.success(_t.messaggio.successoElimina());
            if (owner) owner.refetch();
            if (slideOver) return slideOver.close();
            if (data.ok)
                navigate(
                    owner
                        ? `/gestione/${owner.name}/edit/${owner.data.id}`
                        : `/gestione/${urlEntityName || endpoint}/search`
                );
        },
        onError: (error) => {
            toast.error(error.message);
        },
        ...options.delete
    };

    const crud = Crud.usePaginatedCrud({
        endpoint,
        search: searchOptions,
        detail: detailOptions,
        init: initOptions,
        create: createOptions,
        clone: cloneOptions,
        update: updateOptions,
        delete: deleteOptions
    });

    if (isCreate) {
        return {
            ...crud,
            save: crud.create,
            // need to tell useForm not to wait for data to be loaded
            data: hasProperties(crud.data) ? crud.data : null
        };
    }

    const stateFlags = {
        isSuccess: crud.isSuccess,
        isError: crud.isError,
        isLoading: crud.isLoading,
        isRefetching: crud.isRefetching
    };

    const paginationProps = {
        fetchNextPage: crud.fetchNextPage,
        hasNextPage: crud.hasNextPage,
        isFetchingNextPage: crud.isFetchingNextPage
    };

    if (crud.readOperation === 'detail') return {...crud, save: crud.update, stateFlags};

    return {
        ...crud,
        searchParams,
        ...searchActions,
        getSearchFieldProps,
        getSearchCheckboxProps,
        stateFlags,
        paginationProps
    };
}
