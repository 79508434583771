import ReactDOM from 'react-dom';
import useHasMounted from '#lib/hooks/use-has-mounted.js';
import {hasKey, isObject} from '#lib/utils/object.js';
import {isString} from '#lib/utils/string.js';

let portalRoot;
function getRoot(root, document = window.document) {
    if (!root) {
        if (!portalRoot) {
            portalRoot = document.createElement('div');
            document.body.appendChild(portalRoot);
        }
        return portalRoot;
    }
    if (isString(root)) {
        const element = document.getElementById(root);
        if (!element) {
            console.error(`Element with id ${root} not found in document.`);
            return portalRoot;
        }
        return element;
    }
    if (isObject(root) && hasKey(root, 'current')) {
        return root.current;
    }
    return portalRoot;
}

export default function Portal(props) {
    const {children, root, document} = props;
    const hasMounted = useHasMounted();
    if (!hasMounted) {
        return null;
    }
    return ReactDOM.createPortal(children, getRoot(root, document));
}
