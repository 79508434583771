import React, {Fragment} from 'react';
import useManageCrud from "#@wizard/use-manage.js";
import {useRouteParams} from '#hooks/use-route-params';
import DesktopFrame from "#root/src/lib/@desktop-template/desktop-frame.jsx";
import Panel from "#components/panel.jsx";
import Input from "#lib/components/input.jsx";
import Table from '#lib/components/table';
import ManageControls from '#components/manage-controls';
import {useForm} from "#lib/hooks/use-form.js";
import {addClasses} from "#lib/helpers/class.js";
import {LoadingIndicator} from "#lib/components/loading.jsx";
import Grid from "#lib/components/grid.jsx";
import * as Crud from "#hooks/use-route-params.js";
import BreadCrumbs from "#src/components/breadcrumbs.jsx";
import Select from "#lib/components/select.jsx";
import {DetailButtons} from "#components/control-buttons.jsx";
import {nanoid} from 'nanoid';
import {useDropdown} from '#hooks/use-dropdown.js';
import {AddButton, DeleteButton} from '#components/row-buttons.jsx';
import {isEmptyOrUndefined} from '#lib/utils/array.js';
import EmptyState from '#lib/components/empty-state.jsx';
import Switch from '#lib/components/switch';
import {useOpenClose} from '#lib/hooks/use-boolean-state.js';
import useResetPassword from '#root/src/@Utente/use-reset-password.js';
import Button from '#lib/components/button';
import Dialog from '#lib/components/dialog';
import {ArrowPathIcon} from '@heroicons/react/24/solid';
import {DefaultPassword} from '#root/src/constants.js';

export function ResetPasswordBtn(props) {
    const {openCloseResetPassword, resetPassword, className} = props;

    return (
        <Fragment>
            <Button
                className={addClasses(className)}
                destructive
                onClick={openCloseResetPassword.open}
            >
                <ArrowPathIcon className="icon-sm mr-1" />
                {_t.utenti.resetPassword()}
            </Button>

            <Dialog.Full
                isOpen={openCloseResetPassword.isOpen}
                onClose={openCloseResetPassword.close}
                title={_t.utenti.confermaResetPassword()}
                description={_t.utenti.descrizioneResetPassword() + DefaultPassword}
            >
                <div className="flex flex-wrap items-baseline justify-end gap-2">
                    <Button
                        destructive
                        busy={resetPassword.isLoading}
                        onClick={resetPassword.execute}
                    >
                        {_t.bottone.conferma()}
                    </Button>
                    <Button onClick={openCloseResetPassword.close}>
                        {_t.bottone.annulla()}
                    </Button>
                </div>
            </Dialog.Full>
        </Fragment>
    );
}

export default function UtentiEdit(routeProps) {
    const {id} = useRouteParams(routeProps);
    const {isCreate} = Crud.useRouteParams();
    const dropdowns = useDropdown(['ruoli']);
    let initialData = {abilitato: true};

    const crud = useManageCrud(
        'utente',
        {id},
        {init: {initialData}}
    );

    const {data, props, registerCheckbox, initialize, register, isSuccess, isLoading, addEntry, removeEntry} = useForm({
        initialData: crud.data,
        errors: crud.error?.cause?.validationErrors,
        onSubmit: (data) => {
            data.id = id;
            crud.save.mutate(data);
        }
    });

    const addUtenteRuolo = React.useCallback(() => {
        addEntry('utenteRuoli', {id: nanoid(), new: true, idRuolo: undefined});
    }, [addEntry]);

    const removeUtenteRuolo = removeEntry.bind(null, 'utenteRuoli');
    const utenteRouliColumns = React.useMemo(
        () => [
            {
                Header: _t.crud.utente.ruolo(),
                accessor: 'idRuolo',
                Cell: ({index}) => {
                    let ruoli = data.utenteRuoli;
                    const autoOpen = index + 1 === ruoli.length && ruoli[index].new;

                    return (
                        <>
                            <Input.Full
                                type="hidden"
                                {...initialize(`utenteRuoli[${index}].new`)}
                            />
                            <Input.Full
                                type="hidden"
                                {...initialize(`utenteRuoli[${index}].id`)}
                            />
                            <Select.Full
                                {...register(`utenteRuoli[${index}].idRuolo`)}
                                options={dropdowns.data.ruoli}
                                accessValue={(option) => option.id}
                                displayValue={(selectedId) =>
                                    dropdowns.data.ruoli.find(
                                        (el) => el.id === selectedId
                                    )?.text
                                }
                                autoOpen={autoOpen}
                                noBorder
                            />
                        </>
                    );
                }
            },
            {
                Header: '',
                id: 'actions',
                Cell: ({index}) =>
                    <DeleteButton onDelete={() => removeUtenteRuolo(index)} />
            }
        ],
        [
            data.utenteRuoli,
            dropdowns.data.ruoli,
            register,
            initialize,
            removeUtenteRuolo
        ]
    );

    const openCloseResetPassword = useOpenClose();
    const resetPassword = useResetPassword(id, {
        onSettled: openCloseResetPassword.close
    });

    return (
        <DesktopFrame>
            <ManageControls.BarSlot>
                <BreadCrumbs customName={_t.utenti.utenti()} />
            </ManageControls.BarSlot>

            <Panel as="form" {...props.form}>
                {isLoading && <LoadingIndicator className={addClasses('mx-auto')} />}
                {isSuccess && (
                    <Grid>
                        <Grid.Slot className={addClasses('flex items-baseline justify-between')}>
                            <h2>{isCreate ? _t.utenti.nuovo() : _t.utenti.utente()}</h2>

                            <div className={'flex gap-x-5'}>
                                {!isCreate && data?.abilitato && (
                                    <ResetPasswordBtn
                                        openCloseResetPassword={openCloseResetPassword}
                                        resetPassword={resetPassword}
                                    />
                                )}
                                <Switch.Full
                                    {...registerCheckbox('abilitato')}
                                    label={_t.utenti.abilitato()}
                                />
                            </div>
                        </Grid.Slot>

                        <Grid.Slot span={4}>
                            <Input.Full
                                {...initialize('username')}
                                label={_t.utenti.username()}
                                required
                            />
                        </Grid.Slot>

                        <Grid.Slot span={4}>
                            <Input.Full
                                {...initialize('nome')}
                                label={_t.utenti.nome()}
                                required
                            />
                        </Grid.Slot>

                        <Grid.Slot span={4}>
                            <Input.Full
                                {...initialize('cognome')}
                                label={_t.utenti.cognome()}
                                required
                            />
                        </Grid.Slot>

                        <Grid.Slot>
                            <div className='grid gap-5 lg:grid-cols-3'>
                                <div className="flex flex-col gap-3">
                                    <h2>{_t.utenti.ruoli()}</h2>
                                    {isEmptyOrUndefined(data.utenteRuoli) ? (
                                        <EmptyState onClick={addUtenteRuolo}>
                                            {_t.utenti.nuovoRuolo()}
                                        </EmptyState>
                                    ) : (
                                        <Table.Full
                                            data={data.utenteRuoli}
                                            columns={utenteRouliColumns}
                                            templateColumns="1fr min-content"
                                            spacing="tight"
                                        >
                                            <Table.Row>
                                                <Table.Cell
                                                    className={addClasses(
                                                        'flex justify-center'
                                                    )}
                                                    colSpan={2}
                                                >
                                                    <AddButton onClick={addUtenteRuolo} />
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Full>
                                    )}
                                </div>
                            </div>
                        </Grid.Slot>

                        {isCreate &&
                            <Grid.Slot span={12}>
                                <p className="mb-4 pr-8 text-neutral-700">
                                    {_t.utenti.defaultPassword()} {DefaultPassword}
                                </p>
                            </Grid.Slot>
                        }
                    </Grid>
                )}
                <ManageControls.ButtonSlot>
                    <DetailButtons
                        save={{busy: crud.save.isPending, ...props.submit}}
                        delete={{
                            onDelete: crud.delete.mutate,
                            busy: crud.delete.isPending
                        }}
                    />
                </ManageControls.ButtonSlot>
            </Panel>
        </DesktopFrame>
    );
}
