import {fetchAndParse} from 'lib/helpers/fetch';
import {useAuthQuery} from 'lib/hooks/use-query';

function useAuth() {
    return {
        tenantId: 'ABSOLUTE'
    };
}

// TODO: add CT endpoint in config
export function useChangeTracker(tableName, rowKey, useQueryOptions) {
    const {tenantId} = useAuth();
    const url = `${API_URL}/p/changetracker/token?tableName=${tableName}&rowKey=${rowKey}`;

    return useAuthQuery({
        queryKey: [tenantId, 'changeTrackerToken', tableName, rowKey],
        queryFn: (_, headers) => fetchAndParse(url, {headers}),
        //5 minutes
        staleTime: 300000,
        ...useQueryOptions
    });
}

export function useChangeTrackerHelper(tableName, rowKey = '') {
    const {refetch} = useChangeTracker(tableName, rowKey, {
        enabled: false,
        onSuccess: (res) => {
            if (res.ok && res.token) {
                window.ChangeTracker.open({
                    getToken: async (callback) => {
                        return await callback(encodeURIComponent(res.token));
                    },
                    serviceUrl: `https://${CT_HOST}.hosts.changetracker.it`
                });
            }
        }
    });

    return refetch;
}
