import React from 'react';
import Button from 'lib/components/button';
import {PencilIcon, TrashIcon, PlusIcon} from '@heroicons/react/20/solid';
import {addClasses} from 'lib/helpers/class';
import {buttonLooks, sizes} from 'lib/styles/constants';
import Dialog from 'lib/components/dialog';
import {useOpenClose} from 'lib/hooks/use-boolean-state';

export function AddButton(props) {
    const {className, children, ...rest} = props;
    return (
        <Button
            className={className ?? addClasses('my-1')}
            look={buttonLooks.secondary}
            size={sizes.sm}
            type="button"
            {...rest}
        >
            {children ?? (
                <React.Fragment>
                    <PlusIcon className="icon-sm -m-0.5" />
                    {_t.bottone.aggiungi()}
                </React.Fragment>
            )}
        </Button>
    );
}

export function EditButton(props) {
    const {children, ...rest} = props;
    return (
        <Button
            look={buttonLooks.text}
            size={sizes.xs}
            type="button"
            className={addClasses('-my-2 -ml-4 -mr-3')}
            {...rest}
        >
            {children ?? (
                <React.Fragment>
                    <PencilIcon className="icon-sm fill-primary-600" />
                    <span className="sr-only">{_t.bottone.modifica()}</span>
                </React.Fragment>
            )}
        </Button>
    );
}

export function DeleteButton(props) {
    const {children, onDelete, busy, ...rest} = props;

    const confirmDelete = useOpenClose();

    return (
        <React.Fragment>
            <Button
                look={buttonLooks.text}
                size={sizes.sm}
                onClick={confirmDelete.open}
                {...rest}
            >
                {children ?? (
                    <React.Fragment>
                        <TrashIcon className="icon-sm fill-neutral-600" />
                        <span className="sr-only">{_t.bottone.elimina()}</span>
                    </React.Fragment>
                )}
            </Button>
            <Dialog.Full
                title={_t.bottone.confermaElimina()}
                isOpen={confirmDelete.isOpen}
                onClose={confirmDelete.close}
            >
                <div className="flex flex-wrap items-baseline justify-end gap-2">
                    <Button destructive busy={busy} onClick={onDelete}>
                        {_t.bottone.conferma()}
                    </Button>
                    <Button onClick={confirmDelete.close}>{_t.bottone.annulla()}</Button>
                </div>
            </Dialog.Full>
        </React.Fragment>
    );
}
