import {useNavigate} from 'react-router-dom';
import {useContext} from './index.jsx';
import React from "react";
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';

export default function Note() {
    const {setState} = useContext();
    const navigate = useNavigate();

    function onSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        setState((p) => ({
            ...p,
            notes: formData.get('note')
        }));
        navigate('../conferma');
    }

    return (
        <TabletFrame titolo={_t.crud.caricaForme.caricaForme() + " / " + _t.note.note()} descrizione={_t.interfaccia.messaggiCarica.note()} formId='form-note'>
            <form className='flex flex-col gap-6' id='form-note' onSubmit={onSubmit}>
                <textarea
                    name='note'
                    className='h-96 p-4 mb-6 bg-neutral-50 text-xl'
                    placeholder={_t.note.inserisci()}
                />
            </form>
        </TabletFrame>
    );
}
