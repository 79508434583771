import React from 'react';
import {Link} from 'react-router-dom';
import {cn} from '#lib/helpers/class';

export default function Logo(props) {
    const {to = '/', url, altText} = props;

    const hasImage = Boolean(url);

    return (
        <Link
            to={to}
            state={{from: location.pathname}}
            className={cn(
                hasImage && 'self-center',
                'relative flex max-h-[54px] max-w-[115px] flex-shrink-0 overflow-hidden text-ellipsis whitespace-nowrap text-sm font-semibold text-gray-700 hover:text-primary-700'
            )}
        >
            {hasImage ? (
                <img alt={altText} src={url} className="object-contain object-left" />
            ) : (
                altText
            )}
        </Link>
    );
}
