import React from 'react';
import {isFunction} from '#lib/utils/function.js';
import {isDefined} from '#lib/utils/undefined.js';
import {defineDefaults} from '#lib/helpers/react.jsx';

const defaultOptions = {
    initialState: {isOpen: false, meta: null}
};

export function useOpenClose(options = {}) {
    defineDefaults(options, defaultOptions);
    const {initialState, onOpen, onClose} = options;
    const userDidClose = React.useRef(false);
    const [state, setState] = React.useState(initialState);
    const {isOpen, meta} = state;
    const isClosed = !isOpen;

    const open = React.useCallback(
        (meta) => {
            setState({isOpen: true, meta});
            if (isFunction(onOpen)) onOpen();
        },
        [onOpen]
    );
    const close = React.useCallback(
        (meta) => {
            setState((prev) => ({
                isOpen: false,
                meta: isDefined(meta, true) ? meta : prev.meta
            }));
            userDidClose.current = true;
            if (isFunction(onClose)) onClose();
        },
        [onClose]
    );

    return {
        isOpen,
        isClosed,
        userDidClose: userDidClose.current,
        meta,
        open,
        close
    };
}

export function useBool(initialState) {
    const [state, setState] = React.useState(
        isDefined(initialState) ? initialState : false
    );

    const activate = React.useCallback(() => {
        setState(true);
    }, []);

    const dectivate = React.useCallback(() => {
        setState(false);
    }, []);

    return [state, activate, dectivate];
}

export function useToggle(initialValue = false) {
    const [value, setValue] = React.useState(initialValue);
    const toggle = React.useCallback(() => {
        setValue((v) => !v);
    }, []);
    return [value, toggle];
}
