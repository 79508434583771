import React from 'react';
import useUniversalLayoutEffect from '#lib/hooks/use-universal-layout-effect.js';

export default function useHasMounted() {
    const [hasMounted, setHasMounted] = React.useState(false);
    useUniversalLayoutEffect(() => {
        setHasMounted(true);
    }, []);
    return hasMounted;
}
