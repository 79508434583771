import {isUndefined} from './undefined.js';

export function arrayEquals(a, b) {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    );
}

export function partition(array, predicate) {
    return array.reduce(
        (result, value) => (result[predicate(value) ? 0 : 1].push(value), result),
        [[], []]
    );
}

export function replaceAtIndex(array, index, newValue) {
    const max = array.length;
    let _index = index >= 0 ? index : max + index;
    if (_index < 0 || _index > max) {
        throw Error(`Your array index ${index} falls out of boundaries`);
    }
    const newArray = array.slice();
    newArray[_index] = newValue;
    return newArray;
}

export function insertAtIndex(array, index, item) {
    const start = array.slice(0, index);
    const inserted = Array.isArray(item) ? item : [item];
    const end = array.slice(index);
    return start.concat(inserted, end);
}

export function removeAtIndex(array, index) {
    if (index == null) return [array.slice()];
    const before = array.slice(0, index);
    const after = array.slice(index + 1);
    const result = before.concat(after);
    const removed = array.at(index);
    return [result, removed];
}

export function swap(array, index1, index2) {
    const newArray = array.slice();
    const temp = newArray[index1];
    newArray[index1] = newArray[index2];
    newArray[index2] = temp;
    return newArray;
}

export function arrayMove(array, from, to) {
    const newArray = array.slice();
    const normalizedTo = to < 0 ? newArray.length + to : to;
    const removed = newArray.splice(from, 1)[0];
    newArray.splice(normalizedTo, 0, removed);
    return newArray;
}

export function isArrayEmpty(array) {
    if (isUndefined(array) || !Array.isArray(array)) return undefined;
    return array.length === 0;
}

export function hasElements(array) {
    if (isUndefined(array) || !Array.isArray(array)) return undefined;
    return array.length > 0;
}

export function isEmptyOrUndefined(array) {
    return isUndefined(array) || isArrayEmpty(array);
}

export function includesAny(array, listOfElements) {
    if (!Array.isArray(array) || !Array.isArray(listOfElements)) return false;
    return array.some((el) => listOfElements.some((le) => le === el));
}
