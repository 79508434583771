import React from 'react';
import {isDefined, isUndefined} from '#lib/utils/undefined.js';

export default function useControlledState(controlledState, initialState) {
    const [_state, _setState] = React.useState(initialState);
    const state = isUndefined(controlledState) ? _state : controlledState;
    const uncontrolledSetState = React.useCallback(
        (value) => {
            if (isDefined(controlledState)) return;
            _setState(value);
        },
        [controlledState]
    );

    return [state, uncontrolledSetState];
}
