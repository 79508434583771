import React from 'react';
import {isFunction} from '#lib/utils/function';
import {isDefined} from '#lib/utils/undefined.js';

/**
 * Given a config (options) object, it includes the default values provided if they are not defined
 * It mutates the original object
 * @param {Object} config
 * @param {Object} defaults
 * @returns
 */
export function defineDefaults(config, defaults = {}) {
    if (!config) return;
    for (const key in defaults) {
        if (!isDefined(config[key], true)) config[key] = defaults[key];
    }
}

/**
 * Checks wether the passed prop is a function, or an object/primitive value.
 * In the first case, it will call and return the function. In the second case,
 * it will only return the prop
 * @param {*} prop
 * @param {*} args
 * @returns {*}
 */
export const ifFunc = (prop, args) => (isFunction(prop) ? prop(args) : prop);

/**
 * Clone a React element assign it `props`, unless
 * the cloned element already defines those prop values
 * It's useful in combination with `React.children.map(child => ...)`
 * @param {React.DetailedReactHTMLElement<{}, HTMLElement>} element the element you want to clone
 * @param {*=} props the props you want to pass to the element, if it doesn't already have them
 * @returns {React.DetailedReactHTMLElement<{}, HTMLElement>}
 */
export function cloneWithPropsIfNotDefined(element, props) {
    if (element === null) return;
    let finalProps = {};
    for (const [key, assignedValue] of Object.entries(props)) {
        const ownValue = element.props?.[key];
        finalProps[key] = ownValue ?? assignedValue;
    }
    return React.cloneElement(element, finalProps);
}
