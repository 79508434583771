import {useContext} from "#routes/trasferisci/index.jsx";
import {useNavigate} from "react-router-dom";
import {useGiacenzeDeposito} from "#hooks/use-giacenze.js";
import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import React from "react";
import {useForm} from "#lib/hooks/use-form.js";
import TabletSelect from "#root/src/lib/@tablet-template/tablet-select.jsx";

function getBinsSelezionati(data) {
    let elementi = Object.keys(data);
    let elementiSelezionati = [];
    for (const el in elementi){
        if(data[elementi[el]] === true){
            elementiSelezionati.push(elementi[el]);
        }
    }
    return elementiSelezionati;
}

export default function SelezionaBins () {
    const {state, setState} = useContext();
    const navigate = useNavigate();
    const movimenti = useGiacenzeDeposito(state.sourceWarehouse, state.porzionato);
    const {data, registerCheckbox} = useForm();

    function onSubmit(event) {
        event.preventDefault();
        const selectedKeys = getBinsSelezionati(data);
        const selectedBins = selectedKeys.map(key => {
            const arr = key.split("|");
            return {idLotto: arr[0], idProdotto: arr[1], idUbicazione: arr[2]}
        });

        if (selectedBins.length === 0)
            return;
        setState((p) => ({
            ...p,
            selectedBins: selectedBins,
            bins: movimenti.data
        }));
        navigate('../deposito-destinazione');
    }

    return (
        <TabletFrame titolo={_t.crud.trasferisci.bins() + " / " + _t.prodotto.prodotto()} formId="bins-form" descrizione={_t.interfaccia.messaggiTrasferisci.prodotto()}>
            {movimenti.isSuccess && (
                <form onSubmit={onSubmit} id="bins-form">
                    <ol className="container-grid">
                        {movimenti.data.length > 0 && movimenti.data.map((movimento) => (
                            <TabletSelect
                                key={movimento.idlotto + "|" + movimento.prodotto.idprodotto + "|" + movimento.idUbicazione}
                                chiave={movimento.idlotto + "|" + movimento.prodotto.idprodotto + "|" + movimento.idUbicazione}
                                isActive={data[movimento.idlotto + "|" + movimento.prodotto.idprodotto + "|" + movimento.idUbicazione]}
                                headerTitle={<div>{movimento.prodotto.prodotto} {movimento.ubicazione?.ubicazione && ' | ' + movimento.ubicazione?.ubicazione}</div>}
                                headerText={<span className="text-neutral-800">{movimento.idlotto} {movimento.tag && " | " + movimento.tag}</span>}
                                emptySlots={5}
                                {...registerCheckbox(movimento.idlotto + "|" + movimento.prodotto.idprodotto + "|" + movimento.idUbicazione)}
                            >
                                <tr className="flex flex-col">
                                    <th className="font-normal text-neutral-800 text-sm text-right">
                                        Quantità
                                    </th>
                                    <td className="font-normal text-neutral-800 text-sm text-right">{movimento.qta}</td>
                                </tr>
                            </TabletSelect>
                        ))}
                    </ol>

                    {movimenti.data.length <= 0 &&
                        <h1 className="m-2">{_t.errore.nessunRisultato()}</h1>
                    }
                </form>
            )}
        </TabletFrame>
    );
}