import React from 'react';
import {cn} from '#lib/helpers/class';

export function Container(props) {
    const {children} = props;
    return <div className="flex flex-col h-full">{children}</div>;
}

export function Header(props) {
    const {children} = props;
    return <header className="contents">{children}</header>;
}

export function Navigation(props) {
    const {children} = props;

    return (
        <div id="nav-bar" className="sticky top-0 z-20 px-4 transition">
            <div className="w-full rounded-none border-white border-b-neutral-100 absolute left-1/2 z-10 h-full -translate-x-1/2 border bg-yellow-50 shadow-md transition-all" />
            <nav
                className={cn(
                    'md:gap-4',
                    'relative z-20 flex h-[56px] items-center transition-all bg-yellow-50'
                )}
            >
                {children}
            </nav>
        </div>
    );
}

export function Content(props) {
    const {children} = props;

    return <div className="isolate z-0 flex-grow">{children}</div>;
}
