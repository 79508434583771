/**
 * Formats texts like C# string.Format method
 * @see https://learn.microsoft.com/en-us/dotnet/api/system.string.format?view=net-7.0#get-started-with-the-stringformat-method
 * @param text: text to format
 * @param params: list of params to replace in text
 * */
export function replaceParams(text, ...params) {
    if (!text || typeof text !== 'string') {
        console.error(
            'Warning: couldn\'t replace parameter in text. Invalid text string: "' +
                text +
                '"'
        );
        return text;
    }
    const textParams = text.match(/\{[0-9]+\}/g);
    if (!textParams) return text;
    if (textParams.length > params.length) {
        console.error(
            'Warning: couldn\'t replace parameter in text. The text string: "' +
                text +
                '" has more params than those passed'
        );
        return text;
    }
    let formattedText = text;

    for (const i in params) {
        formattedText = formattedText.replace('{' + i + '}', params[i]);
    }

    return formattedText;
}
