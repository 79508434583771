import React from "react";
import {Link, Route, Routes} from "react-router-dom";
import SelezionaBins from "./seleziona-bins.jsx";
import Conferma from "#routes/ordini/conferma.jsx";
import TabletOk from "#root/src/lib/@tablet-template/tablet-ok.jsx";
import TabletErrore from "#root/src/lib/@tablet-template/tablet-errore.jsx";
import Quantita from "#routes/ordini/quantita.jsx";
import SelezionaOrdine from "#routes/ordini/seleziona-ordine.jsx";
import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import {Action, List} from "#components/actions.jsx";

const Context = React.createContext();
export const useContext = () => React.useContext(Context);

function Start() {
    return (
        <TabletFrame titolo={_t.crud.ordine.ordini()} descrizione={_t.interfaccia.messaggiOrdini.azione()}>
            <List>
                <List.Item>
                    <Action as={Link} to="confeziona">
                        <Action.Label>{_t.crud.ordine.confeziona()}</Action.Label>
                    </Action>
                </List.Item>
                <List.Item>
                    <Action as={Link} to="chiudi-ordini">
                        <Action.Label>{_t.crud.ordine.chiudiOrdini()}</Action.Label>
                    </Action>
                </List.Item>
                <List.Item>
                    <Action as={Link} to="chiudi-bins">
                        <Action.Label>{_t.crud.ordine.chiudiBins()}</Action.Label>
                    </Action>
                </List.Item>
            </List>
        </TabletFrame>
    );
}

export default function Ordini() {
    const [ordini, setOrdini] = React.useState({});

    return (
        <Context.Provider value={{ordini, setOrdini}}>
            <Routes>
                <Route index element={<Start />} />
                <Route path="confeziona" element={<SelezionaOrdine />} />
                <Route path="chiudi-ordini/*" element={<SelezionaOrdine chiudi={true}/>}/>
                <Route path="chiudi-bins/*" element={<SelezionaBins chiudi={true} />}/>
                <Route path="seleziona-bins" element={<SelezionaBins/>} />
                <Route path="quantita" element={<Quantita/>} />
                <Route path="conferma" element={<Conferma/>} />
                <Route path="fine" element={<TabletOk message={_t.tablet.success()}/>} />
                <Route path="errore" element={<TabletErrore message={_t.tablet.error()} />} />
            </Routes>
        </Context.Provider>
    );
}