import React from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import {Action, List} from "#components/actions.jsx";
import AggiungiProdotti from "#routes/carica/inventario/aggiungi-prodotti.jsx";
import Conferma from "#routes/carica/inventario/conferma.jsx";
import TabletOk from "#root/src/lib/@tablet-template/tablet-ok.jsx";
import TabletErrore from "#root/src/lib/@tablet-template/tablet-errore.jsx";
import {useDepositi} from "#hooks/use-depositi.js";
import Prodotto from "#routes/carica/inventario/prodotto.jsx";
import SelezionaLotti from "#routes/carica/inventario/seleziona-lotti.jsx";
import ProdottoWebgate from "#routes/carica/inventario/prodotto-webgate.jsx";
import ConfermaWebgate from "#routes/carica/inventario/conferma-webgate.jsx";

function Start () {
    const {inventario, setInventario} = useContext();
    const navigate = useNavigate();
    const depositi = useDepositi(false);

    function selectDeposito(deposito) {
        setInventario((p) => ({
            ...p,
            deposito
        }));

        if(inventario.isWebgate)
            navigate('prodotto-webgate');
        else
            navigate('prodotto');
    }

    return (
        <TabletFrame titolo={(inventario.isWebgate? _t.crud.carica.inventarioDaWebgate() : _t.crud.carica.inventarioManuale()) + " / " + _t.deposito.deposito()} descrizione={_t.interfaccia.messaggiCarica.deposito()}>
            {depositi.isSuccess && (
                <List>
                    {depositi.data.map((deposito) => (
                        <List.Item key={deposito.idDeposito}>
                            <Action
                                as='button'
                                onClick={() => selectDeposito(deposito)}
                            >
                                <Action.Label>{deposito.deposito}</Action.Label>
                            </Action>
                        </List.Item>
                    ))}
                </List>
            )}
        </TabletFrame>
    );
}

const Context = React.createContext();
export const useContext = () => React.useContext(Context);

export default function Inventario (props) {
    const {isWebgate} = props;
    const [inventario, setInventario] = React.useState({
        isWebgate: isWebgate
    });

    const {pathname} = useLocation();
    const navigate = useNavigate();

    if(pathname !== '/carica/inventario' && !inventario?.deposito)
        navigate('/carica/inventario');

    return (
        <Context.Provider value={{inventario, setInventario}}>
            <Routes>
                <Route index element={<Start />} />
                <Route path="prodotto" element={<Prodotto />}/>
                <Route path="prodotto-webgate" element={<ProdottoWebgate />}/>
                <Route path="aggiungi-prodotti" element={<AggiungiProdotti />}/>
                <Route path="seleziona-lotti" element={<SelezionaLotti />}/>
                <Route path="conferma" element={<Conferma />}/>
                <Route path="conferma-webgate" element={<ConfermaWebgate />}/>
                <Route path="fine" element={<TabletOk message={_t.tablet.success()}/>} />
                <Route path="errore" element={<TabletErrore message={_t.tablet.error()} />} />
            </Routes>
        </Context.Provider>
    );
}