import {size} from '@floating-ui/dom';

export function availableSize() {
    return size({
        apply({availableWidth, availableHeight, elements}) {
            // Do things with the data, e.g.
            Object.assign(elements.floating.style, {
                maxWidth: `${availableWidth}px`,
                maxHeight: `${availableHeight}px`
            });
        }
    });
}

export function matchReferenceWidth() {
    return size({
        apply({rects, elements}) {
            Object.assign(elements.floating.style, {
                minWidth: `${rects.reference.width}px`
            });
        }
    });
}
