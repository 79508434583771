import React from "react";
import { Toaster as RHT } from "react-hot-toast";

export default function Toaster() {
    return (
        <RHT
            toastOptions={{
                className:
                    "border border-neutral-100 text-sm text-neutral-800 shadow-xl",
            }}
        />
    );
}

export { default as toast } from "react-hot-toast";
