import React from 'react';
import {useContext} from './index.jsx';
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import {useNavigate} from "react-router-dom";
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";

//TODO: valutare se ha senso tenere questa route. Per ora lo lascio perché se dopo la selezione della quantità non è presente l'idLotto
//  (ovvero se la materia che abbiamo selezionato non possedeva un lotto per qualche motivo) naviga qui e te lo fa mettere a mano.
export default function Lotto() {
    const {lavorazione, setLavorazione} = useContext();
    const {fase, prodotto, quantita} = lavorazione;
    const navigate = useNavigate();
    const [lotto, setLotto] = React.useState();
    const inputRef = React.useRef();

    React.useEffect(() => {
        inputRef.current.focus();
    }, [inputRef]);

    function onSubmit(event) {
        event.preventDefault();
        setLavorazione((p) => ({
            ...p,
            prodotto: {...p.prodotto, IDLotto: lotto}
        }));
        navigate("../conferma");
    }

    return (
        <TabletFrame titolo={_t.crud.lavorazione.lavorazione() + " / " + _t.lotto.lotto()} formId='lotto-form' descrizione={_t.interfaccia.messaggiLavorazione.lotto()}>
            <TabletRiepilogoRow label={_t.crud.prodotto.prodotto()} text={prodotto.prodotto} />
            <TabletRiepilogoRow label={_t.operazioni.fase()} text={fase.fase} />
            <TabletRiepilogoRow label={_t.quantita.quantita()} text={quantita} />
            <TabletRiepilogoRow label={_t.lotto.lotto()} text={lotto || _t.errore.nonDefinito()} textCustomClasses={lotto? '' : 'text-neutral-700'} />
            <form className='flex flex-col p-5' onSubmit={onSubmit} id='lotto-form'>
                <p className='text-md font-semibold'>{_t.lotto.codiceLotto()}</p>
                <input
                    ref={inputRef}
                    type='text'
                    name='lotto'
                    value={lotto}
                    onChange={(event) => setLotto(event.target.value)}
                    className='w-full h-full mb-8 text-xl border-b-4 border-neutral-700 text-center'
                    required
                />
            </form>
        </TabletFrame>
    );
}
