import React from 'react';
import {Dialog as HUIDialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/solid';
import {getPopInProps, getFadeInProps} from '#lib/helpers/transition.js';
import {cn} from '#lib/helpers/class.js';

const transitions = {
    opacity: getFadeInProps(),
    scale: getPopInProps()
};

const DialogContext = React.createContext();
DialogContext.displayName = 'DialogContext';

const useDialog = () => React.useContext(DialogContext);

export default function Dialog(props) {
    const {isOpen, onClose, children} = props;

    const _onClose = React.useCallback(() => onClose(), [onClose]);

    return (
        <DialogContext.Provider value={{isOpen, _onClose}}>
            <Transition show={isOpen} as={React.Fragment}>
                <HUIDialog
                    onClose={_onClose}
                    className="fixed inset-0 isolate flex items-center justify-center overflow-auto p-4 md:p-6"
                >
                    <Transition.Child as={React.Fragment} {...transitions.opacity}>
                        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                    </Transition.Child>
                    {children}
                </HUIDialog>
            </Transition>
        </DialogContext.Provider>
    );
}

function CloseButton() {
    const {_onClose} = useDialog();
    return (
        <button
            className="absolute top-2 right-2 rounded-full p-2 hover:bg-neutral-100 md:top-4 md:right-3"
            onClick={_onClose}
        >
            <XMarkIcon className="icon text-neutral-600" />
        </button>
    );
}

function Title(props) {
    const {children} = props;
    return (
        <HUIDialog.Title className="mb-3 pr-8 text-lg text-neutral-700">
            {children}
        </HUIDialog.Title>
    );
}

function Panel(props) {
    const {children, className} = props;
    return (
        <Transition.Child as={React.Fragment} {...transitions.scale}>
            <HUIDialog.Panel
                className={cn('relative z-10 rounded-lg bg-white p-4 md:p-6', className)}
            >
                {children}
            </HUIDialog.Panel>
        </Transition.Child>
    );
}

function Description(props) {
    const {children} = props;

    return (
        <HUIDialog.Description className="mb-4 pr-8 text-neutral-700">
            {children}
        </HUIDialog.Description>
    );
}

function Footer(props) {
    const {color, children} = props;
    const bgColor = React.useMemo(() => {
        switch (color) {
            case 'neutral':
                return 'bg-neutral-100';
            default:
                return 'bg-white';
        }
    }, [color]);

    return (
        <div
            className={`${bgColor} -mx-4 -mb-4 mt-6 flex justify-end gap-4 rounded-br-xl rounded-bl-xl px-6 py-3 md:-mx-6 md:-mb-6`}
        >
            {children}
        </div>
    );
}

function Full(props) {
    const {title, description, isOpen = false, onClose, children} = props;

    return (
        <Dialog isOpen={isOpen} onClose={onClose}>
            <Panel className="relative z-10 rounded-lg bg-white p-4 md:p-6">
                <CloseButton onClick={onClose} />
                {title && <Title>{title}</Title>}
                {description && <Description>{description}</Description>}
                {children}
            </Panel>
        </Dialog>
    );
}

Dialog.Full = Full;
Dialog.Panel = Panel;
Dialog.Title = Title;
Dialog.Description = Description;
Dialog.CloseButton = CloseButton;
Dialog.Footer = Footer;
