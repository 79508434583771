import React from 'react';
import {isFunction} from '#lib/utils/function.js';
import {useWindowEvent} from './use-window-event';

function get(key, defaultValue) {
    if (!window) return defaultValue;
    const item = window.sessionStorage.getItem(key);
    try {
        const parsedValue = JSON.parse(item);
        return parsedValue ?? defaultValue;
    } catch {
        return defaultValue;
    }
}

function set(key, value) {
    try {
        const toStore = isFunction(value) ? value(value) : value;
        window.sessionStorage.setItem(key, JSON.stringify(toStore));
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

function remove(key) {
    window.sessionStorage.removeItem(key);
}

export function useSessionStorage(key, initialValue, clearOnReload = false) {
    const [state, _setState] = React.useState(get(key, initialValue));

    useWindowEvent('beforeunload', () => remove(key), {enabled: clearOnReload});

    const setState = React.useCallback(
        (newValue) => {
            const _newValue = isFunction(newValue) ? newValue(state) : newValue;
            const success = set(key, _newValue);
            if (!success) return;
            _setState(_newValue);
        },
        [key, state]
    );

    return [state, setState];
}

export function useSessionStorageReducer(
    key,
    reducer,
    initialValue,
    clearOnReload = false
) {
    const [state, _dispatch] = React.useReducer(reducer, get(key, initialValue));

    useWindowEvent('beforeunload', () => remove(key), {enabled: clearOnReload});

    const dispatch = React.useCallback(
        (action) => {
            const newState = reducer(state, action);
            const success = set(key, newState);
            if (!success) return;
            _dispatch(action);
        },
        [key, state, reducer]
    );
    return [state, dispatch];
}
