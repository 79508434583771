export const jsonHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

export async function handleErrors(response) {
    if (!response.ok || (response.status >= 400 && response.status < 600)) {
        const data = await response.json();
        const errorMessage = data.errorText || response.statusText;
        const error = new Error(errorMessage);
        error.cause = {
            ...data,
            statusCode: response.status,
            statusText: response.statusText
        };
        throw error;
    }
    return response.json();
}

export async function fetchAndParse(url, options = {}) {
    const response = await fetch(url, options);
    return handleErrors(response);
}
