import React from 'react';
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useClick,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    FloatingFocusManager
} from '@floating-ui/react';
import useControlledState from '#lib/hooks/use-controlled-state.js';
import {InPortal, defineDefaults, makeContext} from '#lib/helpers/react.jsx';
import {availableSize} from '#lib/helpers/floating-ui.js';
import {callIfDefined, isFunction} from '#lib/utils/function.js';
import {Transition} from '@headlessui/react';
import {undefineIf} from '#lib/utils/undefined.js';
import {getPopInProps} from '#lib/helpers/transition.js';
import useGranularEffect from '#lib/hooks/use-granular-effect.js';
import useHasMounted from '#lib/hooks/use-has-mounted.js';

const defaultOptions = {
    initialIsOpen: false,
    interactions: ['click'],
    middlewares: [],
    animated: true,
    placement: 'bottom'
};

function useProvidePopover(options) {
    defineDefaults(options, defaultOptions);
    const {
        initialIsOpen,
        isOpen: controlledOpen,
        interactions,
        middlewares,
        placement,
        hoverDelay,
        onOpen,
        onClose
    } = options;

    const [isOpen, setIsOpen] = useControlledState(controlledOpen, initialIsOpen);
    /* eslint-disable */
    const open = React.useCallback(() => setIsOpen(true), []);
    const close = React.useCallback(() => setIsOpen(false), []);
    /* eslint-enable */

    const didMount = useHasMounted();

    useGranularEffect(
        () => {
            if (!didMount) return;
            if (isOpen) callIfDefined(onOpen);
            else callIfDefined(onClose);
        },
        [isOpen],
        [onOpen, onClose, didMount]
    );

    const {refs, floatingStyles, context} = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement,
        middleware: [
            offset(8),
            flip({padding: 6}),
            shift(),
            availableSize(),
            ...middlewares
        ],
        whileElementsMounted: autoUpdate
    });

    const focus = useFocus(context, {enabled: interactions.includes('focus')});
    const click = useClick(context, {enabled: interactions.includes('click')});
    const hover = useHover(context, {
        enabled: interactions.includes('hover'),
        delay: hoverDelay
    });

    const dismiss = useDismiss(context);
    const role = useRole(context);

    // Merge all the interactions into prop getters
    const {getReferenceProps, getFloatingProps} = useInteractions([
        focus,
        click,
        hover,
        dismiss,
        role
    ]);

    const reference = {ref: refs.setReference};
    const trigger = getReferenceProps();
    const button = {...reference, ...trigger};
    const panel = {ref: refs.setFloating, style: floatingStyles, ...getFloatingProps()};

    return {
        context,
        isOpen,
        reference,
        trigger,
        button,
        panel,
        open,
        close
    };
}

const [Popover, useContext] = makeContext(useProvidePopover, 'Popover');

function Reference(props) {
    const {as = 'div', children, ...rest} = props;
    const {reference} = useContext();
    return React.createElement(as, {...reference, ...rest}, children);
}

function Trigger(props) {
    const {as = 'button', children, ...rest} = props;
    const {trigger} = useContext();
    let mergedProps = {...trigger, ...rest};
    if (mergedProps.as === 'button') mergedProps.type = 'button';
    return React.createElement(as, mergedProps, children);
}

function Button(props) {
    const {as = 'button', children, ...rest} = props;
    const {button} = useContext();
    let mergedProps = {...button, ...rest};
    if (mergedProps.as === 'button') mergedProps.type = 'button';
    return React.createElement(as, mergedProps, children);
}

const transition = getPopInProps();

function Panel(props) {
    const {as = 'div', children, style, ...rest} = props;
    const {context, panel, isOpen, animated} = useContext();
    const mergedProps = {...panel, ...rest, style: {...style, ...panel.style}};
    return (
        <FloatingFocusManager context={context} modal={false}>
            {isFunction(children) ? (
                children({isOpen, panel, context})
            ) : (
                <Transition
                    show={isOpen}
                    as={as}
                    {...undefineIf(transition, !animated)}
                    {...mergedProps}
                >
                    {children}
                </Transition>
            )}
        </FloatingFocusManager>
    );
}

Popover.Reference = Reference;
Popover.Trigger = Trigger;
Popover.Button = Button;
Popover.Panel = InPortal(Panel);
export default Popover;
