import {fetchAndParse} from "#lib/helpers/fetch.js";
import {useAuthQuery} from "#lib/hooks/use-query.js";

export function useProdotti(params) {
    const {deposito, porzionato} = params || {};
    const {data, ...queryProps} = useAuthQuery({
        queryKey: ['prodotti'],
        staleTime: 'Infinity',
        refetchOnWindowFocus: false,
        retry: false,
        retryOnMount: false,
        queryFn: (_, headers) =>
            fetchAndParse(`${API_URL}/p/prodotti?idDeposito=${deposito || ''}&porzionato=${porzionato || ''}`, {
                headers
            })
    });

    return {data: data?.results, ...queryProps};
}
