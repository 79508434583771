import React from 'react';
import {mergeClasses, cn} from '#lib/helpers/class.js';

const colors = {
    neutral: 'bg-neutral-100 text-neutral-800',
    red: 'bg-red-100 text-red-800',
    yellow: 'bg-yellow-100 text-yellow-800',
    green: 'bg-green-100 text-green-800',
    blue: 'bg-blue-100 text-blue-800',
    indigo: 'bg-indigo-100 text-indigo-800',
    purple: 'bg-purple-100 text-purple-800',
    pink: 'bg-pink-100 text-pink-800'
};

export default function Badge(props) {
    const {color = 'neutral', className, children, ...rest} = props;
    const colorClasses = React.useMemo(() => colors[color], [color]);
    return (
        <span
            className={mergeClasses(
                cn(
                    'inline-flex whitespace-nowrap text-center items-center rounded-full px-2.5 py-0.5 text-xs font-medium',
                    colorClasses
                ),
                className
            )}
            {...rest}
        >
            {children}
        </span>
    );
}
