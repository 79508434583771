import React from 'react';
import {useContext} from "#routes/trasferisci/index.jsx";
import {useNavigate} from 'react-router-dom';
import {Action} from "#components/actions.jsx";
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";

const presetQuantities = [1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 20];
export default function Quantita() {
    const {state, setState} = useContext();
    const navigate = useNavigate();
    const maxQuantity = state.warehouseProduct?.qta;
    const [quantity, setQuantity] = React.useState(maxQuantity);

    function onSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        setState((p) => ({
            ...p,
            numeroForme: formData.get('quantita')
        }));
        navigate('../deposito-destinazione');
    }

    function increase() {
        setQuantity((p) => {
            return p + 1;
        });
    }

    function decrease() {
        setQuantity((p) => {
            if (p <= 1) return 1;
            return p - 1;
        });
    }

    return (
        <TabletFrame titolo={_t.crud.spostaForme.spostaForme() + " / " + _t.quantita.quantita()} formId='forme-form' descrizione={_t.interfaccia.messaggiTrasferisci.quantita()}>
            <TabletRiepilogoRow label={_t.prodotto.prodotto()} text={state.warehouseProduct?.prodotto.prodotto}/>
            <TabletRiepilogoRow
                label={_t.quantita.numeroForme()}
                text={quantity + ' (' + state.warehouseProduct?.prodotto.idum + ' ' + Math.round(quantity * state.warehouseProduct?.prodotto.peso * 100) / 100 + ')'}
                textCustomClasses={quantity > maxQuantity? 'text-red-600' : ''}/>
            <TabletRiepilogoRow label={_t.quantita.totale()} text={state.warehouseProduct?.qta} />

            <div className="grid grid-cols-4 gap-4 mb-6 p-5">
                <Action as="button" onClick={decrease}>
                    <Action.Label>-1</Action.Label>
                </Action>
                <form className="col-span-2" id="forme-form" onSubmit={onSubmit}>
                    <input
                        min={0}
                        max={maxQuantity}
                        step={1}
                        type="number"
                        className="w-full h-full text-3xl font-bold border-b-4 border-neutral-700 text-center"
                        name="quantita"
                        value={quantity}
                        onChange={(event) => setQuantity(event.target.value)}
                    />
                </form>
                <Action as="button" onClick={increase}>
                    <Action.Label>+1</Action.Label>
                </Action>
            </div>
            <div className="grid grid-cols-4 gap-4 mb-8 p-5">
                {presetQuantities.map((quantity) => (
                    <Action
                        key={quantity}
                        as="button"
                        onClick={() => setQuantity(quantity)}
                    >
                        <Action.Label>{quantity}</Action.Label>
                    </Action>
                ))}
            </div>
        </TabletFrame>
    );
}
