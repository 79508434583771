import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import {useContext} from './index.jsx';
import {useNavigate} from "react-router-dom";
import React from "react";
import {useEffettuaLavorazione} from "#hooks/use-lavorazione.js";
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";

export default function Conferma() {
    const {lavorazione} = useContext();
    const navigate = useNavigate();
    const effettuaLavorazioneMutation = useEffettuaLavorazione();

    async function OnEffettuaLavorazione() {
        effettuaLavorazioneMutation.mutate(lavorazione, {
            onSuccess: (res) => {res?.ok ? navigate('../fine') : navigate('../errore')},
            onError: () => navigate('../errore')
        })
    }

    return (
        <TabletFrame titolo={_t.crud.lavorazione.lavorazione() + " / " + _t.tablet.riepilogo()} onConfirm={OnEffettuaLavorazione}
                     descrizione={_t.interfaccia.messaggiLavorazione.conferma()}>
            <TabletRiepilogoRow label={_t.prodotto.prodotto()} text={lavorazione.prodotto.prodotto} />
            <TabletRiepilogoRow label={_t.operazioni.fase()} text={lavorazione.fase.fase} />
            <TabletRiepilogoRow label={_t.quantita.quantita()} text={lavorazione.quantita} />
            <TabletRiepilogoRow label={_t.lotto.lotto()} text={lavorazione.prodotto.IDLotto} />
            <TabletRiepilogoRow label={_t.crud.movimenti.tag()} text={lavorazione.prodotto.Tag}/>
        </TabletFrame>
    );
}
