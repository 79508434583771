import React from 'react';
import Table from 'lib/components/table';
import Panel from './panel';
import {Spinner} from 'lib/components/loading';
import {LoadingIndicator} from 'lib/components/loading';
import {cn, addClasses} from 'lib/helpers/class';
import {DocumentMagnifyingGlassIcon, XMarkIcon} from '@heroicons/react/24/solid';

export default function SearchResults(props) {
    const {data, isSuccess, isLoading, isRefetching, isError, ...tableProps} = props;
    const noEntries = !data || data?.pages.at(0).results.length === 0;
    const hasEntries = !noEntries;
    const totalResults = data?.pages.at(0).totalResults;
    const currentResults = data?.pages.reduce((acc, data) => acc + data.results.length, 0);

    return (
        <React.Fragment>
            {isLoading && (
                <Panel>
                    <Spinner className={addClasses('mx-auto')} />
                </Panel>
            )}
            {isSuccess && hasEntries && (
                <div className="relative grid">
                    <Table.Full
                        data={data}
                        spacing="compact"
                        className={addClasses('area-span-full')}
                        accessor={(data) => data.results}
                        {...tableProps}
                        paginated
                    />
                    <LoadingIndicator
                        className={addClasses(
                            cn(
                                isRefetching ? 'opacity-1' : 'opacity-0',
                                'area-span-full pointer-events-none sticky inset-y-0 my-4 mr-2 self-end justify-self-end rounded-full bg-white/80 p-1 transition-opacity'
                            )
                        )}
                    />
                    {totalResults && <div className={'self-end justify-self-end mt-1 text-sm'}>{currentResults} risultati di {totalResults}</div>}
                </div>
            )}
            {(isSuccess || isError) && noEntries && (
                <Panel>
                    <div className="flex items-center justify-center gap-2">
                        {isSuccess && (
                            <DocumentMagnifyingGlassIcon className="icon text-neutral-600" />
                        )}
                        {isError && <XMarkIcon className="icon text-neutral-600" />}
                        <p className="text-center text-sm text-neutral-800">
                            {isSuccess && _t.messaggio.nessunRisultato()}
                            {isError && _t.errore.impossibileCaricareRisultati()}
                        </p>
                    </div>
                </Panel>
            )}
        </React.Fragment>
    );
}
