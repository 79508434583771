import {useContext} from "./index.jsx";
import {useNavigate} from "react-router-dom";
import React from "react";
import {useSpostaBins, useSpostaForme} from "#hooks/use-movimenti.js";
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import {cn} from "#lib/helpers/class.js";
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";

export default function Conferma() {
    const {state} = useContext();
    const navigate = useNavigate();

    const spostaFormeMutation = useSpostaForme();
    const spostaBinsMutation = useSpostaBins();
    const bins = state?.bins?.filter((bin) =>  {
        return state.selectedBins.some(
            el => el.idLotto === bin.idlotto && el.idProdotto === bin.prodotto.idprodotto && el.idUbicazione === bin.idUbicazione
        );
    });

    async function onTrasferisci() {
        if (state.porzionato) {
            spostaBinsMutation.mutate({
                sourceWarehouse: state.sourceWarehouseId,
                targetWarehouse: state.targetWarehouseId,
                bins: bins
            }, {
                onSuccess: (res) => {res?.ok ? navigate('../fine') : navigate('../errore')},
                onError: () => navigate('../errore')
            })
        } else {
            spostaFormeMutation.mutate({
                sourceWarehouse: state.sourceWarehouseId,
                targetWarehouse: state.targetWarehouseId,
                product: state.warehouseProduct,
                qta: state.numeroForme
            }, {
                onSuccess: (res) => {res?.ok ? navigate('../fine') : navigate('../errore')},
                onError: () => navigate('../errore')
            });
        }
    }

    return (
        <TabletFrame titolo={_t.crud.trasferisci.trasferisci() + " / " + _t.tablet.riepilogo()} onConfirm={onTrasferisci} descrizione={_t.interfaccia.messaggiTrasferisci.conferma()}>
            {state.warehouseProduct &&
                <>
                    <TabletRiepilogoRow label={_t.prodotto.prodotto()} text={state.warehouseProduct.prodotto.prodotto} />
                    <TabletRiepilogoRow label={_t.deposito.daCella()} text={state.sourceWarehouse} />
                    <TabletRiepilogoRow label={_t.deposito.aCella()} text={state.targetWarehouse} />
                    <TabletRiepilogoRow label={_t.quantita.quantita()} text={state.numeroForme} />
                    <TabletRiepilogoRow
                        label={_t.lotto.lotto()}
                        text={state.warehouseProduct.idlotto || _t.errore.nonDefinito()}
                        textCustomClasses={state.warehouseProduct.idlotto? '' : 'text-neutral-700'}/>
                </>
            }
            {state?.selectedBins?.length > 0 && (
                <>
                    <ol className="container-single-grid">
                        {bins.map((bin) => (
                            <li
                                key={bin.idlotto + bin.idprodotto + bin.idUbicazione}
                                className={cn(
                                    "item-grid",
                                    ["relative isolate text-md bg-neutral-50 mb-6 p-4"]
                                )}
                            >
                                <header className="w-full ">
                                    <p className="font-semibold mb-2 text-neutral-800">
                                        {bin.prodotto.prodotto} {bin.ubicazione?.ubicazione && ' | ' + bin.ubicazione?.ubicazione}
                                        <p className="text-neutral-800">{bin.idlotto} <span className="text-neutral-800">{bin.tag && " | " + bin.tag}</span></p>
                                    </p>
                                </header>
                                <table className="contents">
                                    <tbody className="contents">
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            Quantità
                                        </th>
                                        <td className="font-normal text-neutral-800  text-sm text-right">{bin.qta}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </li>
                        ))}
                    </ol>
                    <TabletRiepilogoRow label={_t.deposito.daCella()} text={state.sourceWarehouse} />
                    <TabletRiepilogoRow label={_t.deposito.aCella()} text={state.targetWarehouse} />
                </>
            )}
        </TabletFrame>
    );
}
