import React from 'react';
import {mergeClasses, cn} from '#lib/helpers/class.js';

const CheckboxContext = React.createContext();
CheckboxContext.displayName = 'CheckboxContext';

const useCheckbox = () => React.useContext(CheckboxContext);

function Container(props) {
    const {as = 'div', className, children, ...rest} = props;
    const inputId = React.useId();
    const descriptionId = React.useId();

    return (
        <CheckboxContext.Provider value={{inputId, descriptionId}}>
            {React.createElement(
                as,
                {
                    className: mergeClasses(
                        'grid checkbox-container-grid text-sm items-baseline',
                        className
                    ),
                    ...rest
                },
                children
            )}
        </CheckboxContext.Provider>
    );
}

function Input(props) {
    const {className, disabled, ...rest} = props;
    const {inputId, descriptionId} = useCheckbox();
    return (
        <input
            id={inputId}
            aria-describedby={descriptionId}
            disabled={disabled}
            type="checkbox"
            className={mergeClasses(
                cn(
                    disabled && 'opacity-75',
                    'row-span-2 h-4 w-4 translate-y-0.5 cursor-pointer rounded border-neutral-300 text-primary-700 focus:ring-primary-500'
                ),
                className
            )}
            {...rest}
        />
    );
}

function Label(props) {
    const {className, children, ...rest} = props;
    const {inputId} = useCheckbox();
    return (
        <label
            htmlFor={inputId}
            className={mergeClasses('text-sm font-medium text-neutral-700', className)}
            {...rest}
        >
            {children}
        </label>
    );
}

function Description(props) {
    const {className, children, ...rest} = props;
    const {descriptionId} = useCheckbox();
    return (
        <p
            id={descriptionId}
            className={mergeClasses('mt-1 text-neutral-500', className)}
            {...rest}
        >
            {children}
        </p>
    );
}

function Full(props) {
    const {label, description, className, style, ...rest} = props;

    return (
        <Container className={className} style={style}>
            <Input {...rest} />
            {label && <Label>{label}</Label>}
            {description && <Description>{description}</Description>}
        </Container>
    );
}

export default {
    Container,
    Input,
    Label,
    Description,
    Full
};
