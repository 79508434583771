import {Link, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import React from "react";
import Deposito from "./deposito.jsx";
import ProdottiDeposito from "./prodotti-deposito.jsx";
import Quantita from "./quantita.jsx";
import Conferma from "./conferma.jsx";
import TabletOk from '#root/src/lib/@tablet-template/tablet-ok.jsx';
import TabletErrore from '#root/src/lib/@tablet-template/tablet-errore.jsx';
import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import {Action, List} from "#components/actions.jsx";
import SelezionaBins from "#routes/trasferisci/seleziona-bins.jsx";

const Context = React.createContext();
export const useContext = () => React.useContext(Context);

function Start() {
    return (
        <TabletFrame titolo={_t.crud.trasferisci.trasferisci()} descrizione={_t.interfaccia.messaggiTrasferisci.azione()}>
            <List>
                <List.Item>
                    <Action as={Link} to="forme/deposito-sorgente">
                        <Action.Label>{_t.crud.trasferisci.forme()}</Action.Label>
                    </Action>
                </List.Item>
                <List.Item>
                    <Action as={Link} to="bins/deposito-sorgente">
                        <Action.Label>{_t.crud.trasferisci.bins()}</Action.Label>
                    </Action>
                </List.Item>
            </List>
        </TabletFrame>
    );
}

export default function Trasferisci() {
    const [state, setState] = React.useState({});

    const {pathname} = useLocation();
    const navigate = useNavigate();
    const validPaths = ['/trasferisci', '/trasferisci/forme/deposito-sorgente', '/trasferisci/bins/deposito-sorgente'];

    if(!validPaths.includes(pathname) && !state?.sourceWarehouse)
        navigate('/trasferisci');

    return (
        <Context.Provider value={{state, setState}}>
            <Routes>
                <Route index element={<Start />} />
                <Route path="deposito-destinazione" element={<Deposito direction={"destinazione"}/>} />
                <Route path="forme/deposito-sorgente" element={<Deposito direction={"sorgente"} filter={"forme"}/>} />
                <Route path="bins/deposito-sorgente" element={<Deposito direction={"sorgente"} filter={"bins"}/>} />
                <Route path="forme/prodotti-deposito" element={<ProdottiDeposito />} />
                <Route path="bins/seleziona-bins" element={<SelezionaBins />}/>
                <Route path="quantita" element={<Quantita />} />
                <Route path="conferma" element={<Conferma />} />
                <Route path="fine" element={<TabletOk message={_t.tablet.success()} />} />
                <Route path="errore" element={<TabletErrore message={_t.tablet.error()} />} />
            </Routes>
        </Context.Provider>
    );
}
