import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useContext} from './index.jsx';
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";

export default function Lotto() {
    const {caricaBins, setCaricaBins} = useContext();
    const {prodotto, quantita, pezzi} = caricaBins;

    const navigate = useNavigate();

    const [lotto, setLotto] = React.useState();
    const inputRef = React.useRef();

    React.useEffect(() => {
        inputRef.current.focus();
    }, [inputRef]);

    function onSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        setCaricaBins((p) => ({
            ...p,
            idLotto: formData.get('lotto')
        }));
        navigate('../deposito');
    }

    return (
        <TabletFrame titolo={_t.crud.carica.bins() + " / " + _t.lotto.lotto()} formId='form-lotto' descrizione={_t.interfaccia.messaggiCarica.lotto()}>
            <TabletRiepilogoRow label={_t.prodotto.prodotto()} text={prodotto.name}/>
            <TabletRiepilogoRow label={_t.quantita.quantita()} text={quantita + ' Kg / ' + pezzi + ' ' + _t.spedizione.pezzi()}/>
            <TabletRiepilogoRow label={_t.lotto.lotto()} text={lotto || ''}/>

            <form className='flex flex-col p-5' onSubmit={onSubmit} id='form-lotto'>
                <p className='text-md font-semibold'>{_t.lotto.codiceLotto()}</p>
                <input
                    ref={inputRef}
                    type='text'
                    name='lotto'
                    value={lotto}
                    onChange={(event) => setLotto(event.target.value)}
                    className='w-full h-full mb-8 text-xl border-b-4 border-neutral-700 text-center'
                    required
                />
            </form>
        </TabletFrame>
    );
}
