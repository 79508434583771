import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import {useContext} from "#routes/carica/inventario/index.jsx";
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";
import React from "react";
import {Action, List} from "#components/actions.jsx";
import {useCaricaInventario} from "#hooks/use-movimenti.js";
import {useNavigate} from "react-router-dom";

export default function Conferma() {
    const {inventario} = useContext();
    const caricaInventarioMutation = useCaricaInventario();
    const navigate = useNavigate();

    function onCaricaInventario () {
        caricaInventarioMutation.mutate(
            inventario, {
            onSuccess: (res) => {res?.ok ? navigate('../fine') : navigate('../errore')},
            onError: () => navigate('../errore')
        })
    }

    return (
        <TabletFrame
            titolo={_t.crud.carica.inventarioManuale() + " / " + _t.tablet.riepilogo()}
            descrizione={_t.interfaccia.messaggiCarica.conferma()}
            onConfirm={onCaricaInventario}
        >
            <TabletRiepilogoRow label={_t.prodotto.prodotto()} text={inventario.prodotto.prodotto} />
            <TabletRiepilogoRow label={_t.deposito.deposito()} text={inventario.deposito.deposito} />
            <List>
                {inventario.listaProdotti.map(lotto => (
                    <List.Item key={lotto.id}>
                        <Action as="div"
                        >
                            <header className="w-full flex justify-between">
                                <span className="font-semibold mb-2 text-neutral-800">{lotto.lotto}</span>
                                <div>
                                    <table className="contents">
                                        <tbody className="contents">
                                        <tr className="flex flex-col">
                                            <th className="font-normal text-neutral-800 text-sm text-right">
                                                Qta
                                            </th>
                                            <td className="text-sm text-right">{lotto.qta}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </header>
                        </Action>
                    </List.Item>
                ))}
            </List>
        </TabletFrame>
    );
}