import React from 'react';
import Portal from '#lib/components/portal.jsx';
import {isFunction} from '#lib/utils/function.js';
import {isDefined, isUndefined} from '#lib/utils/undefined.js';

export function makeContext(providerHook, name) {
    const Context = React.createContext();
    if (name) Context.displayName = `${name}Context`;

    const ProvidedContext = ({children, ...config}) => {
        const value = isDefined(providerHook, false) ? providerHook(config) : config;
        return (
            <Context.Provider value={value}>
                {isFunction(children) ? children(value) : children}
            </Context.Provider>
        );
    };
    const useContext = () => React.useContext(Context);

    return [ProvidedContext, useContext];
}

export function InPortal(Panel) {
    return function PanelInPortal(props) {
        const {Portal: ProvidedPortal, ...rest} = props;
        switch (ProvidedPortal) {
            case undefined:
                return (
                    <Portal>
                        <Panel {...rest} />
                    </Portal>
                );
            case null:
                return <Panel {...rest} />;
            default:
                return React.cloneElement(ProvidedPortal, {}, <Panel {...rest} />);
        }
    };
}

/**
 * Given a config (options) object, it includes the default values provided if they are not defined
 * It mutates the original object
 * @param {Object} config
 * @param {Object} defaults
 * @returns
 */
export function defineDefaults(config, defaults) {
    if (!config) return;
    for (const key in defaults) {
        if (isUndefined(config[key], true)) config[key] = defaults[key];
    }
}

/**
 * Checks wether the passed prop is a function, or an object/primitive value.
 * In the first case, it will call and return the function. In the second case,
 * it will only return the prop
 * @param {*} prop
 * @param {*} args
 * @returns {*}
 */
export const ifFunc = (prop, args) => (isFunction(prop) ? prop(args) : prop);

/**
 * Clone a React element assign it `props`, unless
 * the cloned element already defines those prop values
 * It's useful in combination with `React.children.map(child => ...)`
 * @param {React.DetailedReactHTMLElement<{}, HTMLElement>} element the element you want to clone
 * @param {*=} props the props you want to pass to the element, if it doesn't already have them
 * @returns {React.DetailedReactHTMLElement<{}, HTMLElement>}
 */
export function cloneWithPropsIfNotDefined(element, props) {
    if (element === null) return;
    let finalProps = {};
    for (const [key, assignedValue] of Object.entries(props)) {
        const ownValue = element.props?.[key];
        finalProps[key] = ownValue ?? assignedValue;
    }
    return React.cloneElement(element, finalProps);
}
