import React from 'react';
import {Action, List} from '#components/actions.jsx';
import {useContext} from './index.jsx';
import {useNavigate} from 'react-router-dom';
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import {useProdotti} from "#hooks/use-prodotti.js";

export default function Prodotto() {
    const {setInventario} = useContext();
    const prodotti = useProdotti({porzionato: "false"});
    const navigate = useNavigate();

    function selectProduct(prodotto) {
        setInventario((p) => ({
            ...p,
            prodotto: {
                id: prodotto.id,
                prodotto: prodotto.prodotto,
                codice: prodotto.codice,
                idUm: prodotto.idUm,
            }
        }));

        navigate('../aggiungi-prodotti');
    }

    return (
        <TabletFrame titolo={_t.crud.carica.inventarioManuale() + " / " + _t.prodotto.prodotto()} descrizione={_t.interfaccia.messaggiCarica.prodotto()}>
            <List>
                {prodotti.data?.length > 0 && prodotti.isSuccess &&
                    prodotti.data.map((prodotto) => (
                        <List.Item key={prodotto.codice}>
                            <Action
                                as="button"
                                onClick={() => selectProduct(prodotto)}
                            >
                                <Action.Label>{prodotto.prodotto}{prodotto.id.includes('SL')? ' - SL' : ''}</Action.Label>
                            </Action>
                        </List.Item>
                    ))}
                {prodotti.data?.length <= 0 &&
                    <h1 className="m-2">{_t.errore.nessunRisultato()}</h1>
                }
            </List>
        </TabletFrame>
    );
}
