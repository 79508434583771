import {ENVIRONMENTS} from '#lib/constants.js';

const isDevelopment = ENV === ENVIRONMENTS.DEVELOPMENT;
const isProduction = !isDevelopment;

const makeUrl = (isDevelopment) =>
    isDevelopment ? `http://${APP_DOMAIN}:${APP_PORT}` : `https://${APP_DOMAIN}`;

export const issuer = 'ABSOLUTE';

export default {
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: `${AWS_REGION}`,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: `${COGNITO_UP_ID}`,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: `${COGNITO_UP_WEB_CLIENT_ID}`,
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - customized storage object
        //storage: MyStorage,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        //authenticationFlowType: 'USER_SRP_AUTH',

        //OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        //clientMetadata: {myCustomKey: 'myCustomValue'},
        cookieStorage: {
            domain: location.hostname,
            secure: isProduction,
            // Needed in order not to be signed out when returning from external URL
            // e.g.: returning from a Stripe checkout session
            sameSite: 'lax'
        },
        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: location.hostname,
            scope: [
                'phone',
                'email',
                'profile',
                'openid',
                'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: makeUrl(isDevelopment),
            redirectSignOut: makeUrl(isDevelopment),
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
};
