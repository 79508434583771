import React from 'react';
import {Link} from 'react-router-dom';
import useAuth from '#lib/hooks/use-auth.jsx';
import {isFunction} from '#lib/utils/function.js';
import {isFalse} from '#lib/utils/boolean.js';
import {addClasses, mergeClasses} from '#lib/helpers/class.js';
import {formToObject} from '#lib/utils/form.js';
import Input from '#lib/components/input.jsx';
import Button from '#lib/components/button.jsx';
import Aside from '#lib/components/aside.jsx';
import Checkbox from '#lib/components/checkbox';

export const STATES = {
    SIGN_IN: 'signIn',
    SIGN_UP: 'signUp',
    VERIFICATION: 'verification',
    COMPLETE_NEW_PASSWORD: 'completeNewPassword',
    CHANGE_PASSWORD: 'changePassword',
    FORGOT_PASSWORD: 'forgotPassword',
    CREATE_NEW_PASSWORD: 'forgotPasswordSubmit',
    VERIFY_EMAIL: 'confirmSignUp'
};

function SignInFields() {
    return (
        <React.Fragment>
            <Input.Full
                className="w-full"
                type="text"
                label={_t.auth.username()}
                name="username"
                autoComplete="false"
            />
            <Input.Full
                className="w-full"
                type="password"
                label={_t.auth.password()}
                name="password"
            />
            {/*<div className="flex flex-wrap justify-end gap-4">*/}
            {/*    <Link to="/forgot-password" className="text-sm text-primary-700">*/}
            {/*        {_t.auth.forgotPassword()}*/}
            {/*    </Link>*/}
            {/*</div>*/}
        </React.Fragment>
    );
}

function SignUpFields() {
    return (
        <React.Fragment>
            <Input.Full
                className="w-full"
                type="text"
                label={_t.auth.username()}
                name="username"
                autoComplete="false"
            />
            <Input.Full
                className="w-full"
                type="password"
                label={_t.global.password()}
                name="password"
            />
            <Input.Full
                className="w-full"
                type="password"
                label={_t.auth.confirmPassword()}
                name="confirmpassword"
            />
            <Input.Full
                className="w-full"
                type="text"
                label={_t.auth.fullName()}
                name="name"
            />
            <Checkbox.Full
                type="checkbox"
                name="acknowledgement"
                label={
                    <React.Fragment>
                        I agree with the{' '}
                        <Link
                            className="text-sky-700 underline hover:no-underline"
                            to="/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            privacy policy
                        </Link>
                        ,{' '}
                        <Link
                            className="text-sky-700 underline hover:no-underline"
                            to="/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            terms &amp; conditions
                        </Link>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
}

function ForgotPasswordFields() {
    return (
        <React.Fragment>
            <div className="flex flex-wrap justify-between gap-4">
                <span className="text-center text-lg text-primary-700">
                    {_t.auth.forgotPassword()}
                </span>
            </div>
            <div className="flex flex-wrap justify-between gap-2">
                <span className="text-xs">{_t.auth.forgotPasswordInfo()}</span>
            </div>
            <Input.Full
                className="w-full"
                type="email"
                label={_t.auth.email()}
                name="email"
            />
        </React.Fragment>
    );
}

function CreateNewPasswordFields() {
    return (
        <React.Fragment>
            <div className="flex flex-wrap justify-between gap-4">
                <span className="text-center text-lg text-primary-700">
                    {_t.auth.createNewPasswordTitle()}
                </span>
            </div>
            <div className="flex flex-wrap justify-between gap-2">
                <span className="text-xs">{_t.auth.createNewPasswordLegenda()}</span>
            </div>
            <Input.Full
                className="w-full"
                type="text"
                label={_t.auth.email()}
                name="email"
                autoComplete="false"
            />
            <Input.Full
                className="w-full"
                type="text"
                label={_t.auth.verificationCode()}
                name="code"
            />
            <Input.Full
                className="w-full"
                type="password"
                label={_t.auth.newPassword()}
                name="password"
            />
            <Input.Full
                className="w-full"
                type="password"
                label={_t.auth.confirmPassword()}
                name="confirmpassword"
            />
        </React.Fragment>
    );
}

function ChangePasswordFields() {
    return (
        <React.Fragment>
            <div className="flex flex-wrap justify-between gap-4">
                <span className="text-center text-lg text-primary-700">
                    {_t.auth.changePasswordTitle()}
                </span>
            </div>
            <div className="flex flex-wrap justify-between gap-2">
                <span className="text-xs">{_t.auth.changePasswordLegenda()}</span>
            </div>
            <Input.Full
                className="w-full"
                type="password"
                label={_t.auth.password()}
                name="password"
            />
            <Input.Full
                className="w-full"
                type="password"
                label={_t.auth.confirmPassword()}
                name="confirmpassword"
            />
        </React.Fragment>
    );
}

function CompletePasswordFields() {
    return (
        <React.Fragment>
            <div className="flex flex-wrap justify-between gap-4">
                <span className="text-center text-lg text-primary-700">
                    {_t.auth.completaPasswordTitolo()}
                </span>
            </div>
            <div className="flex flex-wrap justify-between gap-2">
                <span className="text-xs">{_t.auth.completaPasswordLegenda()}</span>
            </div>
            <Input.Full
                className="w-full"
                type="password"
                label={_t.auth.password()}
                name="password"
            />
            <Input.Full
                className="w-full"
                type="password"
                label={_t.auth.confermaPassword()}
                name="confirmpassword"
            />
        </React.Fragment>
    );
}

function VerifyEmailFields() {
    return (
        <React.Fragment>
            <div className="flex flex-wrap justify-between gap-4">
                <span className="text-center text-lg text-primary-700">
                    {_t.auth.verifyEmail()}
                </span>
            </div>
            <div className="flex flex-wrap justify-between gap-2">
                <span className="text-xs">{_t.auth.verifyEmailInfo()}</span>
            </div>
            <Input.Full
                className="w-full"
                type="text"
                label={_t.auth.verifyEmailCode()}
                name="code"
            />
        </React.Fragment>
    );
}

export default function Authenticator(props) {
    const {
        className,
        state,
        onChange: providedOnChange,
        onSubmit: providedOnSubmit,
        disabled,
        ...formProps
    } = props;

    const auth = useAuth();
    const currentAction = auth[state];
    const currentError = auth[state].error;

    const [didUpdateForm, setDidUpdateForm] = React.useState(false);
    const displayError = !didUpdateForm;

    function onChange(event) {
        if (isFunction(providedOnChange)) return providedOnChange(event);
        setDidUpdateForm(true);
    }

    function onSubmit(event) {
        event.preventDefault();
        setDidUpdateForm(false);
        let data;

        if (isFunction(providedOnSubmit)) {
            data = providedOnSubmit(event);
            if (isFalse(data)) return;
        } else data = formToObject(event.target);
        currentAction.execute(data);
    }

    return (
        <form
            className={mergeClasses(
                'space-y-6 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10',
                className
            )}
            onSubmit={onSubmit}
            onChange={onChange}
            {...formProps}
        >
            {state === STATES.SIGN_IN && <SignInFields />}
            {state === STATES.SIGN_UP && <SignUpFields />}
            {state === STATES.COMPLETE_NEW_PASSWORD && <CompletePasswordFields />}
            {state === STATES.FORGOT_PASSWORD && <ForgotPasswordFields />}
            {state === STATES.CREATE_NEW_PASSWORD && <CreateNewPasswordFields />}
            {state === STATES.VERIFY_EMAIL && <VerifyEmailFields />}
            {displayError && currentError && <Aside type="problem">{currentError}</Aside>}
            <Button
                look="primary"
                className={addClasses('w-full font-normal')}
                type="submit"
                busy={currentAction.isLoading}
                disabled={disabled}
            >
                {state === STATES.SIGN_IN && _t.auth.signin()}
                {state === STATES.SIGN_UP && _t.auth.signup()}
                {state === STATES.COMPLETE_NEW_PASSWORD && _t.bottone.conferma()}
                {state === STATES.FORGOT_PASSWORD && _t.bottone.conferma()}
                {state === STATES.CREATE_NEW_PASSWORD && _t.bottone.conferma()}
                {state === STATES.VERIFY_EMAIL && _t.bottone.conferma()}
            </Button>
        </form>
    );
}
