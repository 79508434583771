import {useContext} from "#routes/carica/inventario/index.jsx";
import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import {useInventario} from "#hooks/use-inventario.js";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import TabletProdottoInventario from "#root/src/lib/@tablet-template/tablet-prodotto-inventario.jsx";
import {List} from "#components/actions.jsx";

export default function SelezionaLotti() {
    const {inventario, setInventario} = useContext();
    let lotti = useInventario(inventario.prodotto.id);
    const navigate = useNavigate();
    
    useEffect(() => {
        if(lotti.data)
            setInventario((p) => {
                return {
                    ...p,
                    listaLotti: lotti.data?.map(l => {return {
                        id: l.id,
                        lotto: l.lotto,
                        qta: l.quantitaAdOggi,
                        qtaAdOggi: l.quantitaAdOggi,
                        attivo: true
                    }})
                }
            });

    }, [lotti.data, setInventario]);

    function aggiornaQuantita(event, id) {
        const value = event.target.value;
        let newLotti = inventario.listaLotti;
        const i = newLotti.findIndex(l => l.id === id);
        newLotti[i].qta = value;

        setInventario((p) => {
            return {
                ...p,
                listaLotti: newLotti.map(l => {return {
                    id: l.id,
                    lotto: l.lotto,
                    qta: l.qta,
                    qtaAdOggi: l.qtaAdOggi,
                    attivo: l.attivo
                }})
            }
        });
    }

    function onSubmit(event) {
        event.preventDefault();
        if (!inventario.listaLotti.some(l => l.attivo === true)) return;

        const newLotti = inventario.listaLotti.filter(l => l.attivo === true).map(l => {
            return {
                id: l.id,
                lotto: l.lotto,
                qta: l.qta,
                qtaAdOggi: l.qtaAdOggi,
            }
        });

        setInventario(p => {
            return {
                ...p,
                listaLotti: newLotti
            };
        });

        navigate('../conferma-webgate');
    }

    function toggleLottoAttivo(id) {
        const index = inventario.listaLotti.findIndex(l => l.id === id);
        const arr = inventario.listaLotti;
        arr[index].attivo = !arr[index].attivo;
        setInventario((p) => {
            return {
                ...p,
                listaLotti: arr
            }
        });
    }

    return (
        <TabletFrame
            titolo={_t.crud.carica.inventarioDaWebgate() + " / " + _t.lotto.lotto()}
            descrizione={_t.interfaccia.messaggiCarica.aggiungiProdottiWebgate()}
            formId="form-lotto"
        >
            <h1 className="font-bold ml-4 mb-2">{inventario.prodotto.nome}{inventario.prodotto.id.includes('SL')? ' - SL' : ''}</h1>
            <form id='form-lotto' onSubmit={onSubmit}>
                <List>
                    {lotti.data?.length > 0 && lotti.isSuccess && inventario.listaLotti?.map((lotto) => (
                        <List.Item key={lotto.id + lotto.lotto}>
                            <TabletProdottoInventario
                                lotto={lotto}
                                onChange={() => aggiornaQuantita(event, lotto.id)}
                                toggleActiveElement={() =>toggleLottoAttivo(lotto.id)}
                                isWebgate={true}
                            />
                        </List.Item>
                    ))}
                    {lotti.data?.length <= 0 &&
                        <h1 className="m-2">{_t.errore.nessunRisultato()}</h1>
                    }
                </List>
            </form>
        </TabletFrame>
    );
}