import {useNavigate} from 'react-router-dom';
import {useContext} from './index.jsx';
import {Action} from '#components/actions.jsx';
import React, {useEffect} from 'react';
import {useGetRisorse} from '#hooks/use-lavorazione.js';
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";

const presetQuantities = [1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 20];

export default function Quantita() {
    const {lavorazione, setLavorazione} = useContext();
    const {fase, prodotto} = lavorazione;

    const navigate = useNavigate();

    const risorse = useGetRisorse(fase?.idFase, prodotto?.id, prodotto?.IDLotto, prodotto?.Tag);
    const [quantita, setQuantita] = React.useState(1);

    function isError(risorsa) {
        return (quantita * risorsa.QtaProdottoUsato > (risorsa.DispoMax || lavorazione.prodotto.DispoMax));
    }
    
    useEffect(() => {
        //calcola la quantità iniziale
        if (risorse.data?.length) {
            const dispoMaxRisorsaBase = lavorazione?.prodotto?.DispoMax || 1;
            const risorsaBase = risorse.data.filter(r => r.DispoMax === 0)[0];
            const risorsaSecondaria = risorse.data.filter(r => r.DispoMax !== 0)[0];

            const quantitaRisorsaBaseMax = Math.floor(dispoMaxRisorsaBase / risorsaBase.QtaProdottoUsato);
            const quantitaRisorsaSecondariaMax = Math.floor(risorsaSecondaria.DispoMax / risorsaSecondaria.QtaProdottoUsato);
            setQuantita(Math.min(quantitaRisorsaBaseMax, quantitaRisorsaSecondariaMax));
        }
    }, [lavorazione?.prodotto?.DispoMax, risorse.data]);

    function onSubmit(event) {
        event.preventDefault();
        if (risorse.data.filter((r) => isError(r)).length) return;
        const form = event.target;
        const formData = new FormData(form);

        setLavorazione((p) => ({
            ...p,
            quantita: formData.get('quantita')
        }));

        if(!lavorazione.prodotto.IDLotto)
            navigate('../lotto');
        else
            navigate('../conferma');
    }

    function increase() {
        setQuantita((p) => p + 1);
    }

    function decrease() {
        setQuantita((p) => {
            if (p <= 1) return 1;
            return p - 1;
        });
    }

    return (
        <TabletFrame titolo={_t.crud.lavorazione.lavorazione() + " / " + _t.quantita.quantita()} formId='quantity-form' descrizione={_t.interfaccia.messaggiLavorazione.quantita()}>
            <TabletRiepilogoRow label={_t.crud.lavorazione.risultante()} text={prodotto?.prodotto} />
            <TabletRiepilogoRow label={_t.crud.lavorazione.richiesti()} text={risorse.isSuccess && risorse.data.map((r) => (
                <div className="mb-1"
                     key={r.IDProdotto}>
                    <span className={isError(r) ? 'text-red-600' : 'text-green-600'}>{quantita * r.QtaProdottoUsato} </span>
                    / <span className={isError(r) ? 'text-red-600' : 'text-blue-600'}> {(r.DispoMax || lavorazione.prodotto.DispoMax)}</span> {r.IDUM} {r.Prodotto}
                </div>
            ))} />
            <TabletRiepilogoRow label={_t.quantita.numeroForme()} text={quantita} />
            <div className='grid grid-cols-4 gap-4 mb-6 p-5'>
                <Action as='button' onClick={decrease}>
                    <Action.Label>-1</Action.Label>
                </Action>
                <form className='col-span-2' id='quantity-form' onSubmit={onSubmit}>
                    <input
                        min={0}
                        step={1}
                        type='number'
                        className='w-full h-full text-3xl font-bold border-b-4 border-neutral-700 text-center'
                        name='quantita'
                        value={quantita}
                        onChange={(event) => setQuantita(event.target.value)}
                    />
                </form>
                <Action as='button' onClick={increase}>
                    <Action.Label>+1</Action.Label>
                </Action>
            </div>
            <div className='grid grid-cols-4 gap-4 mb-8 p-5'>
                {presetQuantities.map((quantity) => (
                    <Action
                        key={quantity}
                        as='button'
                        onClick={() => setQuantita(quantity)}
                    >
                        <Action.Label>{quantity}</Action.Label>
                    </Action>
                ))}
            </div>
        </TabletFrame>
    );
}
