import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import {OPERATIONS, CrudRoute as _CrudRoute} from '../lib/@crud';
import {Private} from '../routes/index';

export const routes = {
    search: 'search',
    create: 'add/:ownerId?',
    update: 'edit/:id'
};

export function CrudRoute(props) {
    return <_CrudRoute {...props} routes={routes} />;
}

export function makeCrudRoutes(
    name,
    SearchViewController,
    DetailViewController,
    ...other
) {
    return (
        <Route path={name}>
            <Route index element={<Navigate to={routes.search} replace />} />
            <Route
                path={routes.search}
                element={Private(
                    <CrudRoute operation={OPERATIONS.SEARCH} name={name}>
                        <SearchViewController />
                    </CrudRoute>
                )}
            />
            {DetailViewController && (
                <React.Fragment>
                    <Route
                        path={routes.create}
                        element={Private(
                            <CrudRoute
                                key={`create-${name}`}
                                operation={OPERATIONS.CREATE}
                                name={name}
                            >
                                <DetailViewController />
                            </CrudRoute>
                        )}
                    />
                    <Route
                        path={routes.update}
                        element={Private(
                            <CrudRoute
                                key={`update-${name}`}
                                operation={OPERATIONS.UPDATE}
                                name={name}
                            >
                                <DetailViewController />
                            </CrudRoute>
                        )}
                    />
                </React.Fragment>
            )}
            {Array.isArray(other) &&
                other.map(({ViewController, ...route}) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={
                            <CrudRoute
                                name={route.name ?? name}
                                operation={route.operation}
                            >
                                <ViewController />
                            </CrudRoute>
                        }
                    />
                ))}
        </Route>
    );
}
