import {fetchAndParse, jsonHeaders} from "#lib/helpers/fetch.js";
import {useAuthQuery} from "#lib/hooks/use-query.js";
import useMutation from "#lib/hooks/use-mutation.js";
import {getAuthHeader} from "#lib/hooks/use-auth.jsx";

export function useLavorazione() {
    const {data, ...queryProps} = useAuthQuery({
        queryKey: ['lavorazione'],
        staleTime: 'Infinity',
        refetchOnWindowFocus: false,
        retry: false,
        retryOnMount: false,
        queryFn: (_, headers) =>
            fetchAndParse(`${API_URL}/p/fase`, {
                headers
            })
    });

    return {data: data?.results, ...queryProps};
}

export function useProdottoCreato(idFase) {
    const {data, ...queryProps} = useAuthQuery({
        queryKey: ['prodotto-creato'],
        staleTime: 'Infinity',
        refetchOnWindowFocus: false,
        retry: false,
        retryOnMount: false,
        queryFn: (_, headers) =>
            fetchAndParse(`${API_URL}/p/distinta-base/prodotto-creato?idFase=${idFase}`, {
                headers
            })
    });

    return {data: data?.results, ...queryProps};
}

export function useGetRisorseOpzioni(idFase, idProdotto) {
    const {data, ...queryProps} = useAuthQuery({
        queryKey: ['get-risorse-opzioni'],
        staleTime: 'Infinity',
        refetchOnWindowFocus: false,
        retry: false,
        retryOnMount: false,
        queryFn: (_, headers) =>
            fetchAndParse(`${API_URL}/p/distinta-base/risorse-opzioni?idFase=${idFase}&idProdotto=${idProdotto}`, {
                headers
            })
    });

    return {data: data?.results, ...queryProps};
}

export function useGetRisorse(idFase, idProdotto, idLotto, tag) {
    const {data, ...queryProps} = useAuthQuery({
        queryKey: ['get-risorse'],
        staleTime: 'Infinity',
        refetchOnWindowFocus: false,
        retry: false,
        retryOnMount: false,
        queryFn: (_, headers) =>
            fetchAndParse(`${API_URL}/p/distinta-base/risorse?idFase=${idFase}&idProdotto=${idProdotto}&idLotto=${idLotto}&tag=${tag}`, {
                headers
            })
    });

    return {data: data?.results, ...queryProps};
}

export function useEffettuaLavorazione() {
    return useMutation({
        mutationFn: async (body) => {
            const headers = await getAuthHeader();
            const response = await fetch(`${API_URL}/p/movimenti/lavorazione`, {
                method: 'POST',
                headers: {
                    ...jsonHeaders,
                    ...headers
                },
                body: JSON.stringify(body),

            });
            return await response.json();
        }
    });

}
