import {cn} from "#lib/helpers/class.js";
import {XMarkIcon} from "@heroicons/react/24/solid/index.js";
import React from "react";
import {PlusIcon} from "@heroicons/react/24/solid";
import {TrashIcon} from "@heroicons/react/20/solid";

export default function TabletProdottoInventario(props) {
    const {lotto, onChange, toggleActiveElement, isWebgate} = props;

    return (
        <div className={cn('bg-neutral-100 p-5',
            (lotto?.attivo || !isWebgate) ? 'opacity-100' : 'opacity-50',
            isWebgate? '' : 'my-5')}>

            {isWebgate ?
                <React.Fragment>
                    <h1 className="text-xl font-bold">{lotto.lotto}</h1>
                    <input
                        type="number"
                        name={'qta' + lotto.id + lotto.lotto}
                        value={lotto.qta}
                        onChange={onChange}
                        required
                        disabled={!lotto.attivo}
                        min={0}
                        className='p-2 my-2 rounded'
                        placeholder="Quantità"
                    />
                    <span
                        className="bg-neutral-600 aspect-square p-2 self-center absolute top-5 right-5 mt-5"
                        onClick={toggleActiveElement}
                    >
                        {lotto.attivo?
                            <XMarkIcon className="text-white icon" />
                            : <PlusIcon className="text-white icon" />
                        }
                    </span>
                </React.Fragment> :
                <React.Fragment>
                    <input
                        className="p-2 m-2 rounded"
                        placeholder="Lotto"
                        type="text"
                        name={lotto.id + 'lotto'}
                        onChange={() => onChange(event, "lotto", lotto.id)}
                        required={true}
                    />
                    <input
                        className="p-2 m-2 rounded"
                        placeholder="Quantità"
                        type="number"
                        name={lotto.id + 'qta'}
                        min={1}
                        onChange={() => onChange(event, "qta", lotto.id)}
                        required={true}
                    />
                    <span
                        className="bg-neutral-600 aspect-square p-2 self-center absolute right-2 bottom-2 m-5"
                        onClick={toggleActiveElement}
                    >
                            <TrashIcon
                                className="text-white icon"
                            />
                        </span>
                </React.Fragment>
            }
        </div>
    )
}