export function isUndefined(variable, strict = true) {
    if (strict) return variable === undefined;
    return variable == undefined;
}

export function isDefined(variable, strict = true) {
    if (strict) return variable !== undefined;
    return variable != undefined;
}

export function getFirstDefined(array, strict) {
    return array.find((el) => isDefined(el, strict));
}

export function undefineIfFalsy(variable) {
    if (!variable) return undefined;
    return variable;
}

export function undefineIf(variable, condition) {
    if (condition) return undefined;
    return variable;
}
