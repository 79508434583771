import {
    useMutation as useReactQueryMutation,
    useQueryClient
} from '@tanstack/react-query';
import {isFunction} from 'lib/utils/function.js';
import {getAuthHeader} from 'lib/hooks/use-auth.jsx';
import {ENVIRONMENTS} from '#lib/constants.js';

export default function useMutation(params) {
    const {
        mutationKey,
        mutationFn,
        invalidate = [mutationKey],
        onMutate: providedOnMutate,
        onSuccess: providedOnSuccess,
        onError: providedOnError,
        onSettles: providedOnSettled,
        optimisticUpdate,
        successUpdate,
        ...options
    } = params;
    const queryClient = useQueryClient();

    return useReactQueryMutation({
        mutationFn,
        mutationKey,
        onMutate: async (arg) => {
            if (isFunction(providedOnMutate)) providedOnMutate(arg);
            if (!mutationKey) return;
            // Cancel possibly active queries
            await queryClient.cancelQueries(mutationKey);
            // Store the old value and return it so that it's accessible
            // in case of an error
            const previousValue = queryClient.getQueryData(mutationKey);
            // If the consumer provided an optimistic update function,
            // update the cache value on mutate
            if (optimisticUpdate) {
                if (
                    ENV === ENVIRONMENTS.DEVELOPMENT &&
                    typeof optimisticUpdate !== 'function'
                ) {
                    console.error(`Type of 'optimisticUpdate' is not a function`);
                }
                queryClient.setQueryData(mutationKey, (previous) =>
                    optimisticUpdate({previous, arg})
                );
            }
            return previousValue;
        },
        // On failure, roll back to the previous value
        onError: (err, arg, previousValue) => {
            if (isFunction(providedOnError)) providedOnError(err, arg, previousValue);
            if (!mutationKey) return;
            if (ENV === ENVIRONMENTS.DEVELOPMENT)
                console.error(`Error while mutating "${mutationKey}": ${err.message}`);
            queryClient.setQueryData(mutationKey, previousValue);
        },
        // On success, use the returned data (if any)
        onSuccess: async (data, arg, context) => {
            if (isFunction(providedOnSuccess)) providedOnSuccess(data, arg, context);
            if (!mutationKey) return;
            if (ENV === ENVIRONMENTS.DEVELOPMENT)
                console.info(`Successful mutation for "${mutationKey}"`);
            if (successUpdate) {
                if (
                    ENV === ENVIRONMENTS.DEVELOPMENT &&
                    typeof successUpdate !== 'function'
                ) {
                    console.error(`Type of 'successUpdate' is not a function`);
                }
                const previous = queryClient.getQueryData(mutationKey);
                let parsedData = await data;
                if (data instanceof Response) parsedData = data.json();

                queryClient.setQueryData(
                    mutationKey,
                    successUpdate({previous, data: parsedData, arg})
                );
            }
        },
        // After success or failure, refetch the query
        // for the specified mutation keys
        onSettled: (data, error, arg, context) => {
            if (isFunction(providedOnSettled))
                providedOnSuccess(data, error, arg, context);
            if (!mutationKey) return;
            if (Array.isArray(invalidate) && invalidate.length) {
                invalidate.forEach((mutationKey) => {
                    if (Array.isArray(mutationKey))
                        queryClient.invalidateQueries(mutationKey);
                });
            }
        },
        ...options
    });
}

export function useAuthMutation({mutationFn, disableAuth = false, ...rest}) {
    async function authorizedMutationFn(args) {
        try {
            const authHeader = await getAuthHeader();
            return mutationFn.call(this, args, authHeader);
        } catch (error) {
            console.error(error);
        }
    }
    return useMutation({
        mutationFn: disableAuth ? mutationFn : authorizedMutationFn,
        ...rest
    });
}
