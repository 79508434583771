import React from "react";
import {useOrdini} from "#hooks/use-ordini.js";
import {useNavigate} from "react-router-dom";
import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/24/solid/index.js";
import {useContext} from "#routes/ordini/index.jsx";
import {useForm} from "#lib/hooks/use-form.js";
import TabletSelect from "#root/src/lib/@tablet-template/tablet-select.jsx";

function getOrdiniSelezionati(data) {
    let ordini = Object.keys(data);
    let ordiniSelezionati = [];
    for (const o in ordini){
        if(data[ordini[o]] === true){
            ordiniSelezionati.push(ordini[o]);
        }
    }
    return ordiniSelezionati;
}

export default function SelezionaOrdine(props) {
    const {setOrdini} = useContext();
    const {chiudi} = props;
    const [date, setDate] = React.useState();
    const ordini = useOrdini(date, chiudi, 'BACK');

    const {data, registerCheckbox} = useForm();

    const dataOrdine = ordini?.dataOrdine;
    if(!date && dataOrdine) {
        setDate(dataOrdine);
    }
    const navigate = useNavigate();

    function selectOrdine(ordine) {
        if (!chiudi) {
            setOrdini((p) => ({
                ...p,
                ordine: ordine
            }));
            navigate('../seleziona-bins');
        }
    }

    function onSubmit(event) {
        event.preventDefault();
        const selectedElements = getOrdiniSelezionati(data);

        if (selectedElements.length === 0)
            return;

        setOrdini((p) => ({
            ...p,
            chiudiOrdine: true,
            ordini: ordini,
            selectedElements: selectedElements
        }));
        navigate('../conferma');
    }

    async function scrollDay(add) {
        const current = new Date(date);
        const day = 24 * 60 * 60 * 1000;
        const newDate = add?
            new Date(current.getTime() + day).toISOString().slice(0, 10) :
            new Date(current.getTime() - day).toISOString().slice(0, 10);

        setDate(newDate);
    }

    async function changeDate(event) {
        const newDate = event.target.value;
        setDate(newDate);
    }

    return (
        <TabletFrame titolo={_t.crud.ordine.ordini() + " / " + (chiudi? _t.crud.ordine.chiudiOrdini() : _t.crud.ordine.confeziona())} formId={"ordini-form"}
                     descrizione={chiudi ? _t.interfaccia.messaggiOrdini.ordineChiudi() : _t.interfaccia.messaggiOrdini.ordine()}>
            <div className="flex">
                <div onClick={() => scrollDay(false)} className=""><ArrowLeftIcon className="icon ml-4"/></div>
                <input
                    type="date"
                    name="date"
                    value={date}
                    onChange={changeDate}
                    className="text-md mx-4 mb-6"
                />
                <div onClick={() => scrollDay(true)}><ArrowRightIcon className="icon"/></div>
            </div>

            {ordini.isSuccess && (
                <form id="ordini-form" onSubmit={onSubmit}>
                    <ol className='container-grid'>
                        {ordini.data.map(ordine => (
                            <TabletSelect
                                key={ordine.id}
                                chiave={ordine.id}
                                onClick={()=> selectOrdine(ordine)}
                                headerTitle={ordine?.prodotto || _t.prodotto.nonTrovato()}
                                headerText={<span><strong>{_t.crud.ordine.destinatario()}:</strong> {ordine.clienteRagioneSociale}</span>}
                                isActive={data[ordine.id]}
                                isSingleSelect={!chiudi}
                                emptySlots={1}
                                {...registerCheckbox(ordine.id)}
                            >
                                <tr className="flex flex-col">
                                    <th className="font-normal text-neutral-800 text-sm text-right">
                                        {_t.crud.ordine.nOrdine()}
                                    </th>
                                    <td className="text-sm text-right">{ordine.numeroOrdine}</td>
                                </tr>
                                <tr className="flex flex-col">
                                    <th className="font-normal text-neutral-800 text-sm text-right">
                                        {_t.crud.ordine.pCollo()}
                                    </th>
                                    <td className="text-sm text-right">{ordine.pezziPerCollo}</td>
                                </tr>
                                <tr className="flex flex-col">
                                    <th className="font-normal text-neutral-800 text-sm text-right">
                                        {_t.crud.ordine.pezzi()}
                                    </th>
                                    <td className="text-sm text-right">{ordine.numeroPezziCaricati || 0}/{ordine.numeroPezzi}</td>
                                </tr>
                                <tr className="flex flex-col">
                                    <th className="font-normal text-neutral-800 text-sm text-right">
                                        {_t.crud.ordine.colli()}
                                    </th>
                                    <td className="text-sm text-right">{Math.floor(ordine.numeroPezziCaricati / ordine.pezziPerCollo)}/{ordine.numeroColli}</td>
                                </tr>
                            </TabletSelect>
                        ))}
                    </ol>
                </form>
            )}
            {ordini.data?.length === 0 &&
                <h1 className="m-2">{_t.errore.nessunRisultato()}</h1>
            }
            {!ordini.isSuccess &&
                <h1 className="m-2">{_t.errore.impossibileCaricareRisultati()}</h1>
            }
        </TabletFrame>
    );
}
