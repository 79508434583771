import React from 'react';

export default function DesktopFrame(props) {
    const {children} = props;

    return (
        <div className="m-auto max-w-[1330px] mb-20 mt-20">
            {children}
        </div>
    );
}