import {Action, List} from '#components/actions.jsx';
import React from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useLavorazione} from "#hooks/use-lavorazione.js";
import Prodotto from '#routes/lavorazione/prodotto.jsx';
import Quantita from '#routes/lavorazione/quantita.jsx';
import Lotto from "#routes/lavorazione/lotto.jsx";
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import Conferma from "#routes/lavorazione/conferma.jsx";
import TabletOk from "#root/src/lib/@tablet-template/tablet-ok.jsx";
import TabletErrore from "#root/src/lib/@tablet-template/tablet-errore.jsx";
import DistintaBase from "#routes/lavorazione/distinta-base.jsx";

const Context = React.createContext();
export const useContext = () => React.useContext(Context);

function Start() {
    const navigate = useNavigate();
    const {setLavorazione} = useContext();
    const fasi = useLavorazione();

    function selectFase(fase) {
        setLavorazione((p) => ({
            ...p,
            fase
        }));
        navigate('prodotto');
    }

    return (
        <TabletFrame titolo={_t.crud.lavorazione.lavorazione() + " / " + _t.operazioni.scegli()} descrizione={_t.interfaccia.messaggiLavorazione.lavorazione()}>
            <List>
                {fasi.isSuccess && fasi.data?.length > 0 &&
                    fasi.data.map((fase) => (
                        <List.Item key={fase.idFase}>
                            <Action
                                as="button"
                                onClick={() => selectFase(fase)}
                            >
                                <Action.Label>{fase.fase}</Action.Label>
                            </Action>
                        </List.Item>
                    ))}
                {fasi.data?.length <= 0 &&
                    <h1 className="m-2">{_t.crud.lavorazione.nessunRisultato()}</h1>
                }
            </List>
        </TabletFrame>
    );
}

export default function Lavorazione() {
    const [lavorazione, setLavorazione] = React.useState({});
    const {pathname} = useLocation();
    const navigate = useNavigate();

    if(pathname !== '/lavorazione' && !lavorazione?.fase)
        navigate('/lavorazione');

    return (
        <Context.Provider value={{lavorazione, setLavorazione}}>
            <Routes>
                <Route index element={<Start />} />
                <Route path="prodotto" element={<Prodotto />} />
                <Route path="selezione-lotto" element={<DistintaBase />} />
                <Route path="quantita" element={<Quantita />} />
                <Route path="lotto" element={<Lotto />} />
                <Route path="conferma" element={<Conferma />} />
                <Route path="fine" element={<TabletOk message={_t.tablet.success()}/>} />
                <Route path="errore" element={<TabletErrore message={_t.tablet.error()} />} />
            </Routes>
        </Context.Provider>
    );
}
