import React from 'react';
import {Link, Routes, Route} from 'react-router-dom';
import {Action, List} from '#components/actions.jsx';
import Prs from './prs.jsx';
import TargetWarehouse from './deposito.jsx';
import Note from './note.jsx';
import Conferma from './conferma.jsx';
import Forme from './forme/index.jsx';
import Quantita from "#routes/carica/forme/quantita.jsx";
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import TabletOk from '#root/src/lib/@tablet-template/tablet-ok.jsx';
import TabletErrore from '#root/src/lib/@tablet-template/tablet-errore.jsx';
import CaricaBins from "#routes/carica/bins/index.jsx";
import Inventario from "#routes/carica/inventario/index.jsx";

function Start() {
    return (
        <TabletFrame titolo={_t.interfaccia.carica()} descrizione={_t.interfaccia.messaggiCarica.azione()}>
            <List>
                <List.Item>
                    <Action as={Link} to="forme">
                        <Action.Label>{_t.crud.carica.forme()}</Action.Label>
                    </Action>
                </List.Item>
                <List.Item>
                    <Action as={Link} to="bins">
                        <Action.Label>{_t.crud.carica.bins()}</Action.Label>
                    </Action>
                </List.Item>
                <List.Item>
                    <Action as={Link} to="prs">
                        <Action.Label>{_t.crud.carica.prs()}</Action.Label>
                    </Action>
                </List.Item>
                <List.Item>
                    <Action as={Link} to="correzione">
                        <Action.Label>{_t.crud.carica.correzioneSpedizione()}</Action.Label>
                    </Action>
                </List.Item>
                <List.Item>
                    <Action as={Link} to="inventario">
                        <Action.Label>{_t.crud.carica.inventarioManuale()}</Action.Label>
                    </Action>
                </List.Item>
                <List.Item>
                    <Action as={Link} to="inventario-webgate">
                        <Action.Label>{_t.crud.carica.inventarioDaWebgate()}</Action.Label>
                    </Action>
                </List.Item>
            </List>
        </TabletFrame>
    );
}

const Context = React.createContext();
export const useContext = () => React.useContext(Context);

export default function Carica() {
    const [state, setState] = React.useState(() => ({
        prsList: [],
        products: [],
        manual: null,
        targetWarehouse: null,
        notes: null
    }));

    return (
        <Context.Provider value={{state, setState}}>
            <Routes>
                <Route index element={<Start />} />
                <Route path="prs" element={<Prs nascondi={false}/>} />
                <Route path="correzione" element={<Prs nascondi={true}/>} />
                <Route path="correzione-quantita" element={<Quantita/>} />
                <Route path="forme/*" element={<Forme />} />
                <Route path="bins/*" element={<CaricaBins />} />
                <Route path="inventario/*" element={<Inventario isWebgate={false}/>}/>
                <Route path="inventario-webgate/*" element={<Inventario isWebgate={true}/>}/>
                <Route path="destinazione" element={<TargetWarehouse/>} />
                <Route path="note" element={<Note />} />
                <Route path="conferma" element={<Conferma />} />
                <Route path="fine" element={<TabletOk message={_t.tablet.success()}/>} />
                <Route path="errore" element={<TabletErrore message={_t.tablet.error()} />} />
            </Routes>
        </Context.Provider>
    );
}
