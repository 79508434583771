import React from 'react';
import {ChevronUpIcon} from '@heroicons/react/24/solid';
import {isFunction} from '#lib/utils/function.js';
import {addClasses, cn, mergeClasses} from '#lib/helpers/class.js';
import {ifFunc} from '#lib/helpers/react.jsx';
import Button from '#lib/components/button.jsx';
import {SORT_DIRECTIONS} from '#lib/constants.js';
import {buttonLooks, sizes} from '#lib/styles/constants.js';

const TableContext = React.createContext();
TableContext.displayName = 'TableContext';

const useTable = () => React.useContext(TableContext);

const SPACING = {
    NORMAL: 'normal',
    COMPACT: 'compact',
    TIGHT: 'tight'
};

function Cell(props) {
    const {colSpan, className, style: providedStyle, children, _lastRow, ...rest} = props;

    const {withinCssGrid, spacing} = useTable();

    const baseClassName = cn(
        colSpan && withinCssGrid && 'table-cell-span',
        withinCssGrid && 'inline-flex flex-wrap items-center',
        _lastRow
            ? 'md:first:rounded-bl-lg md:last:rounded-br-lg'
            : 'border-b border-neutral-200',
        spacing === SPACING.TIGHT && 'p-y[1px] px-2 min-h-[37px]',
        spacing === SPACING.COMPACT && 'p-2',
        spacing === SPACING.NORMAL && 'px-3 py-4',
        'bg-white text-sm text-neutral-800 first-of-type:pl-4 last-of-type:pr-4'
    );

    return (
        <td
            style={{'--col-span': colSpan, ...providedStyle}}
            className={mergeClasses(baseClassName, className)}
            colSpan={colSpan}
            {...rest}
        >
            {children}
        </td>
    );
}

function Header(props) {
    const {className, children, ...rest} = props;
    const {sticky, spacing} = useTable();

    return (
        <th
            scope="col"
            className={mergeClasses(
                cn(
                    sticky &&
                        'sticky top-0 z-10 bg-opacity-50 backdrop-blur backdrop-filter',
                    spacing === SPACING.TIGHT && 'p-2',
                    spacing === SPACING.COMPACT && 'p-2',
                    spacing === SPACING.NORMAL && 'px-3 py-4',
                    'overflow-hidden text-ellipsis whitespace-nowrap border-b border-neutral-300 bg-neutral-50 text-left text-sm font-semibold text-neutral-800 first:rounded-tl-lg last:rounded-tr-lg first-of-type:pl-4 last-of-type:pr-4'
                ),
                className
            )}
            {...rest}
        >
            {children}
        </th>
    );
}

function SortableHeader(props) {
    const {
        name,
        sortDirection,
        isSorting,
        onSort: providedOnSort,
        children,
        ...rest
    } = props;

    function onSort() {
        if (isFunction(providedOnSort)) providedOnSort(name);
    }

    return (
        <Header {...rest}>
            <button onClick={onSort} className="flex cursor-pointer items-center gap-2">
                {children}
                <ChevronUpIcon
                    className={cn(
                        isSorting ? 'opacity-1' : 'opacity-0',
                        sortDirection === SORT_DIRECTIONS.DESC && 'rotate-180',
                        '-my-1 h-5 w-5 flex-shrink-0 transition-all'
                    )}
                />
            </button>
        </Header>
    );
}

function Row(props) {
    const {className, children, ...rest} = props;
    const {withinCssGrid} = useTable();
    return (
        <tr
            className={mergeClasses(cn(withinCssGrid && 'contents'), className)}
            {...rest}
        >
            {children}
        </tr>
    );
}

function Head(props) {
    const {className, children, ...rest} = props;
    const {withinCssGrid} = useTable();
    return (
        <thead
            className={mergeClasses(cn(withinCssGrid && 'contents'), className)}
            {...rest}
        >
            {children}
        </thead>
    );
}

function Body(props) {
    const {className, children, ...rest} = props;
    const {withinCssGrid} = useTable();
    return (
        <tbody
            className={mergeClasses(
                cn(withinCssGrid && 'contents', 'divide-y divide-neutral-200'),
                className
            )}
            {...rest}
        >
            {children}
        </tbody>
    );
}

function Footer(props) {
    const {className, children, ...rest} = props;
    const {withinCssGrid} = useTable();
    return (
        <tfoot
            className={mergeClasses(cn(withinCssGrid && 'contents'), className)}
            {...rest}
        >
            {children}
        </tfoot>
    );
}

function Container(props) {
    const {fullBleed = false, className, children, ...rest} = props;
    const {sticky} = useTable();
    return (
        <div
            className={mergeClasses(
                cn('flex flex-col overflow-auto md:overflow-visible md:px-6 lg:px-8'),
                className
            )}
        >
            <div className={cn('inline-block md:-mx-6 lg:-mx-8')}>
                <div
                    className={cn(
                        fullBleed
                            ? 'border-t border-neutral-200'
                            : 'shadow-md ring-1 ring-neutral-200 md:rounded-lg',
                        !sticky && 'overflow-hidden'
                    )}
                    {...rest}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

function _Table(props, ref) {
    const {templateColumns, numberOfColumns, className, children, ...rest} = props;

    const {withinCssGrid} = useTable();

    if (withinCssGrid && !numberOfColumns) {
        console.warn(`Table displays as a CSS grid, please provide 'numberOfColumns'`);
    }

    return (
        <table
            ref={ref}
            className={mergeClasses(
                cn(
                    'default-table-cols isolate min-w-full border-separate',
                    withinCssGrid && `grid`
                ),
                className
            )}
            style={{
                borderSpacing: '0px',
                gridTemplateColumns: templateColumns,
                '--number-of-columns': numberOfColumns
            }}
            {...rest}
        >
            {children}
        </table>
    );
}

const Table = React.forwardRef(_Table);

function Full(props) {
    const {
        className,
        children,
        columns,
        templateColumns,
        numberOfColumns: providedNumberOfColumns,
        data,
        getRowId = (row, index) => row.id || index,
        accessor: dataAccessor = (data) => data,
        paginated = false,
        pageAccessor = (data) => data?.pages,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        fullBleed,
        ...rest
    } = props;

    const hasChildren = Boolean(children);
    let numberOfColumns = providedNumberOfColumns ?? columns?.length;

    const renderHeaders = React.useCallback(
        (column) =>
            React.createElement(
                column.as ?? Header,
                {
                    key: column.accessor ?? column.id,
                    ...column.props
                },
                ifFunc(column.Header, {
                    value: column.accessor,
                    column
                }) ?? column.accessor
            ),
        []
    );

    const renderRow = React.useCallback(
        (row, index) => (
            <Row key={`row-${getRowId(row, index)}`}>
                {columns.map((column) => (
                    <Cell
                        key={column.accessor ?? column.id}
                        _lastRow={index + 1 === data?.length && !hasChildren}
                    >
                        {ifFunc(column.Cell, {
                            value: row[column.accessor],
                            column,
                            row,
                            index
                        }) ?? row[column.accessor]}
                    </Cell>
                ))}
            </Row>
        ),
        [columns, getRowId, hasChildren, data?.length]
    );

    if (!data) return null;

    return (
        <Container className={className} fullBleed={fullBleed}>
            <Table
                numberOfColumns={numberOfColumns}
                templateColumns={templateColumns}
                {...rest}
            >
                <Head>
                    <Row>{columns.map(renderHeaders)}</Row>
                </Head>
                <Body>
                    {paginated &&
                        pageAccessor(data)
                            .map(dataAccessor)
                            .map((rows) => rows.map(renderRow))}
                    {!paginated && dataAccessor(data).map(renderRow)}
                    {paginated && hasNextPage && (
                        <Row>
                            <Cell
                                colSpan={columns.length}
                                className={addClasses('flex justify-center')}
                            >
                                <Button
                                    className={addClasses('-my-1')}
                                    onClick={fetchNextPage}
                                    look={buttonLooks.text}
                                    disabled={!hasNextPage}
                                    busy={isFetchingNextPage}
                                    size={sizes.xs}
                                >
                                    Carica altri risultati
                                </Button>
                            </Cell>
                        </Row>
                    )}
                    {isFunction(children)
                        ? children({columns, numberOfColumns})
                        : children}
                </Body>
            </Table>
        </Container>
    );
}

function WrapperWithContext(Component, props) {
    const {
        display = 'grid',
        spacing = SPACING.COMPACT,
        stickyHeaders = false,
        ...rest
    } = props;

    const isGrid = display === 'grid';
    const isTable = display === 'table';
    if (!isGrid && !isTable)
        console.warn(
            `Table prop 'display' can either be 'grid' or 'table'. You passed ${display}.`
        );

    const contextValue = {withinCssGrid: isGrid, spacing, sticky: stickyHeaders};
    return (
        <TableContext.Provider value={contextValue}>
            <Component {...rest} />
        </TableContext.Provider>
    );
}

const WithContext = (Component) => WrapperWithContext.bind(null, Component);

export default {
    Footer,
    Container: WithContext(Container),
    Table,
    Head,
    Body,
    Row,
    Cell,
    Header,
    SortableHeader,
    Full: WithContext(Full)
};
