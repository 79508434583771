import React from 'react';
export default function NotFound() {
    return (
        <div className="min-h-full bg-white">
            <main className="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-xl py-16 sm:py-24">
                    <div className="text-center">
                        <p className="text-sm font-semibold uppercase tracking-wide text-primary-700">
                            404 error
                        </p>
                        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-neutral-900 sm:text-5xl">
                            This page does not exist.
                        </h1>
                        <p className="mt-2 text-lg text-neutral-500">
                            The page you are looking for could not be found.
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
}
