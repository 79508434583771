import React from 'react';
import {mergeClasses} from '#lib/helpers/class.js';

const InputContext = React.createContext();
InputContext.displayName = 'SwitchInputContext';
function useInputContext() {
    return React.useContext(InputContext);
}

function Full(props) {
    const {label, ...rest} = props;
    return (
        <Container>
            <Switch {...rest} />
            {label && <Label>{label}</Label>}
        </Container>
    );
}

function Container(props) {
    const {className, children, ...rest} = props;

    const id = React.useId();

    return (
        <InputContext.Provider value={{id}}>
            <div className={mergeClasses('flex items-center gap-2', className)} {...rest}>
                {children}
            </div>
        </InputContext.Provider>
    );
}

export default function Switch(props) {
    const {className, type = 'checkbox', id: providedId, ...rest} = props;
    const inputContext = useInputContext();

    const id = providedId ?? inputContext.id;
    const ref = React.useRef();

    return (
        <div className={mergeClasses('isolate grid items-center', className)}>
            <input
                ref={ref}
                type={type}
                id={id}
                className="area-span-full peer z-10 m-0 h-full w-full cursor-pointer p-0 opacity-0"
                {...rest}
            />
            <div className="area-span-full inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-neutral-200 transition-colors duration-200 ease-in-out peer-checked:bg-primary-700 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-primary-600 peer-focus:ring-offset-2 peer-disabled:opacity-75" />
            <span
                aria-hidden
                className="area-span-full pointer-events-none ml-0.5 inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out peer-checked:translate-x-5"
            />
        </div>
    );
}

function Label(props) {
    const {htmlFor: providedFor, children, ...rest} = props;

    const inputContext = useInputContext();
    const htmlFor = providedFor ?? inputContext.id;
    return (
        <label htmlFor={htmlFor} className="text-sm text-neutral-700" {...rest}>
            {children}
        </label>
    );
}

Switch.Container = Container;
Switch.Label = Label;
Switch.Full = Full;
