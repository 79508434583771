import {useRouteParams} from "#hooks/use-route-params.js";
import * as Crud from "#hooks/use-route-params.js";
import {useForm} from "#lib/hooks/use-form.js";
import useManageCrud from "#@wizard/use-manage.js";
import DesktopFrame from "#root/src/lib/@desktop-template/desktop-frame.jsx";
import ManageControls from "#components/manage-controls.jsx";
import BreadCrumbs from "#components/breadcrumbs.jsx";
import React from "react";
import Panel from "#components/panel.jsx";
import {DetailButtons} from "#components/control-buttons.jsx";
import {LoadingIndicator} from "#lib/components/loading.jsx";
import {addClasses} from "#lib/helpers/class.js";
import Grid from "#lib/components/grid.jsx";
import Select from "#lib/components/select.jsx";
import {useProdotti} from "#hooks/use-prodotti.js";
import {useDropdown} from "#hooks/use-dropdown.js";
import Input from "#lib/components/input.jsx";

export default function MovimentiEdit(routeProps) {
    const defaultArray = [];

    const dropdowns = useDropdown(['depositi', 'causali']);
    const {id} = useRouteParams(routeProps);
    const {isCreate} = Crud.useRouteParams();

    const crud = useManageCrud(
        'movimento',
        {id},
    );

    const {props, initialize, isSuccess, isLoading} = useForm({
        initialData: crud.data,
        errors: crud.error?.cause?.validationErrors,
        onSubmit: (data) => {
            data.id = id;
            crud.save.mutate(data);
        }
    });

    const prodotti= useProdotti();
    const prodottiOptions = prodotti.isSuccess? prodotti.data.map(p => {
        if (p.id.includes('SL')) {
            return {id: p.id, text: p.prodotto + ' - SL'}
        }
        return {id: p.id, text: p.prodotto}
    }) : defaultArray;

    const defaultDateTime = isCreate?
        new Date().toISOString().slice(0,10) + ' ' + new Date().toLocaleTimeString() :
        crud.data?.dataMovimento.slice(0,10) + ' ' + new Date(crud.data?.dataMovimento).toLocaleTimeString();

    return(
        <DesktopFrame>
            <ManageControls.BarSlot>
                <BreadCrumbs customName={_t.crud.movimenti.movimenti()} />
            </ManageControls.BarSlot>

            <Panel as="form" {...props.form}>
                {isLoading && <LoadingIndicator className={addClasses('mx-auto')} />}
                {isSuccess && (
                    <Grid>
                        <Grid.Slot>
                            <h2>{isCreate ? _t.crud.movimenti.nuovo() : _t.crud.movimenti.movimento()}</h2>
                        </Grid.Slot>

                        <Grid.Slot span={6}>
                            <Select.Full
                                {...initialize('idProdotto')}
                                className="w-full"
                                label={_t.prodotto.prodotto()}
                                options={prodottiOptions}
                                accessValue={(option) => option.id}
                                displayValue={(id) => prodottiOptions.find(c => c.id === id)?.text || ''}
                            />
                        </Grid.Slot>
                        <Grid.Slot span={3}>
                            <Select.Full
                                {...initialize('idDeposito')}
                                style={{flexGrow: 0}}
                                label={_t.deposito.deposito()}
                                options={[
                                    {id: '', text: 'Seleziona opzione'},
                                    ...dropdowns.data.depositi
                                ]}
                                accessValue={(option) => option.id}
                                displayValue={(selectedId, options) => (
                                    options.find((el) => el.id === selectedId)?.text
                                )}
                            />
                        </Grid.Slot>
                        <Grid.Slot span={3}>
                            <Input.Full
                                {...initialize('idLotto')}
                                placeholder={_t.lotto.lotto()}
                                name="idLotto"
                                type="search"
                                label={_t.lotto.lotto()}
                            />
                        </Grid.Slot>

                        <Grid.Slot span={4}>
                            <Input.Full
                                {...initialize('numeroProgressivo')}
                                type="text"
                                label={_t.crud.movimenti.numeroProgressivo()}
                                name={"numeroProgressivo"}
                            />
                        </Grid.Slot>
                        <Grid.Slot span={4}>
                            <Select.Full
                                {...initialize('idCausale')}
                                style={{flexGrow: 0}}
                                label={_t.crud.movimenti.causale()}
                                options={[
                                    {id: '', text: 'Seleziona opzione'},
                                    ...dropdowns.data.causali
                                ]}
                                accessValue={(option) => option.id}
                                displayValue={(selectedId, options) => (
                                    options.find((el) => el.id === selectedId)?.text
                                )}
                            />
                        </Grid.Slot>
                        <Grid.Slot span={2}>
                            <Input.Full
                                {...initialize('qta')}
                                type="number"
                                label={_t.crud.movimenti.qta()}
                                name={"qta"}
                            />
                        </Grid.Slot>
                        <Grid.Slot span={2}>
                            <Input.Full
                                defaultValue={defaultDateTime}
                                type="datetime-local"
                                label={_t.crud.movimenti.dataMovimento()}
                                name={"dataMovimento"}
                                step={1}
                            />
                        </Grid.Slot>

                        <Grid.Slot span={6}>
                            <Input.Full
                                {...initialize('note')}
                                type="text"
                                label={_t.crud.movimenti.note()}
                                name={"note"}
                            />
                        </Grid.Slot>
                        <Grid.Slot span={6}>
                            <Input.Full
                                {...initialize('tag')}
                                type="text"
                                label={_t.crud.movimenti.tag()}
                                name={"tag"}
                            />
                        </Grid.Slot>


                        {/*TODO: rimuovere quando ci sarà gestione degli archivi/aziende*/}
                        <Input.Full type='hidden' name='idArchivio' defaultValue={'1'}/>
                        <Input.Full type='hidden' name='idUtenteCreatore' defaultValue={'1'}/>
                    </Grid>
                )}

                <ManageControls.ButtonSlot>
                    <DetailButtons
                        save={{busy: crud.save.isLoading, ...props.submit}}
                        delete={{
                            onDelete: crud.delete.mutate,
                            busy: crud.delete.isLoading
                        }}
                    />
                </ManageControls.ButtonSlot>
            </Panel>
        </DesktopFrame>
    );
}