import React from 'react';
import {NavLink} from 'react-router-dom';
import {cn} from 'lib/helpers/class';
import {hasElements} from 'lib/utils/array';

export default function NavBar(props) {
    const {navigation} = props;
    const entries = Object.entries(navigation).filter(([, items]) => hasElements(items));

    return (
        <div className="flex items-baseline gap-4 overflow-hidden px-3 py-5 text-sm">
            {entries.map(([key, items], index) => (
                <React.Fragment key={key}>
                    {index !== 0 && (
                        <span
                            className="h-4 w-px translate-y-0.5 bg-neutral-200"
                            aria-hidden
                        />
                    )}
                    <ul className="contents">
                        {items.map(({to, end, Icon, name}) => (
                            <li className="contents" key={to}>
                                <NavLink
                                    className={({isActive}) =>
                                        cn(
                                            isActive
                                                ? 'bg-primary-700  !text-white'
                                                : 'bg-transparent text-neutral-600 hover:text-neutral-800',
                                            '-mx-2 -my-1.5 inline-flex whitespace-nowrap rounded-lg px-3 py-1.5 transition-colors'
                                        )
                                    }
                                    to={to}
                                    end={end}
                                    state={{from: location.pathname}}
                                >
                                    {Icon && <Icon className="icon-sm mr-2" />}
                                    {name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </React.Fragment>
            ))}
        </div>
    );
}
