import React from 'react';
import {Link} from 'react-router-dom';
import {cn, mergeClasses} from '#lib/helpers/class.js';
import {sizes, buttonLooks, sizeScale} from '#lib/styles/constants.js';
import {undefineIf} from '#lib/utils/undefined.js';
import {BouncingDots} from '#lib/components/loading.jsx';

export const ANIMATIONS = {
    DOTS: 'dots',
    TEXT: 'text',
    NONE: 'none'
};

const sizeMap = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl'
};

const lookMap = {
    primary: 'primary',
    secondary: 'secondary',
    white: 'white',
    text: 'text',
    circular: 'circular'
};

function Button(props, ref) {
    const {
        look = buttonLooks.white,
        size = sizes.md,
        as = 'button',
        destructive = false,
        children,
        disabled, // DOM property
        busy, // Custom property – disables the button and displays a loading message & animation
        simpleButton = false, // if set to `true`, the inner CSS grid is removed. It's up to the consumer to provide a loading state that doesn't cause reflow
        loadAnimation = ANIMATIONS.DOTS,
        className,
        ...rest
    } = props;

    const isCircular = look === buttonLooks.circular;
    const noAnimation = loadAnimation === ANIMATIONS.NONE || loadAnimation === false;

    const assignedClasses = mergeClasses(
        cn(
            'btn',
            sizeMap[size],
            lookMap[look],
            isCircular && 'circular',
            destructive && 'destructive',
            (disabled || busy) && 'disabled'
        ),
        className
    );

    let assignedType = as;
    if (props?.to) assignedType = Link;
    else if (props?.href) assignedType = 'a';

    const assignedProps = {
        ref,
        className: assignedClasses,
        disabled: busy || disabled,
        type: undefineIf('button', as !== 'button'),
        ...rest
    };

    if (!simpleButton && assignedType !== 'input') {
        return React.createElement(
            assignedType,
            assignedProps,
            <span className="relative grid items-center justify-items-center">
                <span
                    className={cn(
                        busy ? 'visible-inherit' : 'invisible',
                        isCircular && 'absolute',
                        'area-span-full'
                    )}
                >
                    {loadAnimation === ANIMATIONS.DOTS && (
                        <BouncingDots
                            className={(prev) => `${prev} em-w-8 em-h-4`}
                            numberOfDots={
                                isCircular && sizeScale[size] < sizeScale.md ? 1 : 3
                            }
                        />
                    )}
                    <span
                        className={cn(
                            (loadAnimation !== ANIMATIONS.TEXT || noAnimation) &&
                                'sr-only'
                        )}
                    >
                        Loading...
                    </span>
                </span>
                <span
                    className={cn(
                        busy && !noAnimation ? 'invisible' : 'visible-inherit',
                        'area-span-full inline-flex items-center justify-center'
                    )}
                >
                    {children}
                </span>
            </span>
        );
    }
    return React.createElement(assignedType, assignedProps, children);
}

export default React.forwardRef(Button);
