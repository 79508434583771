import React from 'react';
import {Link} from 'react-router-dom';
import {PlusIcon} from '@heroicons/react/24/solid';
import {cn, mergeClasses} from '#lib/helpers/class.js';

export default function EmptyState(props) {
    const {
        as = 'button',
        to,
        className,
        disabled,
        children,
        Icon = PlusIcon,
        ...rest
    } = props;

    let assignedType = as;
    if (to) {
        if (disabled) assignedType = 'span';
        else assignedType = Link;
    }

    let assignedProps = {
        className: mergeClasses(
            cn(
                disabled
                    ? 'border-neutral-200'
                    : 'border-neutral-300 hover:border-primary-400',
                'group relative block w-full rounded-lg border-2 border-dashed p-6 text-center focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
            ),
            className
        ),
        ...rest
    };

    if (assignedType === 'button') {
        assignedProps = Object.assign(assignedProps, {
            disabled,
            type: assignedProps.type ?? 'button'
        });
    } else if (assignedType === Link) {
        assignedProps = Object.assign(assignedProps, {to});
    }

    return React.createElement(
        assignedType,
        assignedProps,
        <React.Fragment>
            {Icon && (
                <Icon
                    className={cn(
                        'mx-auto mb-2 h-6 w-6 transition-colors',
                        disabled
                            ? 'text-neutral-500'
                            : 'text-neutral-600 group-hover:text-primary-700'
                    )}
                />
            )}
            <span
                className={cn(
                    'mt-2 block text-sm transition-colors',
                    disabled
                        ? 'font-normal text-neutral-600'
                        : 'font-medium text-neutral-700 group-hover:text-primary-700'
                )}
            >
                {children}
            </span>
        </React.Fragment>
    );
}
