export function capitalize(name) {
    if (!isString(name)) return name;
    return name.charAt(0).toUpperCase() + name.slice(1);
}

export function isString(variable) {
    return typeof variable === 'string';
}

export function removeWhitespace(string) {
    return string.replaceAll(' ', '');
}

export function isoDateTimeToString(isoDate, time = true) {
    if (!isoDate) return '-';
    try {
        const date = new Date(isoDate);
        const dateString = date.toLocaleDateString();
        if (time) dateString.concat(` - ${date.toLocaleTimeString()}`);
        return dateString;
    } catch (e) {
        return '—';
    }
}
