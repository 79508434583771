import {useContext} from './index.jsx';
import React from "react";
import {useNavigate} from 'react-router-dom';
import {useCreaMovimento, useStampaCartelloBins} from '#hooks/use-movimenti.js';
import {Causali} from '#root/src/constants.js';
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";
export default function Conferma() {
    const {caricaBins} = useContext();
    const {prodotto, quantita, idLotto, deposito, pezzi} = caricaBins;
    const navigate = useNavigate();

    const creaMovimentoMutation = useCreaMovimento();
    const stampaCartelloMutation = useStampaCartelloBins();

    async function OnCreaMovimento() {
        creaMovimentoMutation.mutate({
            idProdotto: prodotto.idProdotto,
            idDeposito: deposito.idDeposito,
            idCausale: Causali.caricoBins,
            idLotto: idLotto,
            qta: pezzi
        }, {
            onSuccess: async (res) => {
                if(res.ok){
                    await stampaCartelloMutation.mutate(res.idMovimento);
                    navigate('../fine');
                }else
                    navigate('../errore');
            },
            onError: () => navigate('../errore')
        });
    }

    return (
        <TabletFrame titolo={_t.crud.caricaBins.caricaBins() + " / " + _t.tablet.riepilogo()} onConfirm={OnCreaMovimento} descrizione={_t.interfaccia.messaggiCarica.conferma()}>
            <TabletRiepilogoRow label={_t.prodotto.prodotto()} text={prodotto.name} />
            <TabletRiepilogoRow label={_t.quantita.quantita()} text={quantita + ' Kg / ' + pezzi + ' ' + _t.spedizione.pezzi()} />
            <TabletRiepilogoRow label={_t.lotto.lotto()} text={idLotto} />
            <TabletRiepilogoRow label={_t.deposito.destinazione()} text={deposito.deposito} />
        </TabletFrame>
    );
}
