import {useMemo} from 'react';
import {useAuthQuery} from 'lib/hooks/use-query';
import {fetchAndParse} from 'lib/helpers/fetch';

function toNamesQuery(names) {
    if (!Array.isArray(names)) return;
    const res = names.reduce((p, c) => {
        if (!p) p = `?names[]=${encodeURIComponent(c)}`;
        else p += `&names[]=${encodeURIComponent(c)}`;
        return p;
    }, '');

    return res;
}

function toDropdownResult(names) {
    return {
        dropdowns: names.reduce((p, c) => {
            p[c] = [];
            return p;
        }, {})
    };
}

export function useDropdown(names) {
    const queryString = useMemo(() => toNamesQuery(names), [names]);
    const initialData = useMemo(() => toDropdownResult(names), [names]);

    const {data, ...queryProps} = useAuthQuery({
        queryKey: ['dropdowns', ...names],
        staleTime: 'Infinity',
        refetchOnWindowFocus: false,
        retry: false,
        retryOnMount: false,
        initialData: initialData,
        queryFn: (_, headers) =>
            fetchAndParse(`${API_URL}/p/dropdowns${queryString}`, {
                headers
            })
    });

    return {data: data?.dropdowns, ...queryProps};
}
