import React from 'react';
import {useContext} from './index.jsx';
import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import {List} from "#components/actions.jsx";
import {PlusIcon} from "@heroicons/react/20/solid";
import {useNavigate} from "react-router-dom";
import TabletProdottoInventario from "#root/src/lib/@tablet-template/tablet-prodotto-inventario.jsx";

export default function AggiungiProdotti() {
    const {inventario, setInventario} = useContext();
    const [prodotti, setProdotti] = React.useState([{id: 1, lotto: '', qta: 0}]);
    const navigate = useNavigate();

    function aggiungiProdotto() {
        setProdotti(p => {
            const lastItem = p.slice(-1)[0];
            return [...p, {id: lastItem.id + 1, lotto: '', qta: 0}];
        });
    }

    function eliminaProdotto(prodotto) {
        if (prodotti.length > 1) {
            setProdotti(p => {
                const newProdotti = p.filter(el => el.id !== prodotto.id);
                return [...newProdotti];
            });
        }
    }

    function onSubmit(event) {
        event.preventDefault();
        if (!prodotti.length > 0) return;

        setInventario((p) => ({
            ...p,
            listaProdotti: prodotti
        }));

        navigate('../conferma');
    }

    function onChange(event, param, id) {
        const value = event.target.value;

        setProdotti(p => {
            const arr = p;
            if (param === "lotto") arr[id -1].lotto = value;
            if (param === "qta") arr[id -1].qta = value;

            return arr;
        })
    }

    return (<TabletFrame
        titolo={_t.crud.carica.inventarioManuale() + " / " + _t.crud.carica.aggiungiProdotti()}
        descrizione={_t.interfaccia.messaggiCarica.aggiungiProdotti()}
        formId="inventario-form"
    >
        <h1 className="font-bold ml-4">{inventario.prodotto.prodotto}</h1>
        <List>
            <form onSubmit={onSubmit} id="inventario-form">
                {prodotti.map(p => (
                    <List.Item key={p.id}>
                        <TabletProdottoInventario
                            lotto={p}
                            onChange={onChange}
                            toggleActiveElement={() =>eliminaProdotto(p)}
                            isWebgate={false}
                        />
                    </List.Item>
                ))}
            </form>
        </List>
        <div className="w-full flex">
            <button type="button" className="btn bg-neutral-100 p-5 m-auto" onClick={() => aggiungiProdotto()}>
                <PlusIcon className="icon"/>
                <h1>{_t.crud.nuovo()}</h1>
            </button>
        </div>
    </TabletFrame>);
}