export function getOnChangeValue(arg) {
    const isEvent = arg?.nativeEvent instanceof Event;
    if (!isEvent) return arg;
    const target = arg.target;
    const type = target.getAttribute('type');

    switch (type) {
        case 'checkbox':
            return target.checked;
        default:
            return target.value;
    }
}
