import React from 'react';
import {Action, List} from '#components/actions.jsx';
import {useContext} from './index.jsx';
import {useNavigate} from 'react-router-dom';
import {useDepositi} from "#hooks/use-depositi.js";
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';

export default function Deposito() {
    const {setCaricaBins} = useContext();
    const navigate = useNavigate();
    const depositi = useDepositi();

    function selectDeposito(deposito) {
        setCaricaBins((p) => ({
            ...p,
            deposito
        }));

        navigate('../conferma');
    }

    return (
        <TabletFrame titolo={_t.crud.carica.bins() + " / " + _t.deposito.deposito()} descrizione={_t.interfaccia.messaggiCarica.deposito()}>
            {depositi.isSuccess && (
                <List>
                    {depositi.data.map((deposito) => (
                        <List.Item key={deposito.idDeposito}>
                            <Action
                                as='button'
                                onClick={() => selectDeposito(deposito)}
                            >
                                <Action.Label>{deposito.deposito}</Action.Label>
                            </Action>
                        </List.Item>
                    ))}
                </List>
            )}
        </TabletFrame>
    );
}
