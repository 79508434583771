import React from 'react';
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import {useContext} from "#routes/lavorazione/index.jsx";
import {useGetRisorseOpzioni} from "#hooks/use-lavorazione.js";
import {Action, List} from "#components/actions.jsx";
import {useNavigate} from "react-router-dom";

export default function DistintaBase() {
    const {lavorazione, setLavorazione} = useContext();
    const {fase, prodotto} = lavorazione;

    const navigate = useNavigate();
    const opzioniProdotto = useGetRisorseOpzioni(fase?.idFase, prodotto?.id);

    function selectOpzioneProdotto(opzione) {
        setLavorazione((p) => ({
            ...p,
            prodotto: {...p.prodotto, ...opzione}
        }));
        navigate('../quantita');
    }

    return (
        <TabletFrame titolo={_t.crud.lavorazione.lavorazione() + " / " + _t.lotto.lotto()} descrizione={_t.interfaccia.messaggiLavorazione.lottoSeleziona()}>
            <h1 className="mb-3 font-bold">{lavorazione.prodotto.prodotto}</h1>
            <List>
                {opzioniProdotto.data?.length > 0 && opzioniProdotto.isSuccess &&
                    opzioniProdotto.data.map((opzione) => (
                        <List.Item key={opzione.IDLotto + opzione.Tag + opzione.IDDeposito}>
                            <Action
                                as="button"
                                onClick={() => selectOpzioneProdotto(opzione)}
                            >
                                <header className="w-full flex justify-between">
                                    <span className="font-semibold mb-2 text-neutral-800">{opzione.IDLotto} {opzione.Tag && " | " + opzione.Tag}</span>
                                    <div>
                                        <table className="contents">
                                            <tbody className="contents">
                                            <tr className="flex flex-col">
                                                <th className="font-normal text-neutral-800 text-sm text-right">
                                                    Qta
                                                </th>
                                                <td className="text-sm text-right">{opzione.DispoMax}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </header>
                            </Action>
                        </List.Item>
                    ))}
                {opzioniProdotto.data?.length === 0 &&
                    <h1 className="m-2">{_t.errore.nessunRisultato()}</h1>
                }
                {!opzioniProdotto.isSuccess &&
                    <h1 className="m-2">{_t.errore.impossibileCaricareRisultati()}</h1>
                }
            </List>
        </TabletFrame>
    );
}
