import {useContext} from "./index.jsx";
import {Action} from "#components/actions.jsx";
import TabletFrame from "#root/src/lib/@tablet-template/tablet-frame.jsx";
import React from "react";
import {useNavigate} from "react-router-dom";
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";

const presetQuantities = [1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 20];

export default function Quantita () {
    const {ordini, setOrdini} = useContext();
    const quantitaMin = 1;
    const pezziCaricati = ordini.ordine.numeroPezziCaricati;
    const cartoniCaricati = pezziCaricati / ordini.ordine.pezziPerCollo;
    const [cartoni, setCartoni] = React.useState(calcolaCartoniIniziali());

    const navigate = useNavigate();

    function increase() {
        setCartoni((p) => p + 1);
    }

    function decrease() {
            setCartoni((p) => {
            if (p <= (quantitaMin)) return quantitaMin;
            return p - 1;
        });
    }

    function calcolaCartoniIniziali() {
        const qtaInizialeMax = ordini.ordine.numeroColli - (pezziCaricati / ordini.ordine.pezziPerCollo);
        const scatoleSelezionate = Math.floor(ordini.pezziSelezionati / ordini.ordine.pezziPerCollo);
        if (scatoleSelezionate < 1) return 1;
        return qtaInizialeMax > scatoleSelezionate? scatoleSelezionate : qtaInizialeMax;
    }

    function onSubmit(event) {
        event.preventDefault();
        if ((cartoni + cartoniCaricati) > ordini.ordine.numeroColli || (cartoni * ordini.ordine.pezziPerCollo) > ordini.pezziSelezionati) return;

        const form = event.target;
        const formData = new FormData(form);

        setOrdini((p) => ({
            ...p,
            cartoni: Number(formData.get('cartoni')) + (cartoniCaricati) || 0
        }));

        navigate("../conferma");
    }

    function isErrorPezziSelezionati() {
        return Number(cartoni * ordini.ordine.pezziPerCollo) > ordini.pezziSelezionati;
    }

    function isErrorCartoniSelezionati() {
        return Number(cartoni + cartoniCaricati) > ordini.ordine.numeroColli;
    }

    return (
        <TabletFrame titolo={_t.crud.ordine.ordini() + " / " + _t.quantita.quantita()} formId='quantity-form' descrizione={_t.interfaccia.messaggiOrdini.quantita()}>
            <TabletRiepilogoRow
                label={_t.crud.ordine.numeroPezziSelezionati()}
                text={
                <>
                    <p>{ordini.ordine.pezziPerCollo + ' pezzi per cartone x ' + cartoni + ' cartoni ='}</p>
                    <p>
                        <span className={isErrorPezziSelezionati()? 'text-red-600' : 'text-green-600'}>{cartoni * ordini.ordine.pezziPerCollo}</span>
                        {' pezzi / '}
                        <span className={isErrorPezziSelezionati()? 'text-red-600' : 'text-blue-600'}>{ordini.pezziSelezionati}</span> {' pezzi nei bins selezionati'}
                    </p>
                </>
                }
                textCustomClasses={"bg-neutral-50 p-5"}
            />
            <TabletRiepilogoRow
                label={_t.crud.ordine.cartoniTotali()}
                text={
                <>
                    <span className={isErrorCartoniSelezionati()? 'text-red-600' : 'text-green-600'}>{Number(cartoni + cartoniCaricati)}</span>
                    {' cartoni / '}
                    <span className={isErrorCartoniSelezionati()? 'text-red-600' : 'text-blue-600'}>{ordini.ordine.numeroColli}</span>
                    {' da completare'}
                </>}
                textCustomClasses="bg-neutral-50 p-5"
            />

            <div className='grid grid-cols-4 gap-4 mb-6 p-5'>
                <Action as='button' onClick={decrease}>
                    <Action.Label>-1</Action.Label>
                </Action>
                <form className='col-span-2' id='quantity-form' onSubmit={onSubmit}>
                    <input
                        min={quantitaMin}
                        step={1}
                        type='number'
                        className='w-full h-full text-3xl font-bold border-b-4 border-neutral-700 text-center'
                        name='cartoni'
                        value={cartoni}
                        onChange={(event) => setCartoni(Number(event.target.value))}
                    />
                </form>
                <Action as='button' onClick={increase}>
                    <Action.Label>+1</Action.Label>
                </Action>
            </div>
            <div className='grid grid-cols-4 gap-4 mb-8 p-5'>
                {presetQuantities.map((quantity) => (
                    <Action
                        key={quantity}
                        as='button'
                        onClick={() => {if (quantity >= quantitaMin) setCartoni(quantity)}}
                    >
                        <Action.Label>{quantity}</Action.Label>
                    </Action>
                ))}
            </div>
        </TabletFrame>
    );
}