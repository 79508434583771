import useAuth from 'lib/hooks/use-auth';

function getMenuActions(options) {
    const {signOut} = options;
    let nav = [
        // {
        //     label: _t.navigation.settings(),
        //     to: '/settings'
        // }
    ];

    nav.push({
        label: _t.auth.esci(),
        onClick: signOut.execute
    });

    return nav;
}

export function useMenuActions() {
    const {meta, signOut} = useAuth();
    return getMenuActions({
        signOut
    });
}
