import React from 'react';
import {Navigate} from 'react-router-dom';
import Authenticator, {STATES} from '#lib/components/authenticator';
import {addClasses} from '#lib/helpers/class';
import useAuth from '#lib/hooks/use-auth';
import useValidator, {
    required,
    mustBeEqual,
    regexp,
    minLength
} from '#lib/hooks/use-validator';
import {formToObject} from '#lib/utils/form';
import {useWasComingFrom} from '#lib/components/require-auth.jsx';

function onSignInSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const data = formToObject(form);

    const submitModel = {
        username: data.username,
        password: data.password
    };
    return submitModel;
}

function onSignUpSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const data = formToObject(form);

    const submitModel = {
        username: data.username,
        password: data.password,
        requiredAttributes: {
            name: data.name
        }
    };
    return submitModel;
}

function onCompleteNewPasswordSubmit(event) {
    event.preventDefault();
    const data = formToObject(event.target);
    return data.password;
}

function onForgotPasswordSubmit(event) {
    event.preventDefault();
    const data = formToObject(event.target);
    return data.email;
}

function onCreateNewPasswordSubmit(event) {
    event.preventDefault();
    const data = formToObject(event.target);
    return {username: data.username, code: data.code, password: data.password};
}

function onVerifyEmailSubmit(event) {
    event.preventDefault();
    const data = formToObject(event.target);
    return {code: data.code};
}

function getValidatorData(state) {
    switch (state) {
        case STATES.SIGN_IN:
            return [
                {onSubmit: onSignInSubmit},
                {
                    email: [required],
                    password: [required]
                    //company: [required]
                }
            ];
        case STATES.SIGN_UP:
            return [
                {onSubmit: onSignUpSubmit},
                {
                    email: [required],
                    name: [required],
                    acknowledgement: [required],
                    ...getPasswordValidationRules()
                }
            ];
        case STATES.COMPLETE_NEW_PASSWORD:
            return [
                {onSubmit: onCompleteNewPasswordSubmit},
                getPasswordValidationRules()
            ];

        case STATES.FORGOT_PASSWORD:
            return [
                {onSubmit: onForgotPasswordSubmit},
                {
                    email: [required]
                }
            ];
        case STATES.CREATE_NEW_PASSWORD:
            return [
                {onSubmit: onCreateNewPasswordSubmit},
                {
                    email: [required],
                    code: [required],
                    ...getPasswordValidationRules()
                }
            ];
        case STATES.VERIFY_EMAIL:
            return [{onSubmit: onVerifyEmailSubmit}, {code: [required]}];
    }
}
export function getPasswordValidationRules() {
    return {
        password: [
            required,
            minLength(8),
            regexp(getPasswordValidationRegexp(), _t.auth.completaPasswordLegenda())
        ],
        confirmpassword: [
            required,
            minLength(8),
            regexp(getPasswordValidationRegexp(), _t.auth.completaPasswordLegenda()),
            mustBeEqual('password', _t.auth.password())
        ]
    };
}

export function getPasswordValidationRegexp() {
    return /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;
}

export default function Auth(props) {
    const {state} = props;
    const {isAuthenticated, hasToChangePassword, meta} = useAuth();
    const isGettingMeta = meta.isLoading;

    const formProps = useValidator(...getValidatorData(state));
    let redirectToDestination = !isGettingMeta && !hasToChangePassword;
    const redirectDestination = useWasComingFrom() || '/';

    switch (state) {
        case STATES.SIGN_IN:
            redirectToDestination = isAuthenticated && !isGettingMeta;
            break;
        case STATES.SIGN_UP:
        case STATES.FORGOT_PASSWORD:
        case STATES.CREATE_NEW_PASSWORD:
        case STATES.VERIFY_EMAIL:
            redirectToDestination = isAuthenticated;
            break;
        case STATES.COMPLETE_NEW_PASSWORD:
            redirectToDestination = !isAuthenticated || !hasToChangePassword;
            break;
    }

    return (
        <main className="flex items-center py-16">
            {redirectToDestination ? (
                <Navigate to={redirectDestination} replace />
            ) : (
                <Authenticator
                    className={addClasses('mx-auto w-full sm:max-w-md')}
                    state={state}
                    {...formProps}
                />
            )}
        </main>
    );
}
