import React from 'react';
import {mergeClasses} from '#lib/helpers/class.js';

// TODO: Link to context that defines alignment and max-w value
export default function MaxWidth(props) {
    const {as = 'div', children, className, ...rest} = props;
    return React.createElement(
        as,
        {
            className: mergeClasses('max-w-[1330px] mx-auto w-full', className),
            ...rest
        },
        children
    );
}
