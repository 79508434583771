import React from 'react';
import {Action, List} from '#components/actions.jsx';
import {useContext} from './index.jsx';
import {useNavigate} from 'react-router-dom';
import {useDepositi} from "#hooks/use-depositi.js";
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';

export default function Deposito() {
    const warehouses = useDepositi(false);

    const {state, setState} = useContext();
    const navigate = useNavigate();
    function selectWarehouse(warehouse) {
        setState((p) => ({
            ...p,
            targetWarehouse: warehouse
        }));

        if(state.prsList.length > 0) {
            navigate('../conferma');
        } else {
            navigate('../note');
        }
    }

    return (
        <TabletFrame titolo={_t.interfaccia.carica() + " / " + _t.deposito.destinazione()} descrizione={_t.interfaccia.messaggiCarica.deposito()}>
            {warehouses.isSuccess &&
                <List>
                    {warehouses.data.map((warehouse) => (
                        <List.Item key={warehouse.idDeposito}>
                            <Action
                                as="button"
                                onClick={() => selectWarehouse(warehouse)}
                            >
                                <Action.Label>{warehouse.deposito}</Action.Label>
                            </Action>
                        </List.Item>
                    ))}
                </List>
            }
        </TabletFrame>
    );
}
