import React from "react";
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {Action, List} from "#components/actions.jsx";
import {useContext} from "../index.jsx";
import Quantita from "./quantita.jsx";
import Lotto from "./lotto.jsx";
import {useProdotti} from "#hooks/use-prodotti.js";
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';

function Start() {
    const {setState} = useContext();
    const prodotti = useProdotti({porzionato: "false"});
    const navigate = useNavigate();

    function selectProduct(product) {
        setState((p) => ({
            ...p,
            isManual: true,
            manual: {
                ...p.manual,
                idProdotto: product.id,
                name: product.prodotto,
                codice: product.codice,
                idUm: product.idUm,
                peso: product.peso
            },
            products: prodotti.data
        }));
        navigate('quantita');
    }

    return (
        <TabletFrame titolo={_t.crud.carica.forme() + " / " + _t.prodotto.prodotto()} descrizione={_t.interfaccia.messaggiCarica.prodotto()}>
            <List>
                {prodotti.data?.length > 0 && prodotti.isSuccess &&
                    prodotti.data.map((product) => (
                        <List.Item key={product.codice}>
                            <Action
                                as="button"
                                onClick={() => selectProduct(product)}
                            >
                                <Action.Label>{product.prodotto}{product.id.includes('SL')? ' - SL' : ''}</Action.Label>
                            </Action>
                        </List.Item>
                    ))}
                {prodotti.data?.length <= 0 &&
                    <h1 className="m-2">{_t.errore.nessunRisultato()}</h1>
                }
            </List>
        </TabletFrame>
    );
}

export default function Forme() {
    const {state} = useContext();
    const navigate = useNavigate();
    const {pathname} = useLocation();

    if(pathname !== '/carica/forme' && !state?.manual)
        navigate('/carica/forme');

    return (
        <Routes>
            <Route index element={<Start />} />
            <Route path="quantita" element={<Quantita />} />
            <Route path="lotto" element={<Lotto />} />
        </Routes>
    );
}
