import {fetchAndParse} from "#lib/helpers/fetch.js";
import {useAuthQuery} from "#lib/hooks/use-query.js";

export function useInventario(id) {
    const {data, ...queryProps} = useAuthQuery({
        queryKey: ['inventario'],
        staleTime: 'Infinity',
        refetchOnWindowFocus: false,
        retry: false,
        retryOnMount: false,
        queryFn: (_, headers) =>
            fetchAndParse(`${API_URL}/p/inventario?id=${id || ''}`, {
                headers
            })
    });

    return {data: data?.results, ...queryProps};
}

export function useInventarioLista() {
    const {data, ...queryProps} = useAuthQuery({
        queryKey: ['inventario-lista'],
        staleTime: 'Infinity',
        refetchOnWindowFocus: false,
        retry: false,
        retryOnMount: false,
        queryFn: (_, headers) =>
            fetchAndParse(`${API_URL}/p/inventario/lista`, {
                headers
            })
    });

    return {data: data?.results, ...queryProps};
}