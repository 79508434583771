import React from "react";
import {Navigate, Route, Routes} from 'react-router-dom';
import Carica from "#routes/carica/index.jsx";
import Trasferisci from "#routes/trasferisci/index.jsx";
import {Private as _Private} from "lib/components/require-auth.jsx";
import Root from "./root.jsx";
import MovimentiSearch from "#routes/gestione/movimenti/search.jsx";
import GiacenzeSearch from "#routes/gestione/giacenze/search.jsx";
import Lavorazione from "#routes/lavorazione/index.jsx";
import useAuth, {DEFAULT_AUTH_ROUTES as AUTH_ROUTES} from '#lib/hooks/use-auth.jsx';
import {STATES as AUTH_STATES} from '#lib/components/authenticator.jsx';
import Auth from '#routes/auth.jsx';
import AppShell from '#routes/app-shell.jsx';
import {makeCrudRoutes} from "#helpers/routes.jsx";
import NotFound from "#routes/404.jsx";
import UtentiSearch from "#routes/gestione/utente/search.jsx";
import UtentiEdit from "#routes/gestione/utente/edit.jsx";
import MovimentiEdit from "#routes/gestione/movimenti/edit.jsx";
import Ordini from "#routes/ordini/index.jsx";

const routeToAuthenticatorState = {
    [AUTH_STATES.SIGN_IN]: AUTH_ROUTES.SIGN_IN,
    [AUTH_STATES.SIGN_UP]: AUTH_ROUTES.SIGN_UP,
    [AUTH_STATES.COMPLETE_NEW_PASSWORD]: AUTH_ROUTES.COMPLETE_NEW_PASSWORD,
    [AUTH_STATES.FORGOT_PASSWORD]: AUTH_ROUTES.FORGOT_PASSWORD,
    [AUTH_STATES.CREATE_NEW_PASSWORD]: AUTH_ROUTES.CREATE_NEW_PASSWORD,
    [AUTH_STATES.VERIFY_EMAIL]: AUTH_ROUTES.VERIFY_EMAIL
};

function checkRouteAuth(user, meta, location) {
    //todo: controllare i ruoli qui
    return null;

    // const userRoles = meta.data?.roles;
    // if (!userRoles) return null;
    //
    // //retrieve routeAuthModel elements from userRoles
    // const routeAuthRoles = userRoles
    //     ?.map((role) => routesByRole[role])
    //     .filter((el) => el) //remove roles without route model
    //     .sort((a, b) => a.order - b.order);
    //
    // const pathname = location.pathname;
    // let excludeRedirect;
    //
    // for (let role of routeAuthRoles)
    //     for (let route of role.routes)
    //         if (matchPath(route, pathname)) {
    //             if (route.exclude) {
    //                 excludeRedirect = parseRedirectUrl(
    //                     pathname,
    //                     route.redirect ?? role.redirect
    //                 );
    //                 break; //if another role grants the access for the excluded path => ignore exclude
    //             }
    //             return null;
    //         }
    //
    // return (
    //     excludeRedirect ??
    //     parseRedirectUrl(pathname, routeAuthRoles.find(() => true)?.redirect)
    // );
}

export const Private = (Element) => _Private(Element, checkRouteAuth);

export default function AppRoutes() {
    const {isAuthenticated} = useAuth();

    return (
        <Routes>
            <Route path="*" element={<AppShell />}>
                <Route
                    index
                    element={
                        <Navigate
                            to={isAuthenticated ? 'home' : AUTH_ROUTES.SIGN_IN}
                            state={{from: '/'}}
                        />
                    }
                />
                {Object.entries(routeToAuthenticatorState).map(([state, path]) => (
                    <Route key={state} path={path} element={<Auth state={state} />} />
                ))}
                <Route path="home" element={Private(<Root />)}/>
                <Route path="carica/*" element={Private(<Carica/>)} />
                <Route path="trasferisci/*" element={Private(<Trasferisci/>)} />
                <Route path="lavorazione/*" element={Private(<Lavorazione/>)} />
                <Route path="ordini/*" element={Private(<Ordini/>)}/>
                <Route path="gestione">
                    {makeCrudRoutes('movimento', MovimentiSearch, MovimentiEdit)}
                    {makeCrudRoutes('giacenza', GiacenzeSearch)}
                    {makeCrudRoutes('utente', UtentiSearch, UtentiEdit)}
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Route>
        </Routes>
    );
}
