import React from "react";

export default function TabletRiepilogoRow(props) {
    const {label, text, textCustomClasses = ''} = props;

    return (
        <dl className='text-md font-semibold mb-6 container-single-grid'>
            <dt className='bg-neutral-50 p-5'>{label}</dt>
            <dl className={'bg-neutral-50 p-5 text-right ' + textCustomClasses}>{text}</dl>
        </dl>
    );
}