import React from 'react';
import Confirm from '#components/confirm-button.jsx';
import {useNavigate} from 'react-router-dom';

export default function TabletFrame(props) {
    const {titolo, descrizione, formId, onConfirm, children} = props;
    const navigate = useNavigate();

    return (
        <div className="mx-auto max-w-screen-md flex-wrapper">
            <div className="flex-content">
                <div className="bg-black p-5 mb-4 cursor-pointer" onClick={() => navigate('/home')}>
                    <h1 className="text-3xl font-semibold text-white">{titolo}</h1>
                    <p className="text-md font-semibold text-white mt-2">{descrizione}</p>
                </div>
                {children}
            </div>

            <div className="flex flex-row justify-around bg-neutral-950 mt-4">
                <Confirm forward={false} onClick={() => navigate(-1)}>
                    <h1>{_t.bottone.indietro()}</h1>
                </Confirm>
                {formId && <Confirm type="submit" form={formId}>
                    <h1>{_t.bottone.avanti()}</h1>
                </Confirm>}
                {onConfirm && <Confirm onClick={onConfirm}>
                    <h1>{_t.bottone.conferma()}</h1>
                </Confirm>}
            </div>
        </div>
    );
}
