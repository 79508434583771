import React, {useEffect} from "react";
import Grid from "#lib/components/grid.jsx";
import Input from "#lib/components/input.jsx";
import DesktopFrame from "#root/src/lib/@desktop-template/desktop-frame.jsx";
import {useDropdown} from "#hooks/use-dropdown.js";
import Select from "#lib/components/select.jsx";
import SearchPanel from "#components/search-panel.jsx";
import useManageCrud from "#@wizard/use-manage.js";
import SearchResults from "#components/search-results.jsx";
import {makePaginatedTableColumns} from "#helpers/table.jsx";
import {capitalize} from "lodash-es";
import ManageControls from "#components/manage-controls.jsx";
import BreadCrumbs from "#src/components/breadcrumbs.jsx";
import Switch from 'lib/components/switch';
import {addClasses} from "#lib/helpers/class.js";

export default function GiacenzeSearch() {
    const dropdowns = useDropdown(['depositi']);
    const [raggruppamenti, setRaggruppamenti] = React.useState({
        groupDeposito: true,
        groupUbicazione: true,
        groupLotto: true,
        groupTag: true
    });
    const [templateColumns, setTemplateColumns] = React.useState('auto auto auto auto auto min-content min-content');

    const porzionatoOptions = [
        {
            id: '',
            text: 'Seleziona opzione'
        },
        {
            id: 'false',
            text: 'Forme'
        },
        {
            id: 'true',
            text: 'Porzionati'
        },
    ];

    const {
        data,
        stateFlags,
        searchParams,
        changeSorting,
        paginationProps,
        getSearchFieldProps,
        setMultipleSearchFields
    } = useManageCrud(
        'giacenza',
        {},
        {
            search: {initialSortField: 'prodotto', initFields: {...raggruppamenti}}
        });

    const columnProps = makePaginatedTableColumns(
        data,
        {
            searchParams,
            changeSorting
        },
        {
            toDetail: false,
            omit: ['idProdotto', 'idDeposito', 'idUm', 'codice'],
            headerText: (accessor) => {
                if(accessor === 'idLotto') return _t.lotto.lotto();
                return capitalize(accessor);
            }
        }
    );

    columnProps.columns = columnProps.columns.map((c) => {
        if (c.accessor === 'qta') {
            c.Cell = function renderCell({row}) {
                return (
                    <div className="text-right w-full">
                        {row.qta.toLocaleString()}
                    </div>
                );
            };
        } else if (c.accessor === 'peso') {
            c.Cell = function renderCell({row}) {
                return (
                    <div className="text-right w-full">
                        {row.peso.toLocaleString()} {row.idUm}
                    </div>
                );
            };
        } else if (c.accessor === 'tag') {
            c.Cell = function renderCell({row}) {
                if (!row.tag) return;
                const segments = row.tag.split('#');
                segments.shift();

                return (
                    <div>
                        {segments.map((s, i) => (
                            <span key={i} className={i > 15? "tag-palette-16 font-bold" : "font-bold tag-palette-" + (i + 1)}>#{s}</span>
                        ))}
                    </div>
                );
            };
        } else if (c.accessor === 'prodotto') {
            c.Cell = function renderCell({row}) {
                return (
                    <div>
                        {row.prodotto} {row.idProdotto.includes('SL')? ' SL ' : ''}
                    </div>
                );
            };
        }

        return c;
    });

    useEffect( ()=> {
        let count = 0;
        if (raggruppamenti.groupDeposito) count++;
        if (raggruppamenti.groupUbicazione) count++;
        if (raggruppamenti.groupLotto) count++;
        if (raggruppamenti.groupTag) count++;

        switch (count) {
            case 0:
                setTemplateColumns('auto min-content min-content');
                break;
            case 1:
                setTemplateColumns('auto auto min-content min-content');
                break;
            case 2:
                setTemplateColumns('auto auto auto min-content min-content');
                break;
            case 3:
                setTemplateColumns('auto auto auto auto min-content min-content');
                break;
            default:
                setTemplateColumns('auto auto auto auto auto min-content min-content');
        }
    }, [raggruppamenti]);

    columnProps.templateColumns = templateColumns;

    function onChangeGroupDeposito() {
        searchParams.groupDeposito = !raggruppamenti.groupDeposito;
        searchParams.groupUbicazione = false;
        setRaggruppamenti((p) => ({
            ...p,
            groupDeposito: !p.groupDeposito,
            groupUbicazione: false
        }));
        setMultipleSearchFields();
    }

    function onChangeGroupUbicazione() {
        searchParams.groupUbicazione = !raggruppamenti.groupUbicazione;
        searchParams.groupDeposito = true;
        setRaggruppamenti((p) => ({
            ...p,
            groupUbicazione: !p.groupUbicazione,
            groupDeposito: true
        }));
        setMultipleSearchFields();
    }

    return (
        <DesktopFrame>
            <ManageControls.BarSlot>
                <BreadCrumbs customName={_t.crud.giacenze.giacenze()}/>
            </ManageControls.BarSlot>
            <SearchPanel
                onSubmit={setMultipleSearchFields}
                onChange={setMultipleSearchFields}
                searchParams={searchParams}
            >
                <Grid>
                    <Grid.Slot span={2}>
                        <Input.Full
                            placeholder={_t.prodotto.prodotto()}
                            name="prodotto"
                            type="search"
                            label={_t.prodotto.prodotto()}
                            onChange={(e) => {
                                searchParams.prodotto = e.target.value;
                                setMultipleSearchFields();
                            }}
                        />
                    </Grid.Slot>
                    <Grid.Slot span={2}>
                        <Select.Full
                            className="flex-grow"
                            label={_t.prodotto.tipo()}
                            {...getSearchFieldProps('porzionato')}
                            options={porzionatoOptions}
                            accessValue={(option) => option.id}
                            displayValue={(id) => porzionatoOptions.find(c => c.id === id)?.text || ''}
                            nullable="true"
                            onChange={(e) => {
                                searchParams.porzionato = e;
                                setMultipleSearchFields();
                            }}
                        />
                    </Grid.Slot>
                    <Grid.Slot span={2}>
                        <Select.Full
                            style={{flexGrow: 0}}
                            label={_t.deposito.deposito()}
                            {...getSearchFieldProps('depositi')}
                            options={[
                                {id: '', text: 'Seleziona opzione'},
                                ...dropdowns.data.depositi.filter(d => !['SPE1', 'GRA1'].includes(d.id))
                            ]}
                            accessValue={(option) => option.id}
                            displayValue={(selectedId, options) => (
                                options.find((el) => el.id === selectedId)?.text
                            )}
                            onChange={(e) => {
                                searchParams.depositi = e;
                                setMultipleSearchFields();
                            }}
                        />
                    </Grid.Slot>
                    <Grid.Slot span={2}>
                        <Input.Full
                            placeholder={_t.lotto.lotto()}
                            name="lotto"
                            type="search"
                            label={_t.lotto.lotto()}
                            onChange={(e) => {
                                searchParams.lotto = e.target.value;
                                setMultipleSearchFields();
                            }}
                        />
                    </Grid.Slot>
                    <Grid.Slot span={1}>
                        <Input.Full
                            placeholder={_t.crud.movimenti.tag()}
                            name="tag"
                            type="search"
                            label={_t.crud.movimenti.tag()}
                            onChange={(e) => {
                                searchParams.tag = e.target.value;
                                setMultipleSearchFields();
                            }}
                        />
                    </Grid.Slot>
                    <Grid.Slot span={2} className="w-full col-span-6 md:col-span-3 flex items-end justify-start gap-3 order-9">
                        <Switch.Container className={addClasses('flex flex-col')}>
                            <Switch.Label>{_t.deposito.deposito()}</Switch.Label>
                            <Switch name="groupDeposito" checked={raggruppamenti.groupDeposito} onChange={onChangeGroupDeposito}/>
                        </Switch.Container>
                        <Switch.Container className={addClasses('flex flex-col')}>
                            <Switch.Label>{_t.deposito.ubicazione()}</Switch.Label>
                            <Switch name="groupUbicazione" checked={raggruppamenti.groupUbicazione} onChange={onChangeGroupUbicazione}/>
                        </Switch.Container>
                        <Switch.Container className={addClasses('flex flex-col')}>
                            <Switch.Label>{_t.lotto.lotto()}</Switch.Label>
                            <Switch name="groupLotto" checked={raggruppamenti.groupLotto} onChange={() => {
                                searchParams.groupLotto = !raggruppamenti.groupLotto;
                                setRaggruppamenti((p) => ({...p, groupLotto: !p.groupLotto}));
                                setMultipleSearchFields();
                            }}/>
                        </Switch.Container>
                        <Switch.Container className={addClasses('flex flex-col')}>
                            <Switch.Label>{_t.crud.movimenti.tag()}</Switch.Label>
                            <Switch name="groupTag" checked={raggruppamenti.groupTag} onChange={() => {
                                searchParams.groupTag = !raggruppamenti.groupTag;
                                setRaggruppamenti((p) => ({...p, groupTag: !p.groupTag}));
                                setMultipleSearchFields();
                            }} />
                        </Switch.Container>
                    </Grid.Slot>
                </Grid>
            </SearchPanel>
            <SearchResults
                data={data}
                {...stateFlags}
                {...paginationProps}
                {...columnProps}
                paginated
            />
        </DesktopFrame>
    );
}