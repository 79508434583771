import React from 'react';
import {
    MagnifyingGlassIcon,
    ArrowPathIcon,
    ArrowDownTrayIcon
} from '@heroicons/react/24/solid';
import Button from 'lib/components/button';
import {captureFormSubmit, captureFormChange} from 'lib/utils/form';
import {isFunction} from 'lib/utils/function';
import {mergeClasses} from 'lib/helpers/class';

export function useSearchForm(searchParams, onSubmit, onChange) {
    const [isNewSearch, setIsNewSearch] = React.useState(true);
    const isRefreshing = !isNewSearch;

    const _onChange = React.useCallback(
        (event) => {
            const data = captureFormChange(event);
            if (searchParams) {
                const entries = Object.entries(data);
                const isNewSearch = entries.some(
                    ([key, value]) => searchParams[key] !== value
                );
                setIsNewSearch(isNewSearch);
            }
            if (isFunction(onChange)) onChange(data, event);
        },
        [searchParams, onChange]
    );

    const _onSubmit = React.useCallback(
        (event) => {
            const data = captureFormSubmit(event);

            //TODO: hack per refetchare griglia su submit anche senza che i parametri siano cambiati
            data.key = new Date();

            if (searchParams) setIsNewSearch(true);
            if (isFunction(onSubmit)) onSubmit(data, event);
        },
        [searchParams, onSubmit]
    );

    return {
        isRefreshing,
        isNewSearch,
        onChange: _onChange,
        onSubmit: _onSubmit
    };
}

export default function SearchPanel(props) {
    const {
        searchParams,
        onSubmit: providedOnSubmit,
        onChange: providedOnChange,
        onExport,
        onExportLoad,
        children,
        className,
        ...rest
    } = props;

    const {onChange, onSubmit, isRefreshing, isNewSearch} = useSearchForm(
        searchParams,
        providedOnSubmit,
        providedOnChange
    );

    return (
        <form
            className={mergeClasses(
                'mb-6 flex items-end gap-3 border-b border-neutral-200 bg-white p-2 shadow-md md:gap-4 md:rounded-lg md:border md:p-5 md:pt-4 lg:p-6 lg:pt-5',
                className
            )}
            onSubmit={onSubmit}
            onChange={onChange}
            {...rest}
        >
            {children}
            <Button look="primary" type="submit">
                {isRefreshing && (
                    <React.Fragment>
                        <ArrowPathIcon className="icon -my-0.5" />
                        <span className="sr-only">{_t.interfaccia.aggiorna()}</span>
                    </React.Fragment>
                )}
                {isNewSearch && (
                    <React.Fragment>
                        <MagnifyingGlassIcon className="icon -my-0.5" />
                        <span className="sr-only">{_t.interfaccia.cerca()}</span>
                    </React.Fragment>
                )}
            </Button>

            {onExport && (
                <Button
                    look="primary"
                    type="button"
                    onClick={onExport}
                    busy={onExportLoad}
                >
                    <>
                        <ArrowDownTrayIcon className="icon -my-0.5" />
                        <span className="sr-only">{_t.interfaccia.esporta()}</span>
                    </>
                </Button>
            )}
        </form>
    );
}
