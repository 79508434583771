import React from 'react';
import {useRouteParams} from 'src/lib/@crud';
import BreadCrumbsComponent from 'lib/components/breadcrumbs';
import {capitalize} from 'lib/utils/string';

export default function Breadcrumbs(props) {
    const {detailName = _t.interfaccia.dettaglio(), customName, ...rest} = props;
    const {isCreate, isDetail, name, routes} = useRouteParams();

    let levels = [{name: customName ? customName : capitalize(name), to: `../${routes.search}`}];

    if (isDetail)
        levels.push({name: isCreate ? _t.interfaccia.crea() : detailName, to: '#'});

    return <BreadCrumbsComponent rootUrl="/home" levels={levels} {...rest} />;
}
