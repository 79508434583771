export const buttonLooks = Object.freeze({
    primary: 'primary',
    secondary: 'secondary',
    white: 'white',
    text: 'text',
    circular: 'circular'
});

export const sizes = Object.freeze({
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl'
});

export const sizeScale = Object.freeze({
    [sizes.xs]: 0,
    [sizes.sm]: 1,
    [sizes.md]: 2,
    [sizes.lg]: 3,
    [sizes.xl]: 4
});
