import React from 'react';

export default function useGranularEffect(effect, primaryDeps, secondaryDeps) {
    const ref = React.useRef();

    if (!ref.current || !primaryDeps.every((d, i) => Object.is(d, ref.current[i]))) {
        ref.current = [...primaryDeps, ...secondaryDeps];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useEffect(effect, ref.current);
}
