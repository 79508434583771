import React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, info: undefined};
    }

    // eslint-disable-next-line no-unused-vars
    static getDerivedStateFromError(err, info) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(err, info) {
        this.setState((state) => ({...state, info}));
        console.error(err, info.componentStack);
    }

    render() {
        if (this.state.hasError) {
            if (this.props.fallback) return this.props.fallback;
            if (this.state.info) return <pre>{this.state.info.componentStack}</pre>;
            return null;
        }

        return this.props.children;
    }
}
