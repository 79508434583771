import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Auth, Hub} from 'aws-amplify';
import awsConfig, {issuer} from '#src/aws.config.js';
import {useQueryClient} from '@tanstack/react-query';
import useAsync from '#lib/hooks/use-async.js';
import {defineDefaults} from '#lib/helpers/react.jsx';

import useUniversalLayoutEffect from '#lib/hooks/use-universal-layout-effect.js';

export const DEFAULT_AUTH_ROUTES = Object.freeze({
    SIGN_IN: 'signin',
    SIGN_UP: 'signup',
    CHANGE_PASSWORD: 'change-password',
    COMPLETE_NEW_PASSWORD: 'complete-new-password',
    FORGOT_PASSWORD: 'forgot-password',
    CREATE_NEW_PASSWORD: 'forgot-password-complete',
    VERIFY_EMAIL: 'verify-email'
});

export const MFA_TYPE = Object.freeze({
    SMS: 'SMS_MFA',
    TOTP: 'SOFTWARE_TOKEN_MFA',
    NO_FMA: 'NOMFA'
});

export const CHALLENGES = Object.freeze({
    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED'
});

Auth.configure(awsConfig);

const AuthContext = React.createContext();

export function AuthProvider(props) {
    const {children, options = {}} = props;
    const auth = useProvideAuth(options);
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function useAuth() {
    return React.useContext(AuthContext);
}

export async function getJwtToken() {
    return Auth.currentSession().then((session) => session.getIdToken().getJwtToken());
}

export async function getAuthHeader() {
    const token = await getJwtToken();
    return {authorization: token};
}

const defaultOptions = {
    AUTH_ROUTES: DEFAULT_AUTH_ROUTES
};

async function getLoggedUser() {
    try {
        return await Auth.currentAuthenticatedUser();
    } catch {
        return null;
    }
}

function useProvideAuth(options) {
    defineDefaults(options, defaultOptions);
    const {getMeta, AUTH_ROUTES} = options;

    const [isLoading, setIsLoading] = React.useState(true);
    const [user, setUser] = React.useState(null);
    const [tempUser, setTempUser] = React.useState(null);
    const [unconfirmedUser, setUnconfirmedUser] = React.useState(null);
    const currentUser = user ?? tempUser;

    const isAuthenticated = Boolean(currentUser);

    const setUserData = React.useCallback(
        (user) => {
            setIsLoading(false);
            if (!user) return;
            setUser(user);
        },
        // cookie state setters are stable because the key is constant
        []
    );

    const challengeName = currentUser?.challengeName;
    const preferredMFA = currentUser?.preferredMFA;
    const hasToChangePassword = challengeName === CHALLENGES.NEW_PASSWORD_REQUIRED;
    const isMFAActive = preferredMFA === MFA_TYPE.SMS || preferredMFA === MFA_TYPE.TOTP;
    const isTOTPMFA = preferredMFA === MFA_TYPE.TOTP;
    const isSMSMFA = preferredMFA === MFA_TYPE.SMS;

    //TODO: sostituire con check generico per distinguere utente vero (autenticato) da temporaneo (check su presenza challange?)
    const meta = useAsync(getMeta, {
        immediate: isAuthenticated && !hasToChangePassword
    });

    const queryClient = useQueryClient();

    const clearUserData = React.useCallback(() => {
        setUser(null);
        meta.clear();
        queryClient.clear();
        // cookie state setters are stable because the key is constant
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshUserData = useAsync(async () => {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true
        });
        console.log(user);
        setUser(user);
    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    const wasComingFrom = location.state?.from || '/';

    useUniversalLayoutEffect(() => {
        // NOTE: check for user or risk an infinite loop
        if (!user) {
            // If a session cookie exists on component mount
            // use it to reset auth state
            (async () => {
                const user = await getLoggedUser();
                setUserData(user);
            })();
        }
    }, [user, setUserData]);

    const signIn = useAsync(
        async ({username, password}) => Auth.signIn(username, password),
        {
            onSuccess: (user) => {
                if (user.challengeName === CHALLENGES.NEW_PASSWORD_REQUIRED)
                    setTempUser(user);
                else setUserData(user);
            }
        }
    );

    const signUp = useAsync(async ({username, password, requiredAttributes}) =>
        Auth.signUp({
            username,
            password,
            attributes: requiredAttributes,
            autoSignIn: {enabled: true}
        }).then((res) => {
            setUnconfirmedUser(res.user);
            navigate(`/`); //${AUTH_ROUTES.VERIFY_EMAIL} //TODO: valutare un verify email
        })
    );

    const signOut = useAsync(
        async ({global}) => Promise.allSettled([Auth.signOut({global})]),
        {
            onSettled: () => {
                clearUserData();
                navigate(`/${AUTH_ROUTES.SIGN_IN}`);
            }
        }
    );

    const signOutOfAllDevices = useAsync(async () =>
        Promise.allSettled([signOut.execute({global: true})])
    );

    // SMS Multi-Factor Authentication

    const setUpSMSMFA = useAsync(async () => Auth.setPreferredMFA(user, MFA_TYPE.SMS));

    // Time-Based One-time Password Algorithm (TOTP)

    const setUpTOTP = useAsync(async () => {
        const user = await Auth.currentAuthenticatedUser();
        const {username} = user;
        return Auth.setupTOTP(user).then(
            (code) =>
                `otpauth://totp/AWSCognito:${username}?secret=${code}&issuer=${issuer}`
        );
    });

    const verifyTOTP = useAsync(async (oneTimePassword) => {
        const user = await Auth.currentAuthenticatedUser();
        return Auth.verifyTotpToken(user, oneTimePassword).then(() =>
            Auth.setPreferredMFA(user, MFA_TYPE.TOTP)
        );
    });

    const removeMFA = useAsync(async () => {
        const user = await Auth.currentAuthenticatedUser();
        return Auth.setPreferredMFA(user, MFA_TYPE.NO_FMA);
    });

    const changePassword = useAsync(async (oldPassword, newPassword) => {
        const user = await Auth.currentAuthenticatedUser();
        return Auth.changePassword(user, oldPassword, newPassword);
    });

    const completeNewPassword = useAsync(async (newPassword, requiredAttributes) => {
        return Auth.completeNewPassword(tempUser, newPassword, requiredAttributes).catch(
            (err) => {
                setUser(null);
                navigate(`/${AUTH_ROUTES.SIGN_IN}`);
                return err;
            }
        );
    });

    const forgotPassword = useAsync(async (username) =>
        Auth.forgotPassword(username).then(() => {
            navigate(`/${AUTH_ROUTES.CREATE_NEW_PASSWORD}`);
        })
    );

    const forgotPasswordSubmit = useAsync(async ({username, code, password}) =>
        Auth.forgotPasswordSubmit(username, code, password).then(() => {
            navigate(`/${AUTH_ROUTES.SIGN_IN}`);
        })
    );

    /**
     * Send attribute verification code
     * @param {email|phone_number} attribute
     **/
    const confirmSignUp = useAsync(
        async ({code}) => Auth.confirmSignUp(unconfirmedUser?.username, code)
        //TODO: add redirect
    );

    Hub.listen('auth', ({payload}) => {
        const {event} = payload;
        switch (event) {
            case 'autoSignIn':
                if (!user) setUserData(payload.data);
                setUnconfirmedUser(null);
                break;
            case 'autoSignIn_failure':
                navigate(`/${AUTH_ROUTES.SIGN_IN}`);
                setUnconfirmedUser(null);
                break;
        }
    });

    /**
     * Send attribute verification code
     * @param {email|phone_number} attribute
     **/
    const verifyAttribute = useAsync(async (attribute) =>
        Auth.verifyCurrentUserAttribute(attribute)
    );

    /**
     * Confirm attribute
     * @param {email|phone_number} attribute
     **/
    const verifyAttributeSubmit = useAsync(
        async (attribute, verificationCode) =>
            Auth.verifyCurrentUserAttributeSubmit(attribute, verificationCode),
        {
            onSuccess: refreshUserData.execute
        }
    );

    return {
        user,
        meta,
        isLoading,
        isAuthenticated,
        hasToChangePassword,
        refreshUserData,
        completeNewPassword,
        confirmSignUp,
        preferredMFA,
        isMFAActive,
        isSMSMFA,
        isTOTPMFA,
        signUp,
        signIn,
        signOut,
        signOutOfAllDevices,
        setUpSMSMFA,
        setUpTOTP,
        verifyTOTP,
        removeMFA,
        changePassword,
        forgotPassword,
        forgotPasswordSubmit,
        verifyAttribute,
        verifyAttributeSubmit,
        AUTH_ROUTES
    };
}
