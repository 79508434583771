import {cn} from '#lib/helpers/class.js';
import React from 'react';

export function List(props) {
    const {children} = props;
    return <ol className="flex flex-col gap-4 sm:gap-6">{children}</ol>;
}

function Item(props) {
    const {children} = props;
    return <li className="isolate relative">{children}</li>;
}

List.Item = Item;

export function Action(props) {
    const {as, selected, children, ...rest} = props;

    return React.createElement(
        as,
        {
            className: cn(
                selected && 'ring-4',
                'w-full text-left text-inherit bg-neutral-100 text-neutral-950 p-4 flex flex-col gap-4 ring-neutral-950 ring-inset'
            ),
            ...rest
        },
        children
    );
}

function Label(props) {
    const {children} = props;
    return (
        <p className="font-semibold text-2md md:text-3xl">{children}</p>
    );
}

Action.Label = Label;
