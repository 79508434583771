import React from 'react';
import {isFunction} from '#lib/utils/function.js';
import {ENVIRONMENTS} from '#lib/constants.js';
import {defineDefaults} from '#lib/helpers/react.jsx';

const defaultOptions = {
    immediate: false,
    parseError: (err) => err?.message ?? err,
    parseResult: (data) => data
};

const STATUS = {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error'
};

// TODO: Add deduping capabilities
export default function useAsync(asyncFn, options = {}) {
    defineDefaults(options, defaultOptions);
    const {immediate, parseError, parseResult, onSuccess, onLoading, onError, onSettled} =
        options;

    const [status, setStatus] = React.useState(STATUS.IDLE);
    const [data, setData] = React.useState(null);
    const [error, setError] = React.useState(null);

    const isIdle = status === STATUS.IDLE;
    const isLoading = status === STATUS.LOADING;
    const isSuccess = status === STATUS.SUCCESS;
    const isError = status === STATUS.ERROR;

    const execute = React.useCallback(
        (...args) => {
            if (!isFunction(asyncFn)) return;
            setStatus(STATUS.LOADING);
            setError(null);
            setData(null);
            if (isFunction(onLoading)) onLoading();

            return new Promise((resolve) => {
                asyncFn(...args)
                    .then((data) => {
                        setData(parseResult(data));
                        setStatus(STATUS.SUCCESS);
                        if (typeof onSuccess == 'function') onSuccess(data);
                        resolve(data);
                    })
                    .catch((error) => {
                        setError(parseError(error));
                        setStatus(STATUS.ERROR);
                        if (typeof onError == 'function') onError(data);
                        if (ENV === ENVIRONMENTS.DEVELOPMENT) console.error(error);
                        // TODO: Discutere del reject
                        // prima era `reject(err)`;
                    })
                    .finally(() => {
                        if (typeof onSettled == 'function') onSettled(data);
                    });
            });
        },
        [asyncFn, data, onError, onLoading, onSettled, onSuccess, parseError, parseResult]
    );

    const clear = React.useCallback(() => {
        setStatus(STATUS.IDLE);
        setData(null);
        setError(null);
    }, []);

    React.useEffect(() => {
        if (immediate && status === STATUS.IDLE) execute();
    }, [execute, immediate, status]);

    return {
        execute,
        clear,
        status,
        isIdle,
        isLoading,
        isSuccess,
        isError,
        error,
        data
    };
}
