import {useAuthQuery} from "#lib/hooks/use-query.js";
import {fetchAndParse} from "#lib/helpers/fetch.js";

export function useGiacenzeDeposito(sourceWarehouse, porzionato, idProdotto) {
    const {data, isSuccess, ...queryProps} = useAuthQuery({
        queryKey: ['giacenzeDeposito'],
        staleTime: 'Infinity',
        refetchOnWindowFocus: false,
        retry: false,
        retryOnMount: false,
        queryFn: (_, headers) =>
            fetchAndParse(`${API_URL}/p/giacenze/giacenzeDeposito?deposito=${sourceWarehouse || ''}&porzionato=${porzionato}&idProdotto=${idProdotto || ''}`, {
                headers
            })
    });

    return {data: data?.results, ...queryProps, isSuccess};
}