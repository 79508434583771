import React from 'react';
import {cn} from '#lib/helpers/class.js';
import {ArrowLeftIcon, ArrowRightIcon} from '@heroicons/react/24/solid';

export default function Confirm(props) {
    const {as = 'button', forward = true, children, disabled, ...rest} = props;

    const className = cn(
        disabled && 'opacity-75',
        'bg-neutral-950 inline-flex items-center gap-4 justify-center text-center text-white p-5 text-2xl font-semibold shrink-0'
    );

    switch (as) {
        case 'button':
            return (
                <button type="button" className={className} disabled={disabled} {...rest}>
                    {!forward &&
                        <ArrowLeftIcon className="icon"/>
                    }
                    {children}
                    {forward &&
                        <ArrowRightIcon className="icon"/>
                    }
                </button>
            );
        case 'submit':
            return (
                <input
                    type="submit"
                    className={className}
                    disabled={disabled}
                    value={children}
                    {...rest}
                />
            );
        default:
            return React.createElement(as, {className, ...rest}, children);
    }
}
