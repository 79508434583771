import {useContext} from './index.jsx';
import React from "react";
import {useNavigate} from 'react-router-dom';
import {useCreaMovimentoPrs, useCreaMovimento} from "#hooks/use-movimenti.js";
import {useAggiornaSpedizione} from "#hooks/use-spedizioni.js";
import {Causali} from '#root/src/constants.js';
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";

export default function Conferma() {
    const {state} = useContext();
    const navigate = useNavigate();
    const spedizioni = state.spedizioni;

    const creaMovimentoMutation = useCreaMovimento();
    const creaMovimentoPrsMutation = useCreaMovimentoPrs();
    const aggiornaSpedizioneMutation = useAggiornaSpedizione();

    async function OnCreaMovimento() {
        if (state.isManual) {
            const {lotto, quantity, idProdotto} = state.manual;
            creaMovimentoMutation.mutate({
                idProdotto,
                idDeposito: state.targetWarehouse.idDeposito,
                idCausale: Causali.caricoMagazzino,
                idLotto: lotto,
                qta: quantity,
                note: state.notes
            }, {
                onSuccess: (res) => {res?.ok ? navigate('../fine') : navigate('../errore')},
                onError: () => navigate('../errore')
            });
        } else
            creaMovimentoPrsMutation.mutate({
                prs: state.prsList,
                idDeposito: state.targetWarehouse.idDeposito
            }, {
                onSuccess: (res) => {res?.ok ? navigate('../prs') : navigate('../errore')},
                onError: () => navigate('../errore')
            });
    }

    async function OnAggiornaSpedizione() {
        const aggiornaSpedizioneModel = {
            idSpedizione: state.aggiustaSpedizione.idImportSpedizione,
            quantity: state.aggiustaSpedizione.quantity,
            deposito: state.targetWarehouse
        }

        aggiornaSpedizioneMutation.mutate(aggiornaSpedizioneModel, {
            onSuccess: (res) => {res?.ok ? navigate('../correzione') : navigate('../errore')},
            onError: () => navigate('../errore')
        });
    }

    return (
        <TabletFrame
            titolo={_t.interfaccia.carica() + " / " + _t.tablet.riepilogo()}
            descrizione={_t.interfaccia.messaggiCarica.conferma()}
            onConfirm={state.isEdit? OnAggiornaSpedizione : OnCreaMovimento}
        >
            {state.manual && (
                <>
                    <TabletRiepilogoRow label={_t.prodotto.prodotto()} text={state.manual.name}></TabletRiepilogoRow>
                    <TabletRiepilogoRow label={_t.quantita.quantita()} text={state.manual.quantity}></TabletRiepilogoRow>
                    <TabletRiepilogoRow label={_t.lotto.lotto()} text={state.manual.lotto}></TabletRiepilogoRow>
                </>
            )}
            {state.prsList.length > 0 && (
                <ol className="container-grid">
                    {spedizioni
                        .filter((spedizione) =>  {
                            return state.prsList.some(
                                el => el.numeroProgressivo === spedizione.numeroProgressivo && el.lotto === spedizione.lotto
                            );
                        })
                        .map((spedizione) => (
                            <li
                                key={spedizione.id}
                                className="item-grid relative isolate text-md bg-neutral-50 mb-6 p-4"
                            >
                                <header className="w-fit">
                                    <p className="font-semibold mb-2 text-neutral-800">
                                        {spedizione.prodotto.prodotto}
                                    </p>
                                    <span className="text-neutral-800">
                                        {spedizione.lotto}
                                    </span>
                                </header>
                                <table className="contents">
                                    <tbody className="contents">
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            {_t.spedizione.casse()}
                                        </th>
                                        <td className="text-sm text-right">{spedizione.pezzi}</td>
                                    </tr>
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            {_t.spedizione.pezzi()}
                                        </th>
                                        <td className="text-sm text-right">{spedizione.casse}</td>
                                    </tr>
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            {_t.spedizione.numeroProgressivo()}
                                        </th>
                                        <td className="text-sm text-right">{spedizione.numeroProgressivo}</td>
                                    </tr>
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            {_t.spedizione.pesoLordo()}
                                        </th>
                                        <td className="text-sm text-right">{spedizione.pesoLordo}</td>

                                    </tr>
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            {_t.spedizione.pesoNetto()}
                                        </th>
                                        <td className="text-sm text-right">{spedizione.pesoNetto}</td>
                                    </tr>
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            {_t.spedizione.forme()}
                                        </th>
                                        <td className="text-sm text-right">{spedizione.pezzi * spedizione.casse}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </li>
                        ))}
                </ol>
            )}
            {!state.isEdit &&
                <>
                    <TabletRiepilogoRow label={_t.deposito.destinazione()} text={state.targetWarehouse.deposito} />
                    {state.prsList.length === 0 &&
                        <TabletRiepilogoRow label={_t.note.note()} text={state.notes || _t.errore.nonDefinito()} textCustomClasses={state.notes? '' : 'text-neutral-700'}/>
                    }
                </>
            }
            {state.isEdit &&
                <>
                    <TabletRiepilogoRow label={_t.prodotto.prodotto()} text={state.aggiustaSpedizione.prodotto} />
                    <TabletRiepilogoRow label={_t.quantita.quantita()} text={state.aggiustaSpedizione.quantity} />
                </>
            }
        </TabletFrame>
    );
}
