import React from 'react';
import {
    DocumentDuplicateIcon,
    CloudArrowUpIcon,
    MagnifyingGlassIcon,
    PlusIcon,
    CheckIcon,
    TrashIcon,
    ChevronDownIcon
} from '@heroicons/react/20/solid';
import {Menu as HUIMenu, Transition} from '@headlessui/react';
import * as Crud from '../lib/@crud';
import {useChangeTrackerHelper} from 'lib/hooks/use-change-tracker';
import Button from 'lib/components/button';
import {Spinner as SpinnerComponent} from 'lib/components/loading';
import Dialog from 'lib/components/dialog';
import {getPopInProps} from 'lib/helpers/transition';
import {isNotFalse} from 'lib/utils/boolean';
import {hasProperties} from 'lib/utils/object';
import {useOpenClose} from 'lib/hooks/use-boolean-state';
import {cn} from 'lib/helpers/class';
import {callAll} from 'lib/utils/function';

const Spinner = () => (
    <SpinnerComponent className="mr-1 h-4 w-4 translate-y-0.5" width={2} />
);

const getClass = (isMobile, destructive) => {
    if (!isMobile) return undefined;
    return cn(
        destructive
            ? 'text-red-700 hover:text-red-600'
            : 'text-inherit hover:text-primary-700',
        'flex'
    );
};

export function ControlButton(props) {
    const {destructive} = props;
    const isMobile = useIsMobile();
    const className = React.useMemo(
        () => getClass(isMobile, destructive),
        [isMobile, destructive]
    );
    return <Button size="xs" className={className} {...props} />;
}

function AddButton(props) {
    const isMobile = useIsMobile();
    const className = React.useMemo(() => getClass(isMobile), [isMobile]);
    const {routes} = Crud.useRouteParams();
    const [addRoute] = routes.create.split('/');
    const {to = `../${addRoute}`, ...rest} = props;

    return (
        <ControlButton to={to} className={className} {...rest}>
            <PlusIcon className="icon-sm mr-1" />
            {_t.bottone.nuovo()}
        </ControlButton>
    );
}

function SearchButton(props) {
    const {routes} = Crud.useRouteParams();
    const {to = `../${routes.search}`, ...rest} = props;

    return (
        <ControlButton to={to} {...rest}>
            <MagnifyingGlassIcon className="icon-sm mr-1" />
            {_t.bottone.cerca()}
        </ControlButton>
    );
}

export function SaveButton(props) {
    const {busy, form, showDidSave, ...rest} = props;

    return (
        <ControlButton
            busy={busy}
            loadAnimation={false}
            type="submit"
            form={form}
            {...rest}
        >
            {busy && <Spinner />}
            {!busy && (
                <React.Fragment>
                    {showDidSave ? (
                        <CheckIcon className="icon-sm mr-1" />
                    ) : (
                        <CloudArrowUpIcon className="icon-sm mr-1" />
                    )}
                </React.Fragment>
            )}
            {_t.bottone.salva()}
        </ControlButton>
    );
}

function CloneButton(props) {
    const {busy, ...rest} = props;
    return (
        <ControlButton busy={busy} loadAnimation={false} {...rest}>
            {busy && <Spinner />}
            {!busy && <DocumentDuplicateIcon className="icon-sm mr-1" />}
            {_t.bottone.clona()}
        </ControlButton>
    );
}

function DeleteButton(props) {
    const {onDelete, busy, onClick, ...rest} = props;
    const confirmDelete = useOpenClose();

    const onConfirm = () => {
        onDelete();
        confirmDelete.close();
    };

    return (
        <React.Fragment>
            <ControlButton
                destructive
                onClick={callAll(confirmDelete.open, onClick)}
                {...rest}
            >
                <TrashIcon className="icon-sm mr-1" />
                {_t.bottone.elimina()}
            </ControlButton>
            <Dialog.Full
                title={_t.bottone.confermaElimina()}
                description={_t.bottone.confermaEliminaDescrizione()}
                isOpen={confirmDelete.isOpen}
                onClose={confirmDelete.close}
            >
                <div className="flex flex-wrap items-baseline justify-end gap-2">
                    <Button destructive busy={busy} onClick={onConfirm}>
                        {_t.bottone.conferma()}
                    </Button>
                    <Button onClick={confirmDelete.close}>{_t.bottone.annulla()}</Button>
                </div>
            </Dialog.Full>
        </React.Fragment>
    );
}

/*function ChangeTrackerButton(props) {
    const {name} = Crud.useRouteParams();
    const {tableName = name, rowKey, onClick, ...rest} = props;
    const openCT = useChangeTrackerHelper(tableName, rowKey);
    if (!tableName) return null;

    return (
        <ControlButton
            type="ControlButton"
            title="ChangeTracker"
            onClick={callAll(openCT, onClick)}
            {...rest}
        >
            <img
                className="icon-sm"
                width="20"
                height="20"
                src="https://www.changetracker.it/413d82005450dd3a7e44.png"
             alt={'changeTracker'}/>
            <span className="ml-1 inline lg:hidden">{_t.bottone.changeTracker()}</span>
        </ControlButton>
    );
}*/

export function DetailButtons(props) {
    const {
        save,
        clone,
        delete: _delete,
        add,
        search,
        /*changeTracker,*/
        children,
        slideOver
    } = props;

    const {isUpdate} = Crud.useRouteParams();

    return (
        <React.Fragment>
            {isNotFalse(save) && <SaveButton {...save} />}
            <Menu>
                {isUpdate && hasProperties(clone) && <CloneButton {...clone} />}
                {isUpdate && isNotFalse(_delete) && <DeleteButton {..._delete} />}
                {isUpdate && isNotFalse(add) && !slideOver && <AddButton {...add} />}
                {isNotFalse(search) && !slideOver && <SearchButton {...search} />}
                {children}
               {/* {isNotFalse(changeTracker) && isUpdate && (
                    <ChangeTrackerButton {...changeTracker} />
                )}*/}
            </Menu>
        </React.Fragment>
    );
}

export function SearchButtons(props) {
    const {add, /*changeTracker,*/ children} = props;

    return (
        <Menu>
            {isNotFalse(add) && <AddButton {...add} />}
            {children}
            {/*{isNotFalse(changeTracker) && <ChangeTrackerButton {...changeTracker} />}*/}
        </Menu>
    );
}

const transition = getPopInProps();

const MobileContext = React.createContext();
MobileContext.displayName = 'MobileContext';
const useIsMobile = () => React.useContext(MobileContext) ?? false;

function Menu(props) {
    const {children} = props;

    return (
        <React.Fragment>
            <div className="hidden lg:contents">{children}</div>
            <div className="relative contents lg:hidden">
                <HUIMenu as="div" className="relative flex-shrink-0 text-neutral-600">
                    {({open}) => (
                        <MobileContext.Provider value={true}>
                            <HUIMenu.Button as={Button} look="primary" size="xs">
                                {_t.interfaccia.azioni()}
                                <ChevronDownIcon
                                    className={cn(
                                        open && '-scale-y-100',
                                        'icon -my-1 transition-all'
                                    )}
                                />
                            </HUIMenu.Button>
                            <Transition as={React.Fragment} {...transition}>
                                <HUIMenu.Items className="absolute right-0 z-10 mt-2 flex w-48 origin-top-right flex-col gap-2 rounded-md bg-white p-3 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {React.Children.map(children, (child, index) => {
                                        if (!child) return null;
                                        return (
                                            <HUIMenu.Button
                                                as={child.type}
                                                {...child.props}
                                                key={index}
                                            />
                                        );
                                    })}
                                </HUIMenu.Items>
                            </Transition>
                        </MobileContext.Provider>
                    )}
                </HUIMenu>
            </div>
        </React.Fragment>
    );
}
