import {jsonHeaders} from "#lib/helpers/fetch.js";
import toast from 'react-hot-toast';
import useMutation from "#lib/hooks/use-mutation.js";
import {getAuthHeader} from "#lib/hooks/use-auth.jsx";

export function useCreaMovimento() {
    return useMutation({
        mutationFn: async (body) => {
            const headers = await getAuthHeader();
            const response = await fetch(`${API_URL}/p/movimenti/create`, {
                method: 'POST',
                headers: {
                    ...jsonHeaders,
                    ...headers
                },
                body: JSON.stringify(body),

            });
            return await response.json();
        }
    });
}

export function useCreaMovimentoPrs() {
    return useMutation({
        mutationFn: async (body) => {
            const headers = await getAuthHeader();
            const response = await fetch(`${API_URL}/p/movimenti/createPrs`, {
                method: 'POST',
                headers: {
                    ...jsonHeaders,
                    ...headers
                },
                body: JSON.stringify(body),

            });
            return await response.json();
        }
    });
}

export function useSpostaForme() {
    return useMutation({
        mutationFn: async (body) => {
            const headers = await getAuthHeader();
            const response = await fetch(`${API_URL}/p/movimenti/moveForme`, {
                method: 'POST',
                headers: {
                    ...jsonHeaders,
                    ...headers
                },
                body: JSON.stringify(body),

            });
            return await response.json();
        }
    });
}

export function useSpostaBins() {
    return useMutation({
        mutationFn: async (body) => {
            const headers = await getAuthHeader();
            const response = await fetch(`${API_URL}/p/movimenti/moveBins`, {
                method: 'POST',
                headers: {
                    ...jsonHeaders,
                    ...headers
                },
                body: JSON.stringify(body),

            });
            return await response.json();
        }
    });
}

export function useStampaCartelloBins() {
    return useMutation({
        mutationFn: async (idMovimento) => {
            const headers = await getAuthHeader();
            const response = await fetch(`${API_URL}/p/movimenti/stampa-cartello-bins`, {
                method: 'POST',
                headers: {
                    ...jsonHeaders,
                    ...headers
                },
                body: JSON.stringify({idMovimento}),

            });
            const res = await response.json();
            if(res?.ok){
                const downloadLink = document.createElement('a');
                downloadLink.href = `data:application/pdf;base64,${res.base64}`;
                downloadLink.download = 'cartello.pdf';
                downloadLink.click();
            }else
                toast.error(res.errorText);

            return res;
        }
    });
}

export function useChiudiBins() {
    return useMutation({
        mutationFn: async (model) => {
            const headers = await getAuthHeader();
            const response = await fetch(`${API_URL}/p/movimenti/chiudi-bins`, {
                method: 'POST',
                headers: {
                    ...jsonHeaders,
                    ...headers
                },
                body: JSON.stringify(model),

            });
            return await response.json();
        }
    });
}

export function useCaricaInventario() {
    return useMutation({
        mutationFn: async (model) => {
            const headers = await getAuthHeader();
            const response = await fetch(`${API_URL}/p/movimenti/carica-inventario`, {
                method: 'POST',
                headers: {
                    ...jsonHeaders,
                    ...headers
                },
                body: JSON.stringify(model),

            });
            return await response.json();
        }
    });
}

export function useCaricaInventarioWebgate() {
    return useMutation({
        mutationFn: async (model) => {
            const headers = await getAuthHeader();
            const response = await fetch(`${API_URL}/p/movimenti/carica-inventario-webgate`, {
                method: 'POST',
                headers: {
                    ...jsonHeaders,
                    ...headers
                },
                body: JSON.stringify(model),

            });
            return await response.json();
        }
    });
}
