import React from 'react';
import {EyeIcon, EyeSlashIcon} from '@heroicons/react/20/solid';
import {getFirstDefined, isDefined} from '#lib/utils/undefined.js';
import {isFunction} from '#lib/utils/function.js';
import {mergeClasses, cn} from '#lib/helpers/class.js';

const Context = React.createContext();
Context.displayName = 'InputContext';

function useContext() {
    return React.useContext(Context);
}

function ContextProvider(props) {
    const {children, controlledHasError, controlledError, controlledShowPassword} = props;

    const [_error, setError] = React.useState(false);
    const error = controlledError ?? _error;

    const [_showPassword, setShowPassword] = React.useState(false);
    const showPassword = isDefined(controlledShowPassword)
        ? controlledShowPassword
        : _showPassword;

    const onInvalid = React.useCallback((event) => {
        const {validationMessage} = event.target;
        setError(validationMessage);
    }, []);

    const onChange = React.useCallback(() => {
        if (_error) setError(null);
    }, [_error]);

    const hasError = Boolean(getFirstDefined([controlledHasError, error, _error]));

    const inputId = React.useId();
    const helperId = React.useId();
    const errorMessageId = React.useId();

    const context = {
        error,
        hasError,
        showPassword,
        setShowPassword,
        onInvalid,
        onChange,
        inputId,
        helperId,
        errorMessageId
    };

    return <Context.Provider value={context}>{children}</Context.Provider>;
}

function WithContext(Element, props, ref) {
    const {
        hasError: controlledHasError,
        error: controlledError,
        showPassword: controlledShowPassword,
        ...rest
    } = props;
    return (
        <ContextProvider
            controlledHasError={controlledHasError}
            controlledError={controlledError}
            controlledShowPassword={controlledShowPassword}
        >
            <Element {...rest} ref={ref} />
        </ContextProvider>
    );
}

function GiveContext(Element) {
    return React.forwardRef(WithContext.bind(null, Element));
}

function ShowPassword(props) {
    const {onClick: providedOnClick, children, ...rest} = props;
    const {inputId, showPassword, setShowPassword} = useContext();

    const onClick = React.useCallback(
        (event) => {
            if (isFunction(providedOnClick)) providedOnClick(event);
            setShowPassword((p) => !p);
        },
        [providedOnClick, setShowPassword]
    );

    const renderProp = isFunction(children);

    return (
        <button
            {...rest}
            aria-controls={inputId}
            onClick={onClick}
            type="button"
            className="rounded-full p-2 text-neutral-500"
        >
            {renderProp && children(showPassword)}
            {!renderProp && (
                <React.Fragment>
                    {showPassword ? (
                        <React.Fragment>
                            <EyeSlashIcon className="icon-sm" />
                            <span className="sr-only">Nascondi</span>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <EyeIcon className="icon-sm" />
                            <span className="sr-only">Mostra</span>
                        </React.Fragment>
                    )}
                    {children}
                </React.Fragment>
            )}
        </button>
    );
}

function _Container(props) {
    const {as = 'div', className, children, type, ...rest} = props;
    const isHidden = type === 'hidden';
    return React.createElement(
        as,
        {
            className: isHidden ? 'hidden' : className,
            ...rest
        },
        children
    );
}

const Container = GiveContext(_Container);

function Label(props) {
    const {className, children, htmlFor: providedHtmlFor, ...rest} = props;
    const {inputId, hasError} = useContext() ?? {};
    const htmlFor = providedHtmlFor ?? inputId;
    return (
        <label
            className={mergeClasses(
                cn(
                    hasError ? 'text-red-800' : 'text-neutral-700',
                    'mb-1 flex items-baseline gap-2 whitespace-nowrap text-sm font-medium'
                ),
                className
            )}
            htmlFor={htmlFor}
            {...rest}
        >
            {children}
        </label>
    );
}

function Elements(props) {
    const {as = 'div', noBorder = false, className, children, ...rest} = props;

    const {hasError} = useContext();

    return React.createElement(
        as,
        {
            className: mergeClasses(
                cn(
                    'group relative input-box flex',
                    hasError && 'input-error',
                    noBorder && 'no-border'
                ),
                className
            ),
            ...rest
        },
        children
    );
}

function _Input(props, ref) {
    const {
        type: providedType = 'text',
        id: providedId,
        onInvalid: providedOnInvalid,
        onChange: providedOnChange,
        className,
        hasError: controlledHasError = false,
        noBorder,
        autoComplete,
        readOnly = false,
        ...rest
    } = props;

    const inputRef = React.useRef(null);
    React.useImperativeHandle(ref, () => inputRef.current);

    const context = useContext();
    const id = providedId ?? context?.inputId;
    const showPassword = context?.showPassword;
    const type = showPassword ? 'text' : providedType;

    React.useEffect(() => {
        if (providedType !== 'password') return;
        const input = inputRef.current;
        const form = input?.form;
        if (!input || !form) return;
        const onSubmit = () => {
            input.setAttribute('type', 'password');
        };
        form.addEventListener('submit', onSubmit);
        return () => {
            form.removeEventListener('submit', onSubmit);
        };
    }, [providedType]);

    const hasError = controlledHasError ?? context?.hasError;

    function onInvalid(event) {
        event.preventDefault();
        if (isFunction(providedOnInvalid)) providedOnInvalid(event);
        context?.onInvalid(event);
    }

    function onChange(event) {
        if (isFunction(providedOnChange)) providedOnChange(event);
        context?.onChange();
    }

    return (
        <input
            ref={inputRef}
            id={id}
            type={type}
            onChange={onChange}
            onInvalid={onInvalid}
            className={mergeClasses(
                cn(
                    'input-box input-inside-elements',
                    noBorder && 'no-border',
                    hasError && 'input-error',
                    readOnly && 'bg-gray-50'
                ),
                className
            )}
            readOnly={readOnly}
            aria-describedby={context.helperId}
            aria-errormessage={context.errorMessageId}
            {...rest}
            autoComplete={type === 'password' ? 'off' : autoComplete}
        />
    );
}

const Input = React.forwardRef(_Input);

function Helper(props) {
    const {as = 'p', className, id: providedId, children, ...rest} = props;
    const {helperId, hasError} = useContext();
    const id = providedId ?? helperId;

    const helper = React.createElement(
        as,
        {
            className: mergeClasses('mt-2 text-sm text-neutral-500', className),
            id,
            ...rest
        },
        children
    );

    return hasError ? null : helper;
}

function ErrorMessage(props) {
    const {as = 'p', className, id: providedId, children, ...rest} = props;
    const {errorMessageId} = useContext();
    const id = providedId ?? errorMessageId;

    return React.createElement(
        as,
        {
            className: mergeClasses('mt-1 text-sm text-red-800', className),
            id,
            ...rest
        },
        children
    );
}

function Leading(props) {
    const {as = 'div', className, children, ...rest} = props;
    return React.createElement(
        as,
        {
            className: mergeClasses('flex items-center pl-3', className),
            ...rest
        },
        children
    );
}

function Trailing(props) {
    const {as = 'div', className, children, ...rest} = props;
    return React.createElement(
        as,
        {
            className: mergeClasses('-m-[1px] flex items-center', className),
            ...rest
        },
        children
    );
}

function _Full(props, ref) {
    const {
        type,
        className,
        style,
        inputClassName,
        label,
        helper,
        noBorder = false,
        ...inputProps
    } = props;

    const {error} = useContext();

    return (
        <_Container className={className} style={style} type={type}>
            {label && <Label>{label}</Label>}
            <Elements noBorder={noBorder}>
                <Input ref={ref} type={type} className={inputClassName} {...inputProps} />
                {type === 'password' && (
                    <Trailing>
                        <ShowPassword />
                    </Trailing>
                )}
            </Elements>
            {helper && <Helper>{helper}</Helper>}
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </_Container>
    );
}

const Full = GiveContext(React.forwardRef(_Full));

Container.Label = Label;
Container.Elements = Elements;
Container.Input = Input;
Container.ErrorMessage = ErrorMessage;
Container.Helper = Helper;
Container.Leading = Leading;
Container.Trailing = Trailing;
Container.Full = Full;
export default Container;
