import React from 'react';
import {SORT_DIRECTIONS} from '#lib/constants';
import {useSessionStorageReducer} from '#lib/hooks/use-session-storage';

export const ACTIONS = {
    SET_FILTER: 'set-filter',
    SET_SORT_FIELD: 'set-sort-field',
    TOGGLE_SORT_DIRECTION: 'toggle-sort-direction'
};

export const defaultInitialParams = {
    sortField: '',
    sortDirection: ''
};

function useSearchActions(params, dispatch) {
    const setSearchField = React.useCallback(
        ({name, value}) => {
            dispatch({
                type: ACTIONS.SET_FILTER,
                payload: {name, value}
            });
        },
        [dispatch]
    );

    const setMultipleSearchFields = React.useCallback(
        (fields) => {
            dispatch({
                type: ACTIONS.SET_MULTIPLE_FILTERS,
                payload: fields
            });
        },
        [dispatch]
    );

    const changeSorting = React.useCallback(
        (selectedField) => {
            if (selectedField === params.sortField) {
                dispatch({type: ACTIONS.TOGGLE_SORT_DIRECTION});
            } else {
                dispatch({
                    type: ACTIONS.SET_SORT_FIELD,
                    payload: selectedField
                });
            }
        },
        [dispatch, params.sortField]
    );

    return {
        setSearchField,
        setMultipleSearchFields,
        changeSorting
    };
}

export function useSearch(reducer = searchReducer, initialParams = defaultInitialParams) {
    const [params, dispatch] = React.useReducer(reducer, initialParams);
    const actions = useSearchActions(params, dispatch);
    return {params, dispatch, ...actions};
}

export function useKeepPreviousSearch(
    key,
    reducer = searchReducer,
    initialParams = defaultInitialParams,
    clearOnReload = true
) {
    const [params, dispatch] = useSessionStorageReducer(
        key,
        reducer,
        initialParams,
        clearOnReload
    );
    const actions = useSearchActions(params, dispatch);
    return {params, dispatch, ...actions};
}

export function searchReducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_FILTER: {
            const {name, value} = action.payload;
            return {...state, [name]: value};
        }
        case ACTIONS.SET_MULTIPLE_FILTERS: {
            return {...state, ...action.payload};
        }
        case ACTIONS.SET_SORT_FIELD: {
            const newSortField = action.payload;
            if (state.sortField === newSortField) return;
            return {
                ...state,
                sortField: newSortField,
                sortDirection: SORT_DIRECTIONS.ASC
            };
        }
        case ACTIONS.TOGGLE_SORT_DIRECTION: {
            const newDirection =
                state.sortDirection === SORT_DIRECTIONS.ASC
                    ? SORT_DIRECTIONS.DESC
                    : SORT_DIRECTIONS.ASC;
            return {...state, sortDirection: newDirection};
        }
    }
}
