import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import useAuth from '#lib/hooks/use-auth.jsx';
import {isFunction} from '#lib/utils/function.js';
import {LoadingIndicator} from '#lib/components/loading.jsx';

export function useWasComingFrom() {
    const {AUTH_ROUTES} = useAuth();
    const location = useLocation();
    const from = location.state?.from;
    if (from && !Object.values(AUTH_ROUTES).includes(from)) return from;
    return null;
}

export function RequireAuth(props) {
    const {checkRouteAuth, children} = props;
    const {
        isAuthenticated,
        isLoading: isLoadingAuth,
        hasToChangePassword,
        user,
        meta,
        AUTH_ROUTES
    } = useAuth();
    const isLoadingMeta = meta?.isLoading;
    const isLoading = isLoadingAuth || isLoadingMeta;

    const location = useLocation();

    if (hasToChangePassword) {
        // Redirect them to the change-password page, but save the current location
        // they were trying to go to when they were redirected.
        return (
            <Navigate
                to={`/${AUTH_ROUTES.COMPLETE_NEW_PASSWORD}`}
                state={{from: location}}
                replace
            />
        );
    }

    if (!isAuthenticated) {
        // Redirect them to the sign-in page, but save the current location
        // they were trying to go to when they were redirected.
        return (
            <Navigate to={`/${AUTH_ROUTES.SIGN_IN}`} state={{from: location}} replace />
        );
    }

    const redirectRoute =
        !isLoading && isFunction(checkRouteAuth) && checkRouteAuth(user, meta, location);

    if (redirectRoute) return <Navigate to={redirectRoute} replace />;

    if (isLoading)
        return (
            <div className="flex min-h-screen w-full items-center justify-center">
                <LoadingIndicator />
            </div>
        );

    return children;
}

export const Private = (element, checkRouteAuth) => (
    <RequireAuth checkRouteAuth={checkRouteAuth}>{element}</RequireAuth>
);
