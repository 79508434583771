import React from 'react';
import {mergeClasses, addClasses} from '#lib/helpers/class.js';

export function Spinner(props) {
    const {as = 'span', className, style, width = 4, ...rest} = props;

    return React.createElement(
        as,
        {className: mergeClasses('h-6 w-6 block', className), ...rest},
        <span
            className="aspect-square w-full animate-spin rounded-full border-primary-200 border-t-primary-400 block"
            style={{...style, borderWidth: width}}
            {...rest}
        />
    );
}

function _BouncingDots(props) {
    const {
        numberOfDots = 3,
        width = numberOfDots * 30,
        height = 50,
        radius = 10,
        maxDelay = 330,
        className,
        ...rest
    } = props;

    let dots = new Array(numberOfDots);

    for (let i = 0; i < numberOfDots; i++) {
        dots[i] = (
            <circle
                key={`dot-${i}`}
                style={{
                    animationDelay: `${(maxDelay / numberOfDots) * i}ms`
                }}
                className="motion-safe:animate-bounce"
                cx={numberOfDots === 1 ? width / 2 : (width / 2 - radius) * i + radius}
                cy={height * 0.75}
                r={radius}
            />
        );
    }

    return (
        <svg
            viewBox={`0 0 ${width} ${height}`}
            preserveAspectRatio="xMidYMid meet"
            className={mergeClasses('fill-currentColor', className)}
            {...rest}
        >
            {dots}
        </svg>
    );
}

export const BouncingDots = React.memo(_BouncingDots);

export function LoadingIndicator(props) {
    const {className, children, ...rest} = props;

    return (
        <div className={mergeClasses('flex flex-col gap-2', className)} {...rest}>
            {children ?? <span className="sr-only">Caricamento</span>}
            <Spinner />
        </div>
    );
}

export function LoadingSkeleton(props) {
    const {className = addClasses('w-full em-h-2'), ...rest} = props;
    return (
        <span
            className={mergeClasses(
                'block animate-pulse rounded-md bg-neutral-200',
                className
            )}
            {...rest}
        />
    );
}
