import React from 'react';
import {Outlet} from 'react-router-dom';
import {Header, Navigation, Content, Container} from '#components/shell';
import UserMenu from '#components/user-menu';
import Logo from '#components/logo';
import NavBar from '#components/nav-bar';
import useNavigation from '#hooks/use-navigation';
import {useMenuActions} from '#hooks/use-menu-actions';
import useAuth from '#lib/hooks/use-auth.jsx';
import ManageControls from '#components/manage-controls.jsx';
import {includesAny} from "#lib/utils/array.js";
import {Ruoli} from "#root/src/constants.js";
import { useLocation } from 'react-router-dom'

export default function AppShell() {
    const navigation = useNavigation();
    const {isAuthenticated, meta} = useAuth();
    const isBackOfficeAdmin = includesAny(meta.data?.ruoli, [Ruoli.backOfficeAdmin]);
    const location = useLocation();
    const menuActions = useMenuActions();

    return (
        <Container>
            <ManageControls.Provider hideControls={!isBackOfficeAdmin || !location.pathname.includes("gestione")}>
                <Header>
                    <Navigation>
                        <Logo to="/home" altText={'SardaFormaggi'} url={`${MEDIA_URL}/logo.png`}/>
                        <NavBar navigation={{routes: navigation.routes}} />
                        {isAuthenticated && (
                            <div className="relative ml-auto flex items-center gap-2">
                                <h1 className="mr-1 font-semibold text-gray-700">
                                    {meta.data?.nome}
                                </h1>
                                <UserMenu actions={menuActions} />
                            </div>
                        )}
                    </Navigation>
                    <ManageControls/>
                </Header>
                <Content>
                    <Outlet />
                </Content>
            </ManageControls.Provider>
        </Container>
    );
}
