import React from 'react';
import {OPERATIONS} from '../@crud';

export const CrudRouteContext = React.createContext();
CrudRouteContext.displayName = 'CrudRouteContext';

export function CrudRoute(props) {
    const {operation, routes, name, children} = props;

    return (
        <CrudRouteContext.Provider value={{operation, routes, name}}>
            {children}
        </CrudRouteContext.Provider>
    );
}

export function useRouteParams() {
    const {operation, ...rest} = React.useContext(CrudRouteContext);
    const isCreate = operation === OPERATIONS.CREATE;
    const isUpdate = operation === OPERATIONS.UPDATE;
    const isDetail = isCreate || isUpdate;

    return {
        operation,
        isCreate,
        isUpdate,
        isDetail,
        ...rest
    };
}
