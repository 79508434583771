import React from 'react';
import {get, set} from 'lodash-es';
import useValidator from '#lib/hooks/use-validator.js';
import useWaitQueue from '#lib/hooks/use-wait-queue.js';
import {formToObject} from '#lib/utils/form.js';
import {isDefined} from '#lib/utils/undefined.js';
import {callAll, isFunction} from '#lib/utils/function.js';
import {getOnChangeValue} from '#lib/utils/event.js';
import {isUndefined} from '#lib/utils/undefined.js';

// TODO: Move to utils once we harmonize es modules into node
function setOnCopy(object, key, value) {
    const newObject = structuredClone(object);
    set(newObject, key, value);
    return newObject;
}

export const ACTIONS = {
    SET_INITIAL_DATA: 'set_initial_data',
    SET_VALUE: 'set_value',
    SET_MULTIPLE_VALUES: 'set_multiple_values',
    ADD_ENTRY: 'add_entry',
    REMOVE_ENTRY: 'remove_entry',
    GET_DERIVED_DATA: 'get_derived_data'
};

const STATUS = {
    LOADING: 'loading',
    SUCCESS: 'success'
};

export function formReducer(state, action) {
    const prevStatus = state.status;
    const prevData = state.data;

    switch (action.type) {
        case ACTIONS.SET_INITIAL_DATA: {
            const initialData = action.payload;
            return {status: STATUS.SUCCESS, data: initialData ?? {}};
        }
        case ACTIONS.SET_VALUE: {
            const {key, value} = action.payload;
            const newData = setOnCopy(prevData, key, value);
            return {
                status: prevStatus,
                data: newData
            };
        }
        case ACTIONS.SET_MULTIPLE_VALUES: {
            const entries = action.payload;
            const newData = structuredClone(prevData);
            for (const [key, value] of entries) {
                set(newData, key, value);
            }
            return {
                status: prevStatus,
                data: newData
            };
        }
        case ACTIONS.ADD_ENTRY: {
            const {key, model} = action.payload;
            const clonedModel = structuredClone(model);
            let list = get(prevData, key);
            if (isUndefined(list)) list = [];
            else if (!Array.isArray(list)) return {status: prevStatus, data: prevData};
            const newList = [...list, clonedModel];
            const newData = setOnCopy(prevData, key, newList);
            return {status: prevStatus, data: newData};
        }
        case ACTIONS.REMOVE_ENTRY: {
            const {key, index} = action.payload;
            let list = get(prevData, key);
            if (isUndefined(list)) list = [];
            else if (!Array.isArray(list)) return {status: prevData, data: prevData};
            const newList = list.slice();
            newList.splice(index, 1);
            const newData = setOnCopy(prevData, key, newList);
            return {status: prevStatus, data: newData};
        }
        case ACTIONS.GET_DERIVED_DATA: {
            const {extraData, merger} = action.payload;
            let newData;
            if (isFunction(merger)) {
                newData = merger(prevData, extraData);
                if (!newData) return {status: prevStatus, data: prevData};
            } else {
                newData = {...prevData, ...extraData};
            }
            if (newData === prevData) {
                console.warn(`[useForm] Your merger function must be pure`);
                return {status: prevStatus, data: prevData};
            }
            return {status: prevStatus, data: newData};
        }
        default:
            throw Error('Unknown action.');
    }
}

export function useForm({
    initialData,
    errors = {},
    reducer = formReducer,
    derivedData = {},
    alternativeActions = {},
    enableWhen = [],
    validation,
    middlewares = [],
    disabledFields,
    onSubmit: providedOnSubmit
} = {}) {
    const [state, dispatch] = React.useReducer(reducer, {
        status: STATUS.LOADING,
        data: {}
    });

    const {status, data} = state;

    React.useEffect(() => {
        if (isUndefined(initialData, true)) return;
        dispatch({type: ACTIONS.SET_INITIAL_DATA, payload: initialData});
    }, [status, initialData]);

    function handleChange(key, arg) {
        const value = getOnChangeValue(arg);
        dispatch({type: ACTIONS.SET_VALUE, payload: {key, value}});
    }

    const addEntry = React.useCallback((key, model = {}) => {
        dispatch({type: ACTIONS.ADD_ENTRY, payload: {key, model}});
    }, []);

    const removeEntry = React.useCallback((key, index) => {
        dispatch({type: ACTIONS.REMOVE_ENTRY, payload: {key, index}});
    }, []);

    function register(key, onChange) {
        const stateValue = get(state.data, key);
        return {
            name: key,
            value: isDefined(stateValue, true) ? stateValue : '',
            onChange: callAll(handleChange.bind(null, key), onChange),
            disabled: disabledFields?.includes(key),
            error: get(errors, key)
        };
    }

    function registerCheckbox(key, onChange) {
        const stateValue = get(state.data, key);
        return {
            name: key,
            checked: isUndefined(stateValue) ? false : stateValue,
            type: 'checkbox',
            onChange: callAll(handleChange.bind(null, key), onChange),
            disabled: disabledFields?.includes(key),
            error: get(errors, key)
        };
    }

    function registerRadio(key, value, onChange) {
        const stateValue = get(state.data, key);
        return {
            name: key,
            value,
            checked: stateValue === value,
            type: 'radio',
            onChange: callAll(handleChange.bind(null, key), onChange),
            disabled: disabledFields?.includes(key),
            error: get(errors, key)
        };
    }

    function initialize(key, defaultValue) {
        const stateValue = get(state.data, key);
        return {
            name: key,
            defaultValue: stateValue ?? defaultValue,
            disabled: disabledFields?.includes(key),
            error: get(errors, key)
        };
    }

    function initializeCheckbox(key, defaultChecked) {
        const stateValue = get(state.data, key);
        return {
            name: key,
            type: 'checkbox',
            defaultchecked: stateValue ?? defaultChecked,
            disabled: disabledFields?.includes(key),
            error: get(errors, key)
        };
    }

    function initializeRadio(key, value, defaultChecked) {
        const stateValue = get(state.data, key);
        return {
            name: key,
            value,
            type: 'radio',
            defaultChecked: stateValue === value || defaultChecked,
            disabled: disabledFields?.includes(key),
            error: get(errors, key)
        };
    }

    const wait = useWaitQueue();

    const derivedDataCallback = React.useCallback(async (key, fn, merger, ...args) => {
        wait.add(key);
        const extraData = await fn(...args);
        wait.remove(key);
        dispatch({type: ACTIONS.GET_DERIVED_DATA, payload: {extraData, merger}});
        // TODO: safelist known static methods for lib hooks
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const derive = React.useMemo(() => {
        let callbacks = {};
        for (const [key, options] of Object.entries(derivedData)) {
            const {fn, merger} = options;
            callbacks[key] = derivedDataCallback.bind(null, key, fn, merger);
        }
        return callbacks;
    }, [derivedData, derivedDataCallback]);

    const formId = React.useId();
    const formRef = React.useRef(null);

    const onSubmit = React.useCallback(
        async (event) => {
            const form = event.target;
            const rawData = formToObject(form);
            const data = await middlewares.reduce(chainMiddlewares, rawData);
            if (!isFunction(providedOnSubmit)) return;
            providedOnSubmit(data, form, event);
        },
        [middlewares, providedOnSubmit]
    );

    const validatorProps = useValidator({onSubmit}, validation);

    const formProps = {
        ref: formRef,
        id: formId,
        ...validatorProps
    };

    const submitProps = {
        form: formId,
        type: 'submit',
        disabled: !enableWhen.every(Boolean) || wait.queue.size
    };

    const isIdle = status === STATUS.IDLE;
    const isLoading = status === STATUS.LOADING;
    const isSuccess = status === STATUS.SUCCESS;

    let alternatives = {},
        altButtonProps = {};

    for (const [altKey, altFn] of Object.entries(alternativeActions)) {
        const form = formRef.current;
        if (!form) continue;
        const action = altFn.bind(null, form);
        alternatives[altKey] = action;
        const props = {'aria-controls': formId, onClick: action};
        altButtonProps[altKey] = props;
    }

    const props = {
        form: formProps,
        submit: submitProps,
        ...altButtonProps
    };

    return {
        data,
        status,
        isIdle,
        isLoading,
        isSuccess,
        dispatch,
        addEntry,
        removeEntry,
        register,
        registerCheckbox,
        registerRadio,
        initialize,
        initializeCheckbox,
        initializeRadio,
        derive,
        alternatives,
        props
    };
}

async function chainMiddlewares(accumulator, middleware) {
    const resolved = await Promise.resolve(accumulator);
    const newValue = await middleware(resolved);
    return newValue;
}
