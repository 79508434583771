import React, {useEffect, useRef} from 'react';
import {useForm} from '#lib/hooks/use-form.js';
import {useContext} from './index.jsx';
import {useNavigate} from 'react-router-dom';
import ErrorBoundary from '#lib/components/error-boundary.jsx';
import {ArrowLeftIcon, ArrowRightIcon} from '@heroicons/react/24/solid';
import {useSpedizioni} from "#hooks/use-spedizioni.js";
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import TabletSelect from "#root/src/lib/@tablet-template/tablet-select.jsx";

function getSpedizioniSelezionate(data) {
    let numeriProdotto = Object.keys(data);
    let npSelezionati = [];
    for (const np in numeriProdotto){
        if(data[numeriProdotto[np]] === true){
            npSelezionati.push(numeriProdotto[np]);
        }
    }
    return npSelezionati;
}

export default function Prs(props) {
    const {setState} = useContext();
    const {nascondi} = props;
    const {data, registerCheckbox} = useForm();
    const [date, setDate] = React.useState('');
    const [direction, setDirection] = React.useState('');
    const allowRefetch = useRef(0);

    const prs = useSpedizioni(nascondi, date, direction, allowRefetch.current);
    const dataDDT = prs?.dataDDT;

    useEffect(() => {
        if(dataDDT)
        setDate(dataDDT)
    }, [dataDDT]);

    const navigate = useNavigate();

    function onSubmit(event) {
        event.preventDefault();
        const selectedKeys = getSpedizioniSelezionate(data);
        const selectedPrs = selectedKeys.map(key => {
            const arr = key.split("|");
            return {numeroProgressivo: arr[0], lotto: arr[1]}
        });
        if (selectedPrs.length === 0)
            return;
        setState((p) => ({
            ...p,
            isManual: false,
            isEdit: false,
            prsList: selectedPrs,
            spedizioni: prs.data
        }));
        navigate('../destinazione');
    }

    async function changeDate(event) {
        setDirection('');
        const newDate = event.target.value;
        setDate(newDate);
        allowRefetch.current++;
    }

    async function scrollDay(add) {
        setDirection(add? 'FWD' : 'BACK');
        const current = new Date(date);
        const day = 24 * 60 * 60 * 1000;
        const newDate = add?
            new Date(current.getTime() + day).toISOString().slice(0, 10) :
            new Date(current.getTime() - day).toISOString().slice(0, 10);

        setDate(newDate);
        allowRefetch.current++;
    }

    function selectSpedizione(spedizione) {
        if (nascondi) {
            setState((p) => ({
                ...p,
                isEdit: true,
                aggiustaSpedizione: {
                    idImportSpedizione: spedizione.idImportSpedizione,
                    pezzi: spedizione.pezzi,
                    casse: spedizione.casse,
                    forme: spedizione.forme,
                    prodotto: spedizione.prodotto.prodotto
                }
            }));
            navigate('../correzione-quantita');
        }
    }

    return (
        <ErrorBoundary>
            <TabletFrame titolo={nascondi? _t.crud.carica.correzioneSpedizione() : _t.crud.carica.prs()}
                         descrizione={nascondi? _t.interfaccia.messaggiCarica.modificaPrs() : _t.interfaccia.messaggiCarica.prs()}
                         formId={!nascondi ? 'prs-form' : undefined}>
                <div className="flex">
                    <div onClick={() => scrollDay(false)} className=""><ArrowLeftIcon className="icon ml-4"/></div>
                    <input
                        type="date"
                        name="date"
                        value={date}
                        onChange={changeDate}
                        className="text-md mx-4 mb-6"
                    />
                    <div onClick={() => scrollDay(true)}><ArrowRightIcon className="icon"/></div>
                </div>

                {prs.isSuccess && (
                    <form onSubmit={onSubmit} id="prs-form">
                        <ol className='container-grid'>
                            {prs.data.map((spedizione) => (
                                <TabletSelect
                                    key={spedizione.numeroProgressivo + "|" + spedizione.lotto}
                                    chiave={spedizione.numeroProgressivo + "|" + spedizione.lotto}
                                    onClick={()=> selectSpedizione(spedizione)}
                                    headerTitle={spedizione?.prodotto?.prodotto || _t.prodotto.nonTrovato()}
                                    headerText={spedizione.lotto}
                                    isActive={data[spedizione.numeroProgressivo + "|" + spedizione.lotto]}
                                    isSingleSelect={nascondi}
                                    {...registerCheckbox(spedizione.numeroProgressivo + "|" + spedizione.lotto)}
                                >
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            {_t.spedizione.casse()}
                                        </th>
                                        <td className="text-sm text-right">{spedizione.casse}</td>
                                    </tr>
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            {_t.spedizione.pezzi()}
                                        </th>
                                        <td className="text-sm text-right">{spedizione.pezzi}</td>
                                    </tr>
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            {_t.spedizione.numeroProgressivo()}
                                        </th>
                                        <td className="text-sm text-right">{spedizione.numeroProgressivo}</td>
                                    </tr>
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            {_t.spedizione.pesoLordo()}
                                        </th>
                                        <td className="text-sm text-right">{spedizione.pesoLordo}</td>
                                    </tr>
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            {_t.spedizione.pesoNetto()}
                                        </th>
                                        <td className="text-sm text-right">{spedizione.pesoNetto}</td>
                                    </tr>
                                    <tr className="flex flex-col">
                                        <th className="font-normal text-neutral-800 text-sm text-right">
                                            {_t.spedizione.forme()}
                                        </th>
                                        <td className="text-sm text-right">{spedizione.forme}</td>
                                    </tr>
                                </TabletSelect>
                            ))}
                        </ol>
                    </form>
                )}
                {prs.data?.length === 0 &&
                    <h1 className="m-2">{_t.errore.nessunRisultato()}</h1>
                }
                {!prs.isSuccess &&
                    <h1 className="m-2">{_t.errore.impossibileCaricareRisultati()}</h1>
                }
            </TabletFrame>
        </ErrorBoundary>
    );
}
