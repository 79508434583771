import {fetchAndParse, jsonHeaders} from 'lib/helpers/fetch';
import {hasUrlPattern} from 'lib/utils/url';
import {isFunction} from 'lib/utils/function';
import {isNotFalse} from 'lib/utils/boolean';
import {isDefined} from 'lib/utils/undefined';
import {hasProperties, omit} from 'lib/utils/object';

export const HTTP_METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};

export const OPERATIONS = {
    GET: 'get',
    SEARCH: 'search',
    CREATE: 'create',
    UPDATE: 'update' // includes delete
};

// TODO: remove these
const prefixes = {
    private: 'p',
    public: 'o'
};

export function getOperationsEnabledStatus(readsPriority, crudOptions) {
    let status = {};
    let readOperation;
    for (const operation of readsPriority) {
        const operationOptions = crudOptions[operation];
        const enabled =
            isDefined(operationOptions) &&
            isNotFalse(operationOptions) &&
            hasProperties(operationOptions) &&
            isNotFalse(operationOptions.enabled);
        status[operation] = enabled;
        if (enabled && !readOperation) readOperation = operation;
    }
    if (!readOperation) readOperation = 'init';
    return [status, readOperation];
}

export async function fetchFn(
    makeUrl,
    method = HTTP_METHODS.GET,
    accessor = (data) => data,
    mutator = (args) => args,
    args,
    authHeaders
) {
    const headers = Object.assign(jsonHeaders, authHeaders);
    const body = JSON.stringify(mutator(args));
    const baseOptions = {headers, method};
    const isRead = method === HTTP_METHODS.GET;
    const options = isRead ? baseOptions : {...baseOptions, body};
    const url = makeUrl(args);
    const data = await fetchAndParse(url.href, options);
    return accessor(data);
}

/**
 * Given the endpoint and CRUD options, it returns a function that creates a URL based on those options and the (possible) arguments
 * @param {string | URL} endpoint the provided endpoint – could be a full URL like `https://<api-url>/p/resource` or just `"resource"`
 * @param {Object} options the options for the current operation
 * @param {Object} globalOptions the global CRUD options
 * @param {Boolean} isPrivate prefixes a `"/p"` or `"/o"` if it's true or false, respectively. If you provided a full URL as endpoint, it has no effect.
 * @returns
 */
export function makeUrl(endpoint, options, globalOptions, isPrivate = true) {
    return function (args) {
        const prefix = isPrivate ? prefixes.private : prefixes.public;

        const providedEndpoint = isFunction(endpoint)
            ? endpoint(args, options, globalOptions)
            : endpoint;

        const stringUrl = hasUrlPattern(providedEndpoint)
            ? providedEndpoint
            : `${API_URL}/${prefix}/${providedEndpoint ?? globalOptions?.endpoint}`;

        try {
            const url = new URL(stringUrl);
            const searchParams = options?.searchParams || {};
            for (const [key, value] of Object.entries(searchParams)) {
                if (value !== '') url.searchParams.set(key, value);
            }
            if (args?.pageParam) url.searchParams.set('pageParam', args.pageParam);
            return url;
        } catch {
            throw new Error(`${stringUrl} is not a valid url.`);
        }
    };
}

export function makeSearchKey(globalKey, options) {
    const relevantSearchParams = isDefined(options.searchParams)
        ? omit(options.searchParams, ['pageParam', 'pageSize'])
        : {};
    const formattedSearchParams = Object.entries(relevantSearchParams)
        .filter(([, value]) => value !== '')
        .map(([key, value]) => `${key}:${value}`);

    return [...globalKey, 'search', ...formattedSearchParams];
}

export function makeDetailKey(globalKey, options) {
    return [...globalKey, options.id ?? 'dettaglio'];
}
export function makeInitKey(globalKey) {
    return [...globalKey, 'init'];
}

export function updatePaginatedRow(oldData, rowId, updatedRowFields){
    let newData = structuredClone(oldData);

    for (let i = 0; i < newData.pages.length; i++){
        let find;
        for (let v = 0; v < newData.pages[i].results.length; v++)
            if(newData.pages[i].results[v].id === rowId){
                Object.assign(newData.pages[i].results[v], updatedRowFields);
                find = true;
                break;
            }
        if(find) break;
    }

    return newData;
}
