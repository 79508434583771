import {isFunction} from '#lib/utils/function.js';
import {isString} from '#lib/utils/string.js';

/**
 * @param {string} base Initial class
 * @param {string | function | null} provided Modifier. If it's a function, it will be called with `base` as its first argument. This way, you can edit the original `class` attribute, append other elements to it - or ignore it altogether
 * @returns
 */
export function mergeClasses(base, provided) {
    if (isFunction(provided)) return provided(base);
    else if (typeof provided === 'string') return provided;
    else if (provided === null) return null;
    else return base;
}

/**
 * Append classes on the base classes of the component using the function 'mergeClasses'.
 * @param {string} provided Classes to add
 * @returns
 */
export function addClasses(provided) {
    if (!isString(provided)) return (b) => `${b}`;
    return (b) => `${b} ${provided}`;
}

/**
 * Concatenate things like "class" or "className" attribute based on conditions.
 * Works like `classNames` pacakge.
 * @example
 * cn("secondary", "large", true && "focused", false && "active")
 * // returns "secondary large focused"
 * @param  {string[] | boolean[]} classes
 * @returns
 */
export function cn(...classes) {
    const filtered = classes.filter(Boolean);
    return filtered.length ? filtered.join(' ') : undefined;
}
