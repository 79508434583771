import React from 'react';
import {useQuery, useInfiniteQuery} from '@tanstack/react-query';
import {getAuthHeader} from '#lib/hooks/use-auth.jsx';

export * from '@tanstack/react-query';

function authorize(queryFn) {
    return async (args) => queryFn.call(this, args, await getAuthHeader());
}

export function useAuthQuery({queryFn, disableAuth = false, ...rest}) {
    return useQuery({
        queryFn: disableAuth ? queryFn : authorize(queryFn),
        ...rest
    });
}

export function useAuthInfiniteQuery({queryFn, disableAuth = false, ...rest}) {
    return useInfiniteQuery({
        queryFn: disableAuth ? queryFn : authorize(queryFn),
        ...rest
    });
}

export function useFreshAuthQuery(options) {
    return _useFreshQuery(useAuthQuery, options);
}

export function useFreshQuery(options) {
    return _useFreshQuery(useQuery, options);
}

function _useFreshQuery(queryHook, {expiresIn, lookAhead, ...options}) {
    const initialStaleTime = options?.staleTime ?? 0;
    const [staleTime, setStaleTime] = React.useState(initialStaleTime);
    const queryProps = queryHook({staleTime, ...options});
    const {data, isSuccess, isStale, refetch} = queryProps;
    React.useEffect(() => {
        if (!isSuccess) return;
        const newStaleTime = expiresIn(data) - lookAhead;
        setStaleTime(newStaleTime);
    }, [data, isSuccess, expiresIn, lookAhead]);

    React.useEffect(() => {
        if (isStale) refetch();
    }, [isStale, refetch]);

    return queryProps;
}
