import React from 'react';
import {mergeClasses} from '#lib/helpers/class.js';

export default function Grid(props) {
    const {as = 'div', className, children, ...rest} = props;

    return React.createElement(
        as,
        {
            className: mergeClasses(
                'grid content-start gap-3 grid-mw-cols-6 md:gap-4 md:grid-mw-cols-12',
                className
            ),
            ...rest
        },
        children
    );
}

const base = 'w-full col-start-1 col-span-6 md:col-start-auto';
const colMap = {
    1: 'md:col-span-1',
    2: 'md:col-span-2',
    3: 'md:col-span-3',
    4: 'md:col-span-4',
    5: 'md:col-span-5',
    6: 'md:col-span-6',
    7: 'md:col-span-7',
    8: 'md:col-span-8',
    9: 'md:col-span-9',
    10: 'md:col-span-10',
    11: 'md:col-span-11',
    12: 'md:col-span-12'
};
export const getColspan = (key) => `${base} ${colMap[key]}`;

function Slot(props) {
    const {as = 'div', span = 12, className, children, ...rest} = props;

    const gridClass = React.useMemo(() => getColspan(span), [span]);

    return React.createElement(
        as,
        {className: mergeClasses(gridClass, className), ...rest},
        children
    );
}

Grid.Slot = Slot;
