import React from 'react';
import {mergeClasses} from 'lib/helpers/class';

function _Panel(props, ref) {
    const {as = 'div', className, children, ...rest} = props;

    return React.createElement(
        as,
        {
            className: mergeClasses(
                'isolate flex flex-col pb-5 border-b md:rounded-lg bg-white p-2 shadow-md md:border border-neutral-200 md:gap-4 md:p-5 lg:p-6',
                className
            ),
            ref,
            ...rest
        },
        children
    );
}

const Panel = React.forwardRef(_Panel);
export default Panel;
