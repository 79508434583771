import React from 'react';
import {Link} from 'react-router-dom';
import {HomeIcon} from '@heroicons/react/24/solid';
import {cn} from '#lib/helpers/class.js';
import {undefineIf} from '#lib/utils/undefined.js';
import {ConditionalWrapper} from '#lib/components/conditional-render.jsx';

export default function BreadCrumbs(props) {
    const {hideRoot = false, rootUrl = '/', levels = [], ...rest} = props;
    const showRoot = !hideRoot;

    return (
        <nav aria-label="breadcrumb" className="w-full" {...rest}>
            <ol role="list" className="flex h-full items-center gap-4">
                {showRoot && (
                    <li className="contents">
                        <Link
                            to={rootUrl}
                            className="text-neutral-400 hover:text-neutral-500"
                        >
                            <HomeIcon
                                className="h-5 w-5 flex-shrink-0"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Home</span>
                        </Link>
                    </li>
                )}
                {levels.map((level, index) => (
                    <li className="contents" key={`${level.name}-${index}`}>
                        <Level
                            showRoot={showRoot}
                            isFirstLevel={index === 0}
                            isLastLevel={index + 1 === levels.length}
                            {...level}
                        />
                    </li>
                ))}
            </ol>
        </nav>
    );
}

function Level(props) {
    const {name, to, current, isFirstLevel, isLastLevel, showRoot} = props;
    const isCurrentLevel = current || isLastLevel;

    return (
        <div
            className={cn(
                'flex min-w-[5ch] max-w-max flex-grow items-center text-neutral-400',
                isLastLevel ? 'w-auto' : 'w-0'
            )}
        >
            {(!isFirstLevel || showRoot) && '/'}
            <ConditionalWrapper
                element={['span', Link]}
                condition={isCurrentLevel}
                to={undefineIf(to, isCurrentLevel)}
                className={cn(
                    (!isFirstLevel || showRoot) && 'ml-4',
                    !isCurrentLevel && 'hover:text-neutral-700',
                    'w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-neutral-500'
                )}
                aria-current={isCurrentLevel ? 'page' : undefined}
            >
                {name}
            </ConditionalWrapper>
        </div>
    );
}
