import React from 'react';
import {Action, List} from '#components/actions.jsx';
import {useContext} from './index.jsx';
import {useNavigate} from 'react-router-dom';
import {useProdottoCreato} from '#hooks/use-lavorazione.js';
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';

export default function Prodotto() {
    const {lavorazione, setLavorazione} = useContext();
    const {fase} = lavorazione;

    const navigate = useNavigate();
    const prodotti = useProdottoCreato(fase?.idFase);

    function selectProdotto(prodotto) {
        if (prodotto.segno > 0) {
            setLavorazione((p) => ({
                ...p,
                prodotto
            }));
            navigate('../selezione-lotto');
        }
    }

    return (
        <TabletFrame titolo={_t.crud.lavorazione.lavorazione() + " / " + _t.prodotto.prodotto()} descrizione={_t.interfaccia.messaggiLavorazione.prodotto()}>
            {prodotti.isSuccess &&
                <List>
                    {prodotti.data.map((prodotto) => (
                        <>{prodotto.segno > 0 &&
                            <List.Item key={prodotto.id} >
                                <Action
                                    as="button"
                                    onClick={() => selectProdotto(prodotto)}
                                >
                                    <Action.Label>
                                        {prodotto.prodotto}
                                    </Action.Label>
                                </Action>
                            </List.Item>
                        }</>
                    ))}
                </List>
            }
            {prodotti.data?.length === 0 &&
                <h1 className="m-2">{_t.errore.nessunRisultato()}</h1>
            }
            {!prodotti.isSuccess &&
                <h1 className="m-2">{_t.errore.impossibileCaricareRisultati()}</h1>
            }
        </TabletFrame>
    );
}
