import React from "react";
import Grid from 'lib/components/grid.jsx';
import Input from 'lib/components/input.jsx';
import Select from 'lib/components/select.jsx';
import DesktopFrame from "#root/src/lib/@desktop-template/desktop-frame.jsx";
import useManageCrud from "#@wizard/use-manage.js";
import SearchResults from "#components/search-results.jsx";
import {makePaginatedTableColumns} from "#helpers/table.jsx";
import {capitalize} from "lodash-es";
import SearchPanel from "#components/search-panel.jsx";
import {useDropdown} from '#hooks/use-dropdown.js';
import ManageControls from "#components/manage-controls.jsx";
import BreadCrumbs from "#src/components/breadcrumbs.jsx";
import {SearchButtons} from "#components/control-buttons.jsx";
import {SORT_DIRECTIONS} from "#lib/constants.js";

export default function MovimentiSearch() {
    const dropdowns = useDropdown(['depositi']);

    const [dateMin, setDateMin] = React.useState();
    const [dateMax, setDateMax] = React.useState();

    const {
        data,
        stateFlags,
        searchParams,
        changeSorting,
        paginationProps,
        getSearchFieldProps,
        setMultipleSearchFields
    } = useManageCrud(
        'movimento',
        {},
    {search: {
            initialSortField: 'dataMovimento',
            initialSortDirection: SORT_DIRECTIONS.DESC
        }
    });

    const porzionatoOptions = [
        {
            id: '',
            text: 'Seleziona opzione'
        },
        {
            id: 'false',
            text: 'Forme'
        },
        {
            id: 'true',
            text: 'Porzionati'
        },
    ];

    const columnProps = makePaginatedTableColumns(
        data,
        {
            searchParams,
            changeSorting
        },
        {
            omit: ['id', 'note', 'segno', 'idUbicazione', 'ubicazione', 'idProdotto'],
            headerText: (accessor) => {
                if(accessor === 'idLotto') return _t.lotto.lotto();
                if(accessor === 'dataMovimento') return _t.crud.movimenti.dataMovimento();
                return capitalize(accessor);
            }
        }
    );

    columnProps.columns = columnProps.columns.map((c) => {
        if (c.accessor === 'prodotto') {
            c.Cell = function renderCell({row}) {
                return (
                    <div>
                        {row.prodotto} {row.idProdotto?.includes('SL')? ' SL ' : ''}
                        <p className="text-xs text-neutral-600">
                            {row.note}
                        </p>
                    </div>
                );
            };
        } else if (c.accessor === 'dataMovimento') {
            c.Cell = function renderCell({row}) {
                return (
                    <div>
                        {new Date(row.dataMovimento).toLocaleString()}
                    </div>
                );
            };
        } else if (c.accessor === 'qta') {
            c.Cell = function renderCell({row}) {
                return (
                    <div className="text-right w-full">
                        {(row.qta * row.segno).toLocaleString()}
                    </div>
                );
            };
        } else if (c.accessor === 'tag') {
            c.Cell = function renderCell({row}) {
                if (!row.tag) return;
                const segments = row.tag.split('#');
                segments.shift();

                return (
                    <div>
                        {segments.map((s, i) => (
                            <span key={i} className={i > 15? "font-bold tag-palette-16" : "font-bold tag-palette-" + (i + 1)}>#{s}</span>
                        ))}
                    </div>
                );
            };
        }

        return c;
    });

    columnProps.templateColumns = 'auto auto 10% auto auto auto auto min-content';

    function changeDateMin(event) {
        const newDate = event.target.value;
        setDateMin(newDate);
    }

    function changeDateMax(event) {
        const newDate = event.target.value;
        setDateMax(newDate);
    }

    return (
        <DesktopFrame>
            <ManageControls.BarSlot>
                <BreadCrumbs customName={_t.crud.movimenti.movimenti()}/>
            </ManageControls.BarSlot>
            <ManageControls.ButtonSlot>
                <SearchButtons/>
            </ManageControls.ButtonSlot>
            <SearchPanel
                onSubmit={setMultipleSearchFields}
                onChange={setMultipleSearchFields}
                searchParams={searchParams}
            >
                <Grid>
                    <Grid.Slot span={2}>
                        <Input.Full
                            placeholder={_t.prodotto.prodotto()}
                            name="prodotto"
                            type="search"
                            label={_t.prodotto.prodotto()}
                        />
                    </Grid.Slot>
                    <Grid.Slot span={2}>
                        <Select.Full
                            className="flex-grow"
                            label={_t.prodotto.tipo()}
                            {...getSearchFieldProps('porzionato')}
                            options={porzionatoOptions}
                            accessValue={(option) => option.id}
                            displayValue={(id) => porzionatoOptions.find(c => c.id === id)?.text || ''}
                            nullable="true"
                            onChange={(e) => {
                                searchParams.porzionato = e;
                                setMultipleSearchFields();
                            }}
                        />
                    </Grid.Slot>
                    <Grid.Slot span={2}>
                        <Select.Full
                            style={{flexGrow: 0}}
                            label={_t.deposito.deposito()}
                            {...getSearchFieldProps('depositi')}
                            options={[
                                {id: '', text: 'Seleziona opzione'},
                                ...dropdowns.data.depositi.filter(d => !['SPE1', 'GRA1'].includes(d.id))
                            ]}
                            accessValue={(option) => option.id}
                            displayValue={(selectedId, options) => (
                                options.find((el) => el.id === selectedId)?.text
                            )}
                            onChange={(e) => {
                                searchParams.depositi = e;
                                setMultipleSearchFields();
                            }}
                        />
                    </Grid.Slot>
                    <Grid.Slot span={2}>
                        <Input.Full
                            placeholder={_t.lotto.lotto()}
                            name="lotto"
                            type="search"
                            label={_t.lotto.lotto()}
                        />
                    </Grid.Slot>
                    <Grid.Slot span={1}>
                        <Input.Full
                            placeholder={_t.crud.movimenti.tag()}
                            name="tag"
                            type="search"
                            label={_t.crud.movimenti.tag()}
                        />
                    </Grid.Slot>
                    <Grid.Slot span={1}>
                        <Input.Full
                            type="date"
                            defaultValue={dateMin}
                            onChange={changeDateMin}
                            label={_t.bottone.dataInizio()}
                            name={"dateMin"}
                        />
                    </Grid.Slot>
                    <Grid.Slot span={1}>
                        <Input.Full
                            type="date"
                            defaultValue={dateMax}
                            onChange={changeDateMax}
                            label={_t.bottone.dataFine()}
                            name={"dateMax"}
                        />
                    </Grid.Slot>
                </Grid>
            </SearchPanel>

            <SearchResults
                data={data}
                {...stateFlags}
                {...paginationProps}
                {...columnProps}
                paginated
                searchdirection={SORT_DIRECTIONS.DESC}
            />
        </DesktopFrame>
    );
}

