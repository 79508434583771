import React from "react";
import {useNavigate} from 'react-router-dom';
import {useContext} from "#routes/trasferisci/index.jsx";
import {List} from "#components/actions.jsx";
import {useGiacenzeDeposito} from "#hooks/use-giacenze.js";
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';

export default function ProdottiDeposito() {
    const {state, setState} = useContext();
    const navigate = useNavigate();
    const movimenti = useGiacenzeDeposito(state.sourceWarehouse, state.porzionato);

    function selectWarehouseProduct(warehouseProduct) {
        if (warehouseProduct.idUbicazione) {
            setState((p) => ({
                ...p,
                warehouseProduct: warehouseProduct,
                numeroForme: warehouseProduct.qta
            }));

            navigate('../deposito-destinazione');
        } else {
            setState((p) => ({
                ...p,
                warehouseProduct: warehouseProduct
            }));

            navigate('../quantita');
        }
    }

    return (
        <TabletFrame titolo={_t.crud.trasferisci.forme() + " / " + _t.prodotto.prodotto()} descrizione={_t.interfaccia.messaggiTrasferisci.prodotto()}>
            {movimenti.isSuccess && (
                <List>
                    {movimenti.data.length > 0 && movimenti.data.map((movimento) => (
                        <List.Item key={movimento.idlotto + movimento.prodotto.idprodotto} className="contents">
                            <div onClick={() => selectWarehouseProduct(movimento)} className="bg-neutral-50 p-2">
                                <input
                                    type="radio"
                                    className="absolute inset-0 opacity-0"
                                    id={movimento.idlotto + movimento.prodotto.idprodotto}
                                />
                                <header className="w-full flex justify-between">
                                    <p className="font-semibold mb-2 text-neutral-800">
                                        {movimento.prodotto.prodotto} {movimento.prodotto.idprodotto.includes('SL')? ' - SL' : ''} {movimento.ubicazione?.ubicazione && ' | ' + movimento.ubicazione?.ubicazione}
                                        <p className="text-neutral-800">{movimento.idlotto} <span className="text-neutral-800">{movimento.tag && " | " + movimento.tag}</span></p>
                                    </p>
                                    <table className="contents">
                                        <tbody className="contents">
                                        <tr className="flex flex-col">
                                            <th className="font-normal text-neutral-800 text-sm">
                                                Quantità
                                            </th>
                                            <td className="font-normal text-neutral-800  text-sm text-right">{movimento.qta}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </header>
                            </div>
                        </List.Item>
                    ))}
                    {movimenti.data.length <= 0 &&
                        <h1 className="m-2">{_t.errore.nessunRisultato()}</h1>
                    }
                </List>
            )}
        </TabletFrame>
    );
}
