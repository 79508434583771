import React from 'react';
import Table from 'lib/components/table';
import {capitalize} from 'lib/utils/string';
import {EditButton} from '../components/row-buttons';
import {defineDefaults} from 'lib/helpers/props';

const defaultColumnOptions = {
    omit: ['id'],
    sortable: true, // boolean or array
    toDetail: (row) => `../edit/${row.id}`,
    headerText: (accessor) => capitalize(accessor)
};

export function makePaginatedTableColumns(data, searchProps, options = {}) {
    const {searchParams, changeSorting} = searchProps;
    defineDefaults(options, defaultColumnOptions);
    const {omit, sortable, toDetail, headerText} = options;

    const datum = data?.pages.at(0).results.at(0);

    let columns = [];
    let templateColumns = [];

    if (!datum) return {columns, templateColumns: ''};

    if (toDetail) {
        columns.push({
            id: 'detail',
            Cell: ({row}) => <EditButton to={toDetail(row)} />,
            width: 'min-content'
        });

        templateColumns.push('min-content');
    }

    Object.keys(datum).forEach((accessor) => {
        if (Array.isArray(omit) && omit.includes(accessor)) return;

        const isSortable =
            sortable === true || (Array.isArray(sortable) && sortable.includes(accessor));

        let column = {
            Header: headerText(accessor),
            as: isSortable ? Table.SortableHeader : Table.Header,
            accessor,
            sortable: isSortable
        };

        if (isSortable) {
            column.props = {
                sortDirection: searchParams?.sortDirection,
                isSorting: accessor === searchParams?.sortField,
                onSort: () => changeSorting(accessor)
            };
        }

        columns.push(column);
        templateColumns.push('auto');
    });

    return {columns, templateColumns: templateColumns.join(' ')};
}
