import {cn} from "#lib/helpers/class.js";
import React from "react";
import {CheckIcon} from "@heroicons/react/24/solid/index.js";

export default function TabletSelect(props) {
    const {chiave, onClick, headerTitle, headerText, children, isActive, isSingleSelect = false, emptySlots = 0, ...rest} = props;
    const slotsArray = [...Array(emptySlots).keys()];

    return (
        <li className="contents w-full">
            <label
                onClick={onClick}
                htmlFor={chiave}
                className={cn(
                    'item-grid',
                    isActive
                        ? 'opacity-60'
                        : 'opacity-100',
                    "p-4 gap-4 relative isolate text-md bg-neutral-50 mb-6"
                )}
            >
                {isSingleSelect ?
                    <input
                        type="radio"
                        className="absolute inset-0 opacity-0"
                        id={chiave}
                    /> :
                    <input
                        type="radio"
                        className="absolute inset-0 opacity-0"
                        id={chiave}
                        {...rest}
                    />
                }

                <header>
                    <p className="font-semibold mb-2 text-neutral-800">
                        {headerTitle}
                    </p>
                    <span className="text-neutral-800">
                        {headerText}
                    </span>
                </header>

                <table className="contents">
                    <tbody className="contents">
                        {slotsArray.map((s) => (
                            <tr key={s}></tr>
                        ))}
                        {children}
                    </tbody>
                </table>

                <span
                    className={cn(
                        isActive
                            ? 'opacity-100'
                            : 'opacity-0',
                        ["bg-neutral-950 aspect-square p-2 self-center absolute right-4 bottom-4"]
                    )}
                >
                    <CheckIcon
                        className="text-white icon"
                    />
                </span>
            </label>
        </li>
    );
}