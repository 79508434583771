import { isFunction } from "lib/utils/function";
import { get } from "lib/utils/object";
import { isString } from "lib/utils/string";

const maxLevels = 10;
const makeEmptyPath = () => new Array(maxLevels).fill(undefined);
const isDev = process.env.NODE_ENV === 'development';

const access = () => {
    let path = makeEmptyPath();
    let currIndex = 0;

    const add = (newProp) => {
        path[currIndex] = newProp;
        currIndex++;
        return { path, index: currIndex };
    };

    const close = () => {
        const lastPath = path;
        const lastIndex = currIndex;
        path = makeEmptyPath();
        currIndex = 0;
        return { path: lastPath, index: lastIndex };
    };

    return {
        add,
        close,
    };
};

function parse(string, params) {
    const regexp = /%([0-9]+)\$/g;
    let match;
    while ((match = regexp.exec(string))) {
        const index = match[1] - 1;
        const param = params[index] ?? "";
        string = string.replace(match[0], param);
    }
    return string;
}

function getGroupIndex(node) {
    const index = Number(node._index);

    if (typeof index !== "number") {
        if (isDev)
            throw new Error(
                `Intl, getGroupIndex, invalid index ${index} at node ${node}`
            );
        return "";
    }
    return index;
}

function getPlural(node, language, params) {
    const pluralRules = new Intl.PluralRules(language);

    const index = getGroupIndex(node);
    const param = Number(params[index - 1]);

    if (typeof param !== "number" || isNaN(param)) {
        if (isDev)
            throw new Error(
                `Intl, getSelection, missing or invalid param ${index} at node ${node}`
            );
        return "";
    }

    const key = pluralRules.select(param);
    const pluralNode = node[key];

    if (!pluralNode) return "";

    return format(pluralNode, language, ...params);
}

function getSelection(node, language, params) {
    const index = getGroupIndex(node);
    const param = params[index - 1];
    if (typeof param === 'undefined' ) {
        if (isDev)
            throw new Error(`Intl, getSelection, missing param ${index}`);
        return "";
    }

    const selectedNode = node[param];

    if (!selectedNode) {
        if (isDev)
            throw new Error(
                `Intl, getSelection, missing selection for param ${param}`
            );
        return "";
    }

    return format(selectedNode, language, ...params);
}

function format(string, language, ...params) {
    try {
        switch (string._type) {
            case "plurals":
                string = getPlural(string, language, params);
                break;
            case "select":
                string = getSelection(string, language, params);
                break;
        }
        if (!isString(string)) {
            if (isDev) throw new Error(`Text not found for key ${string}`);
            return "**text not found**";
        }

        return parse(string, params);
    } catch (error) {
        if (isDev) throw new Error(error);
        return "**text not found**";
    }
}

const localStoragePrefix = "abs-texts";

function getLocalStorage(key) {
    try {
        const existing = localStorage.getItem(key);
        return JSON.parse(existing);
    } catch (error) {
        console.error(error);
    }
}

function setLocalStorage(key, data) {
    try {
        const updated = JSON.stringify(data);
        localStorage.setItem(key, updated);
    } catch (error) {
        console.error(error);
    }
}

async function fetchTexts(language) {
    return fetch(`${INTL_URL}/texts-${language}.json`)
        .then((response) => response.json())
        .catch((error) => {
            console.error(error);
        });
}

const languageKey = "language";

const traverse = access();
const emptyFn = () => ({});

export default function intl(options) {
    const { onSetup, onSuccess, setGlobal = true } = options;
    const [preferredLanguage] = navigator.language.split("-");

    let language = getLocalStorage(languageKey) ?? preferredLanguage;
    let texts;

    async function setTexts() {
        const textsKey = `${localStoragePrefix}-${language}`;
        texts = getLocalStorage(textsKey);
        if (!texts && isFunction(onSetup)) onSetup();
        texts = await fetchTexts(language);
        if (texts) {
            setLocalStorage(textsKey, texts);
            if (isFunction(onSuccess)) onSuccess(texts);
        }
    }

    setTexts();

    function changeLanguage(newLanguage) {
        language = newLanguage;
        setLocalStorage(languageKey, newLanguage);
        setTexts();
    }

    const _t = new Proxy(emptyFn, {
        get(_, key) {
            traverse.add(key);
            return _t;
        },
        apply(_, thisArg, args) {
            const { path, index } = traverse.close();
            let string = get(texts, path);

            if (!string) {
                if (isDev) {
                    const readablePath = path.filter(Boolean).join(".");
                    //throw new Error(`String not found for ${readablePath}`);
                }
                return path[index - 1];
            }

            if (args) return format(string, language, ...args);
            return string;
        },
    });

    if (setGlobal) {
        window._t = _t;
        return { changeLanguage };
    }

    return {
        _t,
        changeLanguage,
    };
}

/*
async function fakeFetchTexts(language) {
    const localeData = {
        language: language,
        strings: {
            test: 'test',
            welcome: 'Welcome %1$s!',
            delete: 'Delete',
            domain: {
                tables: {
                    deleteDomainTable: 'Delete this domain and all connected tables.'
                }
            },
            actions: {
                delete: 'Delete',
                save: 'Salva'
            },
            isPlurals: {
                _type: 'plurals',
                _index: 1,
                one: '%1$ year old!',
                other: '%1$ years old'
            },
            isPluralsMulti: {
                _type: 'plurals',
                _index: 1,
                one: '%2$ has %1$ year old!',
                other: '%2$ has %1$ years old'
            },
            isOldGenderAlt: {
                _type: 'select',
                _index: 1,
                male: {
                    _type: 'plurals',
                    _index: 2,
                    one: '%3$ is handsome and has %2$ year old!',
                    other: '%3$ is handsome and has has %2$ years old'
                },
                female: {
                    _type: 'plurals',
                    _index: 2,
                    one: '%1$ is beautiful and has %2$ year old!',
                    other: '%1$ is beautiful and has has %2$ years old'
                },
                other: {
                    _type: 'plurals',
                    _index: 2,
                    one: '%1$ is gorgeous and has %2$ year old!',
                    other: '%1$ is gorgeous and has %2$ years old'
                }
            },
            isMelePere: {
                _type: 'plurals',
                _index: 2,
                one: {
                    _type: 'plurals',
                    _index: 3,
                    one: '%1$ ha %2$ mela e %3$ pera',
                    other: '%1$ ha %2$ mela e %3$ pere'
                },
                many: {
                    _type: 'plurals',
                    _index: 3,
                    one: '%1$ ha %2$ mele e %3$ pera',
                    other: '%1$ ha %2$ mele e %3$ pere'
                }
            }
        }
    };
    return localeData;
}
*/
