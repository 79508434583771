import React from 'react';
import {Action, List} from '#components/actions.jsx';
import {useContext} from './index.jsx';
import {useNavigate} from 'react-router-dom';
import {useDepositi} from "#hooks/use-depositi.js";
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';

export default function Deposito(props) {
    const {direction, filter} = props;
    const warehouses = useDepositi(direction === "sorgente", filter);

    const {setState} = useContext();
    const navigate = useNavigate();
    function selectWarehouse(warehouse) {
        if (direction === "sorgente") {
            setState((p) => ({
                ...p,
                sourceWarehouse: warehouse.deposito,
                sourceWarehouseId: warehouse.idDeposito,
                porzionato: filter === "bins"
            }));
            if(filter === "forme")
                navigate("../forme/prodotti-deposito");
            else
                navigate("../bins/seleziona-bins");
        } else {
            setState((p) => ({
                ...p,
                targetWarehouse: warehouse.deposito,
                targetWarehouseId: warehouse.idDeposito
            }));
            navigate("../conferma");
        }
    }

    return (
        <TabletFrame titolo={direction === 'sorgente' ? _t.deposito.sorgente() : _t.deposito.destinazione()}
        descrizione={direction === 'sorgente' ? _t.interfaccia.messaggiTrasferisci.depositoSorgente() : _t.interfaccia.messaggiTrasferisci.depositoDestinazione()}>
            {warehouses.isSuccess &&
                <List>
                    {warehouses.data.map((warehouse) => (
                        <>
                        {(warehouse.qtaInDeposito === undefined || warehouse.qtaInDeposito > 0) &&
                            <List.Item key={warehouse.idDeposito}>
                                <Action
                                    as="button"
                                    onClick={() => selectWarehouse(warehouse)}
                                >
                                    <Action.Label>{warehouse.deposito}</Action.Label>
                                </Action>
                            </List.Item>
                        }
                        </>
                    ))}
                </List>
            }
        </TabletFrame>
    );
}
