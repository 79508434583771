
export const UnitaMisura = {
    gr: 'g',
    kg: 'KG'
};

export const Causali = {
    caricoMagazzino: 'MOVC',
    caricoBins: 'BINSC'
};

export const Ruoli = {
    admin: 'Admin',
    backOfficeAdmin: 'BackOfficeAdmin',
    magazzino: 'Magazzino'
};

export const DefaultPassword = '01SardaFormaggi02.';
