import React from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {Action, List} from "#components/actions.jsx";
import {useProdotti} from "#hooks/use-prodotti.js";
import Lotto from '#routes/carica/bins/lotto.jsx';
import Quantita from '#routes/carica/bins/quantita.jsx';
import Deposito from '#routes/carica/bins/deposito.jsx';
import Conferma from '#routes/carica/bins/conferma.jsx';
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import TabletOk from '#root/src/lib/@tablet-template/tablet-ok.jsx';
import TabletErrore from '#root/src/lib/@tablet-template/tablet-errore.jsx';

const Context = React.createContext();
export const useContext = () => React.useContext(Context);

function Start() {
    const {setCaricaBins} = useContext();
    const products = useProdotti({porzionato: "true"});
    const navigate = useNavigate();

    function selectProduct(product) {
        setCaricaBins((p) => ({
            ...p,
            prodotto: {
                idProdotto: product.id,
                name: product.prodotto,
                codice: product.codice,
                idUm: product.idUm,
                peso: product.peso
            }
        }));
        navigate('quantita');
    }

    return (
        <TabletFrame titolo={_t.crud.carica.bins() + " / " + _t.prodotto.scegli()} descrizione={_t.interfaccia.messaggiCarica.prodotto()}>
            <List>
                {products.isSuccess && products.data?.length > 0 &&
                    products.data.map((product) => (
                        <List.Item key={products.codice}>
                            <Action
                                as="button"
                                onClick={() => selectProduct(product)}
                            >
                                <Action.Label>{product.prodotto}</Action.Label>
                            </Action>
                        </List.Item>
                    ))}
                {products.data?.length <= 0 &&
                    <h1 className="m-2">{_t.errore.nessunRisultato()}</h1>
                }
            </List>
        </TabletFrame>
    );
}

export default function CaricaBins() {
    const [caricaBins, setCaricaBins] = React.useState(() => ({
        prodotto: null
    }));

    const {pathname} = useLocation();
    const navigate = useNavigate();

    if(pathname !== '/carica/bins' && !caricaBins?.prodotto)
        navigate('/carica/bins');

    return (
        <Context.Provider value={{caricaBins, setCaricaBins}}>
            <Routes>
                <Route index element={<Start />} />
                <Route path="quantita" element={<Quantita />} />
                <Route path="lotto" element={<Lotto />} />
                <Route path="deposito" element={<Deposito />} />
                <Route path="conferma" element={<Conferma />} />
                <Route path="fine" element={<TabletOk message={_t.tablet.success()}/>} />
                <Route path="errore" element={<TabletErrore message={_t.tablet.error()} />} />
            </Routes>
        </Context.Provider>
    );
}
