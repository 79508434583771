import useAuth from 'lib/hooks/use-auth';
import {includesAny} from "#lib/utils/array.js";
import {Ruoli} from "#root/src/constants.js";

function getNavigation(auth) {
    const {meta} = auth;
    const routes = [];
    const navigation = {routes};
    const isBackOfficeAdmin = includesAny(meta.data?.ruoli, [Ruoli.backOfficeAdmin]);
    const isAdmin = includesAny(meta.data?.ruoli, [Ruoli.admin]);

    if (!auth.isAuthenticated) return navigation;

    if(isBackOfficeAdmin) {
        routes.push(
            ...[
                {
                    name: _t.navigazione.movimenti(),
                    to: '/gestione/movimento/search'
                },
                {
                    name: _t.navigazione.giacenze(),
                    to: '/gestione/giacenza/search'
                }
            ]
        )
    }

    if(isAdmin) {
        routes.push(
            ...[
                {
                    name: _t.navigazione.utenti(),
                    to: '/gestione/utente/search'
                }
            ]
        )
    }
    // routes.push(
    //     ...[
    //         {
    //             name: _t.navigation.itineraries(),
    //             to: '/manage/itinerary'
    //         }
    //     ]
    // );

    return navigation;
}

export default function useNavigation() {
    const auth = useAuth();
    return getNavigation(auth);
}
