import useAsync from 'lib/hooks/use-async';
import {jsonHeaders} from 'lib/helpers/fetch';
import {getAuthHeader} from 'lib/hooks/use-auth';
import {isFunction} from 'lib/utils/function';
import {toast} from 'react-hot-toast';

export default function useResetPassword(id, options = {}) {
    const {onSettled} = options;
    return useAsync(async () => {
        const headers = await getAuthHeader();

        const response = await fetch(`${API_URL}/p/utente/reset-password?id=${id}`, {
            headers: {
                ...jsonHeaders,
                ...headers
            }
        });

        const data = await response.json();

        if (response.ok){
            toast.success(_t.auth.passwordReimpostata());
            isFunction(onSettled) && onSettled(data);
        }
        else
            toast.error(data?.errorText);
    });
}
