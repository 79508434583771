import {useNavigate} from 'react-router-dom';
import {useContext} from './index.jsx';
import {Action} from '#components/actions.jsx';
import React from 'react';
import {UnitaMisura} from '#root/src/constants.js';
import TabletFrame from '#root/src/lib/@tablet-template/tablet-frame.jsx';
import TabletRiepilogoRow from "#root/src/lib/@tablet-template/tablet-riepilogo-row.jsx";

export default function Quantita() {
    const {caricaBins, setCaricaBins} = useContext();
    const [quantita, setQuantita] = React.useState(10);
    const {prodotto} = caricaBins;

    const navigate = useNavigate();

    function onSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const quantita = formData.get('quantita');
        const {prodotto} = caricaBins;

        setCaricaBins((p) => ({
            ...p,
            quantita: formData.get('quantita'),
            pezzi: calcolaPezzi(quantita, prodotto)
        }));
        navigate('../lotto');
    }

    function increase() {
        setQuantita((p) => p + 5);
    }

    function decrease() {
        setQuantita((p) => {
            if (p <= 1) return 1;
            return p - 5;
        });
    }

    function calcolaPezzi(quantita, prodotto){
        return Math.round(quantita / (prodotto?.idUm === UnitaMisura.gr ? (prodotto?.peso * 1000) : prodotto?.peso));
    }

    return (
        <TabletFrame titolo={_t.crud.carica.bins() + " / " + _t.quantita.quantita()} formId='quantity-form' descrizione={_t.interfaccia.messaggiCarica.quantitaBins()}>
            <TabletRiepilogoRow label={_t.prodotto.prodotto()} text={`${prodotto?.name}`} />
            <TabletRiepilogoRow label={_t.quantita.numeroPezzi()} text={calcolaPezzi(quantita, prodotto)} />

            <div className='text-1xl font-semibold text-center'>
                {_t.quantita.quantitaBins()}
            </div>
            <div className='grid grid-cols-4 gap-4 mb-6 p-5'>
                <Action as='button' onClick={decrease}>
                    <Action.Label>-5 Kg</Action.Label>
                </Action>
                <form className='col-span-2' id='quantity-form' onSubmit={onSubmit}>
                    <input
                        min={0}
                        type='number'
                        className='w-full h-full text-3xl font-bold border-b-4 border-neutral-700 text-center'
                        name='quantita'
                        value={quantita}
                        onChange={(event) => setQuantita(event.target.value)}
                    />
                </form>
                <Action as='button' onClick={increase}>
                    <Action.Label>+5 Kg</Action.Label>
                </Action>
            </div>
        </TabletFrame>
    );
}
