import React from 'react';
import {useLastValue} from '#lib/hooks/use-last-value.js';
import {defineDefaults} from '#lib/helpers/react';

const defaultOptions = {
    enabled: true,
    listenerOptions: {}
};

const emptyObject = {};

export function useWindowEvent(type, listener, options = emptyObject) {
    defineDefaults(options, defaultOptions);
    const {enabled, listenerOptions} = options;
    let listenerRef = useLastValue(listener);

    React.useEffect(() => {
        if (!enabled) return;
        function handler(event) {
            listenerRef.current(event);
        }

        window.addEventListener(type, handler, listenerOptions);
        return () => window.removeEventListener(type, handler, listenerOptions);
    }, [type, listenerOptions, listenerRef, enabled]);
}
